<template>
   <div class="container">
       <div class="row">
           <h2>Domain Management</h2>
            <p>
              From the Domains section you can register or transfer a domain,
              renew a domain when it nears expiration, or edit information about
              the domain, such as email contact, whether a domain should be 
              automatically renewed, manually renewed, or abandoned when it
              expires. 
            </p>
            <p>
                You can also allow other SWCP accounts have access to the 
             functions above, and have the ability to create domain specific
             aliases. 
            </p>
       </div>
       <div class="row">
          <div class="alert alert-info">
            <h3>VERY IMPORTANT NOTE</h3>
            <p>
                  The domain permissions are SHARED between the domain manager and the Email Alias Manager. 
                  If you allow someone access to modify the domain renewal parameters for a domain, 
                  you ALSO grant them permission to add/change/delete email aliases for that domain. 
            </p> 
          </div>
       </div>
       <div class="row">
            <h3> The Domain Section</h3>
       </div>
       <div class="row">
                 <p>Selecting Edit Domains, Renew Domains, or Domain Permissions will give you a list of all your domains, 
                 and domains you're allowed to edit.
                 On any of these pages you can search for specific domains, or select the domain by clicking on it's row.</p>
                <p>To sort domains by expiration date click on the "Exp. Date" header. You can then find
                the domains closest to their expiration date at the top.</p>
        </div>
        <div class="row">
                 <h4>Expiration Date</h4>
        </div>
        <div class="row">
                <p>Date your domain will expire</p>
        </div>
        <div class="row">
                <h4>Auto-Renew</h4>
        </div>
        <div class="row">
            <p>If this is set, we will automatically renew your domain each year for as long as you 
               have an SWCP account. The renewal will happen approximately 45 days before the domain's 
               anniversary date. We will send you a confirmation email each time we renew the domain,
               and a charge will appear on your next invoice.
               If Auto-Renew is turned off, we will send you an email reminder when the domain's 
               anniversary date approaches and you can decide at that time whether to renew the domain or not.</P>
       </div>
       <div class="row">
               <h4>Abandon</h4>
       </div>
       <div class="row">
                <p>If this is set, we will NOT renew your domain and we will NOT send you reminder emails about it. 
                   The domain will be allowed to expire.</p>
        </div>

        <div class="row">
                <h4>Contact Email</h4>
        </div>
        <div class="row">
                <p>When we send reminder or confirmation emails regarding your domain, they are sent to 
                the email address specified here. If this field is blank, the emails are sent to the swcp.com 
                email account for the primary SWCP account holder that owns the domain.</p>

                <p>Note: the Auto-Renew and Let's Expire settings are mutually exclusive. They cannot both be 
                selected, and the system will complain if you try to set them both.</p>
        </div>

        <div class="row">
                <h4>Domain Renewals</H4>
        </div>
        <div class="row">
                <p>If your domain is available for renewal you'll find a pull-down menu to select the number
                of years you want to renew the domain. Choose the number of years, then click the "Renew Domain" button. 
                You'll get a message telling you if the renewal request has gone through. </p>
                <p>If you're domain is on auto-renewal, or too far away from expiration to renew there will be an
                alert at the bottom of the page with that information.</p>
        
                <p>You can return to the 
                domain list with either the Cancel button or selecting "Renew Domains" from the sidebar.
                In the domain list, the domain you just renewed will show "Yes" in the Renewal Pending column. This 
                indicates that we have received your renewal request, but it has not been finalized by the domain 
                registry yet. Renewal requests are generally finalized within an hour.</P>

                <p>When the domain renewal completes, we will send you an email confirming that it has gone through.                
                At that time, if you revisit the Domain List page you will see the Renewal Pending column for this 
                domain will say "No", and the expiration date will be updated to its new value.</p>

                <p>The confirmation email is sent to the Contact Email address listed for the domain. If no address is specified,                
                the email is sent to the swcp.com email account for the primary SWCP account holder that owns the domain.</p>
        </div>
        <div class="row">
            <h2>Domain Permissions</h2>
        </div>
        <div class="row">
            <p>When a domain name is first added to your account, only the primary account holder is allowed to 
               make changes to it. Sometimes it is useful for the account holder to specify that another SWCP 
               user be allowed to edit their aliases. This could be a consultant who works with your company, 
               or one of your employees, co-workers, or family members.</p>
        </div>
        <div class="row">
           <h4>Adding an Admin</h4>
        </div>
        <div class="row">
            <p>Select Domain Permissions from the side bar, then select the domain you want to add admins to.
               This will show you a list of admins that can access that domain. To add a new admin, 
               type the account name for that person and press the Add Admin button. Note this person must
               have an SWCP account, so they can login to the members panel. You can either add that person
               to that domain alone, or to all your domains.
               </p>

        </div>
        <div class="row">
           <h4>Removing an Admin</h4>
        </div>
        <div class="row">
           <p>You can remove an admin from a single domain by pressing the delete button next to
            their name in the list of admins for this domain. If you want to remove someone form
            all your domains, select Remove Permissions from the sidebar. This will allow you to
            remove someone quickly and easily from all your domains, useful when you lose an employee
            or change web developers.</p>
        </div>
   </div>
</template>

<script>

export default {
      name: "DomRegHelp",
      data () {
        return {
          account: ''
        }
      },
      computed: {
          currentRouteName() { return this.$route.name; },
          operator ()        { return this.$store.state.operator; },
          current_user ()    { return this.$store.state.current_user; },
          jwt ()             { return this.$store.state.jwt; }
      }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped >
.rsam-info.card-body {
   background-color: #ffffee;
   margin-bottom: 15px;
}
.drh-info.card-body {
   background-color: white;
   background-image: linear-gradient(white, white, #eeeeee);
}
.card-columns { 
    column-count: 2;
}
@media (max-width: 767px) 
.card-columns { 
    column-count: 1;
}

</style>
