<template>
   <div>
    <div class="row">
        <h2 class='dbhead'>Payments</h2>
    </div>
    
    <vue-good-table
      :columns="columns"
      :rows="rows"
      :theme="vgt_theme"
      styleClass="vgt-table condensed bordered"
      :sort-options="{
          enabled: true
      }"
      :pagination-options="{
        enabled: false
      }"
      :search-options="{
         enabled: false,
         placeholder: 'Search All Fields'
       }"
    >
   </vue-good-table>

  </div>
</template>

<script>

import VueGoodTable from '@/components/vue3-good-table/Table';
import axios from "axios";


export default {
      name: 'RecentPayments',
      emits: [ 'message' ],
      components: {
         VueGoodTable
      },
      data () {
        return {
          columns: [
                   {
                     field: "amount",
                     label: "Amount",
                     type: 'number',
                     sortable: false,
                     tdClass: 'text-center',
                     thClass: 'text-center',
                     formatFn: this.money
                   },
                   {
                     field: "invoice",
                     label: "Invoice",
                     type: 'string',
                     sortable: false,
                     formatFn: this.niceName
                   },
                   {
                     field: "checknum",
                     label: "Payment ID",
                     type: 'string',
                     sortable: false
                   },
                   {
                     field: "date",
                     label: "Date",
                     type: 'string',
                     sortable: true,
                     formatFn: this.pretty_date
                   },
                   {
                     field: "posted",
                     label: "Posted to Invoice?",
                     type: 'string',
                     sortable: false,
                     formatFn: this.yesno
                   }
                 ],
          rows: [],
          prices: [],
          selected_row: {}
        }
      },
      watch: {
          current_user: function (newUser, oldUser) {
            this.getRecentPayments();
          }
      },

      mounted() {
          this.getRecentPayments();
      },
      methods: {
         getRecentPayments() {
            var config = { headers:
                            { Authorization: "Bearer " + this.jwt }
                         };

            var curr = this.current_user;
            var url = "/api/recent-payments/" + curr;
            if ((curr == "") || (curr == undefined)) { this.$router.push({ name: "home"}); }
            console.log("Recent Payments for [" + curr + "]");
            axios.get(url, config )
            .then (
                   response => {
                      if (response.data.status) {
                          this.rows = response.data.data.payments;
                      } else {
                          this.rows = [];
                      }
                   })
            .catch(error => {
                   if (error.response) {
                      if (error.response.data.status && error.response.data.status == "expired_token") {
                          this.$store.dispatch('expired');
                      } else {
                          this.responseMessage(error.response.data.message, 'for ' + curr, 'modal', 'serious', '', []);
                      }
                   }
                   this.rows = [ ]; 
                   })
         },
         responseMessage(title, body, type, alert, footer, menu) {
                var args = { title: title, body: body,
                             type: type, alert: alert,
                             footer: footer, menu: menu };
                this.$store.commit('setStatus', args);
                this.$emit('message');
                return;
         },
         yesno (str) {
            if (str == "y") { return "Yes"; }
            if (str == "n") { return "No"; }
            return "Unknown";
         },
         niceName(str) {
            if (str == "0") { return "Unknown"; }
            return str;
         },
         money(str) {
            return "$" + str;
         },
         pretty_date (str) {
            if (str.length >= 8) {
               var year = str.substring(0,4);
               var month = str.substring(4,6);
               var day = str.substring(6,8);
               return  year + "-" + month + "-" + day;
            } else {
               return str;
            }
         }
      },
      computed: {
          operator ()        { return this.$store.state.operator; },
          current_user ()    { return this.$store.state.current_user; },
          mode()             { return this.$store.state.mode; },
          jwt ()             { return this.$store.state.jwt; },
          vgt_theme()        {
              if (this.mode == "light") {
                 return "swcptab";
              } else {
                 return "swcpdark";
              }
          }
      }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.vgt-wrap__footer {
    color: #000000;
    font-size: 1.1rem;
    padding: 0.5em !important;
    background: linear-gradient(#13C5E4, #F1F3F6);
}

</style>
