<template>
  <div>
   <div class="row">
     <div class="col-sm-12">
       <h2>Renew Domain {{ info.data.domain }}</h2>
         <form v-if="info.status" class="form-group my-2 my-lg-0" @submit.prevent="sendRenewalRequest">
           <table :class="tab_class">
              <tr>
                <th>Domain</th>
                <td> {{ info.data.domain }} </td>
              </tr>
              <tr>
                <th>Expiration Date</th>
                <td> {{ info.data.expiration_date }} </td>
              </tr>
              <tr>
                <th>Auto Renew?</th>
                <td> {{ yesno(info.data.auto_renew) }} </td>
              </tr>
              <tr>
                <th>Renewal pending?</th>
                <td> {{ yesno_quantity(info.data.renewal_pending) }} </td>
              </tr>
              <tr v-if="info.message != ''">
                 <td class="notice-me" colspan=2> {{ info.message }} </td>
              </tr>
              <tr v-if="info.status == 1">
                 <td><input type='hidden' v-model="renewalData.renew_domain">
                     <TldPrices v-bind:valid="valid" v-bind:init="tldinit" v-bind:domain="info.data.domain" v-on:select_num_years="getNumYears" /></td>
                 <td>
                     <button v-on:click.prevent="submitRenewal()"
                             class="btn btn-outline-info align-center"
                             type="submit">Renew Domain</button>
                 </td>
              </tr>
           </table>
         </form>
     </div>
   </div>
   <div class="row justify-content-center">
        <GoBack title="Cancel"></GoBack>
   </div>
 </div>
</template>

<script>

   import axios from "axios";
   import dayjs from "dayjs";
   import TldPrices from '@/components/TldPrices'
   import GoBack from '@/components/GoBack'
   export default {
      name: 'DomainRenewal',
      components: { TldPrices, GoBack },
      emits: [ 'message' ],
      data () {
        return {
          tldinit: "Number of years to renew",
          valid: true,
          info: { data: { domain: 'unknown', expiration_date: '1994-06-10', auto_renew: 0 } },
          new_expiration_date: '',
          renewalData: {
               renew_domain: 'jammed.com',
               price: 0,
               numyears: 1
          },
          sendData: {
               subject: '',
               message: '',
               send_from: 'noc@swcp.com',
               send_to: 'help@swcp.com',
               footer: ''
          },
          response_status: '',
          status: false
        }
      },
      methods: {
         getNumYears(numyears, price) {
            this.renewalData.numyears = numyears;
            this.renewalData.price = price;
         },
         yesno(thing) {
            if (thing == 1) { return "Yes"; }
            else { return "No"; }
         },
         yesno_quantity(thing) {
            var unit = "years";
            if (thing == 1) { unit="year"; }
            if (thing == 0) { return "No"; }
            else { return "Yes for " + thing + " " + unit; }
         },
         getDomainInfo() {
            var domain = this.$route.params.domain;
            var url = "/api/domain-renewal-status/" + domain;
            var config = { headers:
                            { Authorization: "Bearer " + this.jwt }
                         };
            this.renewalData.renew_domain = domain;
            axios.get(url, config )
              .then (
                 response => {
                    this.info = response.data;
                    this.status = true;
                    if (! response.data.status) {
                        this.responseMessage('Cannot renew domain', response.data.message, 'modal', 'serious', '', []);
                    }
                 })
              .catch(error => {
                    if (error.response.data.status && error.response.data.status == "expired_token") {
                        this.$store.dispatch('expired');
                    }
                    this.status = false;
                 })
         },
         buildRenewalNotice( domain, numyears, price, expiration_date) {
            var yr = "years";
            var msg = "";
            if (numyears == 1) { yr = "year"; }
            msg = domain + " will be renewed for " + numyears + " years<br>";
            msg += "The new expiration date is " + this.new_expiration_date + "<br>";
            return msg;
         },
         buildRenewalMessage( domain, numyears, price, expiration_date) {
            var yr = "years";
            var msg = "";
            console.log("buildRenewalMsg: " + domain + " " + numyears + " " + price + " " + expiration_date);
            if (numyears == 1) { yr = "year"; }
            msg = "Hello. The registration for your domain, " + domain + ",\n" +
                  "has been renewed for " + numyears + " " + yr +
                  ".\n  The new expiration date for " + domain + " is: ";
            msg += this.new_expiration_date;
            msg += "\n\nA charge of $" + price + " will appear on your next SWCP invoice.\n";
            msg += "If you have any questions, please email domains@swcp.com.\n\n";
            msg += "Thank you, \n\nSWCP Staff";
            return msg;
         },            
         newExpirationDate(expiration_date, numyears) {
            var date_obj = dayjs(expiration_date);
            date_obj = date_obj.add(numyears, "years");
            return date_obj.format('YYYY-MM-DD');
         },
         submitRenewal () {
            var url = "/api/renew-domain";
            var config = { headers:
                            { Authorization: "Bearer " + this.jwt }
                         };
            axios.post(url, this.renewalData, config )
               .then (
                  response => {
                   this.new_expiration_date = this.newExpirationDate(
                                              this.info.data.expiration_date, this.renewalData.numyears);
                   var msg = this.buildRenewalMessage( 
                             this.info.data.domain, 
                             this.renewalData.numyears,
                             this.renewalData.price,
                             this.info.data.expiration_date);
                   this.sendRenewalNotice(msg);
                   this.getDomainInfo();
               })
               .catch(error => { 
                  console.log("Couldn't renew " + this.info.data.domain + " " + error);
               })
         },
         sendRenewalNotice(msg) {
            var url = "/api/sendmessage";
            var config = { headers:
                            { Authorization: "Bearer " + this.jwt }
                         };
            this.sendData.send_to = this.customer_email;
            this.sendData.footer = this.footer_tag;
            this.sendData.message = msg;
            this.sendData.subject = "Domain renewed: " + this.renewalData.renew_domain;
            axios.post(url, this.sendData, config )
            .then (
                   response => {
                      var msg = this.buildRenewalNotice( this.renewalData.renew_domain, 
                                  this.renewalData.numyears, 
                                  this.renewalData.price, 
                                  this.info.data.expiration_date );
                      this.responseMessage('Renewal is Pending', msg, 'thankyou', 'warning', '', [ { name: 'Domain list', url: '/renew-domains' } ]);
                      this.response_status = response.data.status;
                      this.is_visible = true;
                   })
            .catch(error => {
                      this.response_title = 'Renewal Failed';
                      this.response_body = 'Problems renewing your domain: ' + error;
                      this.response_status = error;
                      this.is_visible = true;
                   })
          },
          responseMessage(title, body, type, alert, footer, menu) {
             var args = { title: title, body: body,
                          type: type, alert: alert,
                          footer: footer, menu: menu };
             this.$store.commit('setStatus', args);
             console.log("DomRenew: responseMessage");
             console.log(args);
             this.$emit('message');
             return;
           }
      },
      mounted() {
         this.getDomainInfo();
      },
      computed: {
          footer_tag () {
              if (this.operator === this.current_user) {
                 return "Message sent by " + this.operator;
              } else {
                 return "Message sent by " + this.operator + " for " + this.current_user;
              }
          },
          currentRouteName() { return this.$route.name; },
          operator ()        { return this.$store.state.operator; },
          current_user ()    { return this.$store.state.current_user; },
          jwt ()             { return this.$store.state.jwt; },
          customer_email () { return this.$store.state.reply_to; },
          mode() { return this.$store.state.mode; },
          tab_class () {
            return "table table-" + this.mode;
          }  
      }
   }
</script>

<style scoped>
.notice-me {
   margin-top: 5px;
   font-weight: bold;
   color: #ffffff;
   text-align: center;
   background-color: #3392ca;
   padding: 10px;
   border-radius: 2em;
}
</style>
