<template>
   <div>
    <div class="row">
        <h2 class='dbhead'>Disk Usage for <span class="swcp-em">{{ current_user}}</span> </h2>
    </div>
    
    <div class="row">
    <table :class="tab_class">
       <thead class="thead-dark">
         <tr>
          <th>Logname</th>
          <th>UID</th>
          <th>Quota</th>
          <th>Disk Usage</th>
          <th>Percent Used</th>
         </tr>
       </thead>
       <tbody>
         <tr v-for="row in rows" :key="row.uid">
          <td>{{row.logname}}</td>
          <td>{{row.uid}}</td>
          <td>{{unitified(row.curr_quota)}}</td>
          <td>{{unitified(row.curr_usage)}}</td>
          <td>{{percentage_used(row.curr_quota, row.curr_usage)}} %</td>
         </tr>
       </tbody>
    </table>
    </div>
  </div>
</template>

<script>

import axios from "axios";

export default {
      name: 'DiskUsage',
      emits: [ 'message' ],
      data () {
        return {
          rows: [],
          disk_usage: []
        }
      },
      watch: {
          current_user: function (newUser, oldUser) {
            this.getDiskUsage();
          }
      },

      mounted() {
          this.getDiskUsage();
      },
      methods: {
         percentage_used (quota, used) {
             let percent = parseFloat(used) / parseFloat(quota) * 100;
             return percent.toFixed(0);    
         },
         disk_use_units (num) {
             let units = "KB";
             let bytes;
             let float_num = parseFloat(num);
             let gigs = 1024 * 1024;
             let megs = 1024;
             bytes = float_num;
             if (float_num >= gigs) {
                 units = "GB";
                 bytes = float_num / gigs;
             } else {
               if (float_num > megs) {
                  units = "MB";
                  bytes = float_num / megs;
               } 
             }  
             return { 'units': units, 'bytes': bytes.toFixed(1) };
         },
         unitified (num) {
           let parsed_num = this.disk_use_units(num);
           return parsed_num.bytes + " " + parsed_num.units;
         },
         commafy_int (num) {
            return parseInt(num).toLocaleString();
         },
         pretty_date (str) {
            if (str.length >= 8) {
               var year = str.substring(0,4);
               var month = str.substring(4,6);
               var day = str.substring(6,8);
               return  year + "-" + month + "-" + day;
            } else {
               return str;
            }
         },

         getDiskUsage() {
            var config = { headers:
                            { Authorization: "Bearer " + this.jwt }
                         };

            var url = "/api/disk-usage/" + this.current_user;
            axios.get(url, config )
            .then (
                   response => {
                      this.disk_usage = response.data.data.diskusage;
                      this.rows = this.disk_usage;
                   })
            .catch(error => {
                   if (error.response) {
                      if (error.response.data.status && error.response.data.status == "expired_token") {
                          this.$store.dispatch('expired');
                      } 
                   } else {
                      this.responseMessage('Get Disk Usage ', error, 
                                           'modal', 'serious', '', []);
                   }
                   this.rows = [ ];
                   })
         },
         responseMessage(title, body, type, alert, footer, menu) {
                var args = { title: title, body: body,
                             type: type, alert: alert,
                             footer: footer, menu: menu };
                this.$store.commit('setStatus', args);
                this.$emit('message');
                return;
         }
      },
      computed: {
          operator ()        { return this.$store.state.operator; },
          current_user ()    { return this.$store.state.current_user; },
          jwt ()             { return this.$store.state.jwt; },
          mode ()             { return this.$store.state.mode; },
          tab_class ()       {
              if (this.mode == "light" ) { return "table table-bordered"; }
              else { return "table table-bordered table-dark"; }
          }
      }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
