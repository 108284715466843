<template>
     <div class="col-12">
        <h2 class="dbhead"><em>One email address per line in the target list</em></h2>
     </div>
     <div class="row form-group my-5">
         <div class="col-5 padme">
             <h3>Alias</h3>
             {{ full_email(updData.src_user, updData.src_domain) }} 
         </div>
         <div class="col-1"> 
             <h3>&nbsp;</h3> => 
         </div>
         <div class="col-6">
             <h3>Target List</h3>
             <textarea class="form_control maxial" rows=5  v-model="updData.targets"></textarea>
         </div>
     </div>
       
     <div class="row justify-content-center">
         <button v-on:click="updateAlias" class="btn btn-info">Change</button>
         <GoBack title="Cancel"></GoBack>
     </div>

</template>

<script>

import GoBack from "@/components/GoBack";
import axios from "axios";
export default {
    name: 'UpdateEmailAlias',
    components: { GoBack },
    emits: [ 'message' ],
    methods: {
       full_email (src, domain) {
          return src + "@" + domain;
       },
       getTargets(domain, alias) {
           var url = "/api/domain-alias-dests/" + domain + "/" + alias;
           var config = { headers:
                            { Authorization: "Bearer " + this.jwt }
                         };
           axios.get(url, config)
               .then (
                  response => {
                      if (response.data.status) {
                          this.updData.targets = this.line_by_line(response.data.data.dst_list);
                      } else {
                          this.responseMessage('Not Found', response.data.message, 'modal', 'serious', '', [] );
                      }
                  })
               .catch(error => {
                  this.responseMessage('Failed', error, 'modal', 'serious', '', []);
               })
       },
       updateAlias(alias) {
           var url = "/api/update-domain-alias";
           var config = { headers:
                            { Authorization: "Bearer " + this.jwt }
                         };
           axios.post(url, this.updData, config)
               .then (
                  response => {
                      if (response.data.status) {
                          this.responseMessage('Updates', response.data.message, 'review', 'info', '', [] );
                      } else {
                          this.responseMessage('Failed update', response.data.message, 'modal', 'serious', '', [] );
                      }
                  })
               .catch(error => {
                  this.responseMessage('Failed', error, 'modal', 'serious', '', []);
               })
       },
       responseMessage(title, body, type, alert, footer, menu) {
              var args = { title: title, body: body,
                           type: type, alert: alert,
                           footer: footer, menu: menu };
              this.$store.commit('setStatus', args);
              this.$emit('message');
              return;
       },
       line_by_line (list) {
           var result = "";
           if (list != null) {
               list.forEach((line) => { 
                        result += line + "\n"; });
           } else {
               result = null;
           }
           return result;
       },
    },
    mounted () {
        this.updData.src_domain = this.$route.params.domain;
        this.updData.src_user = this.$route.params.src;
        this.updData.targets = this.line_by_line(this.getTargets(this.updData.src_domain, this.updData.src_user));
    },
    computed: {
        operator ()        { return this.$store.state.operator; },
        current_user ()    { return this.$store.state.current_user; },
        jwt ()             { return this.$store.state.jwt; }
   },
   data() {
      return {
        updData: {
            src_user: '',
            src_domain: '',
            targets: ''
        }
      }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.maxial {
  width: 100%;
}
.padme {
   padding-left: 5%;
}
</style>
