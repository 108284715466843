<template>
  <div class="hello container">
    <div class="row">
       <h1>{{ msg }}</h1>
    </div>
    <div class="row alert alert-primary">
      Welcome to SWCP's Member Portal - Beta version. Please report any problems or suggestions
      to noc@swcp.com. Thanks!
    </div>
    <div class="row">
       <h3>Important Notes</h3>
    </div>
    <div class="oldstyle row">
       <ul>
           <li>When you make a payment through this portal it happens immediately</li>
           <li>You can now store credit cards for future use without being on auto-pay</li>
           <li>Your credit cards are stored off-site with our payment gateway partner</li>
       </ul>
    </div>

    <div class="row justify-content-md-center alert alert-info">
        <h3>Some Useful Pointers</h3>
    </div>
    <div class="row">
      <div class="col-sm-2"></div>
      <div class="col-sm-2">
           <a :class="btn_mode"  @click="$router.push('make-payment')">Make a Payment</a>
      </div>
      <div class="col-sm-2">
           <a :class="btn_mode" 
              href="https://www.swcp.com/blog" target="_blank" rel="noopener">SWCP blog</a>
      </div>
      <div class="col-sm-2">
           <a :class="btn_mode" 
              href="https://members.swcp.com/wiki" target="_blank" rel="noopener">SWCP Wiki</a>
      </div>
      <div class="col-sm-2">
           <a :class="btn_mode" 
              href="https://members.swcp.com/" target="_blank" rel="noopener">Legacy Portal</a>
      </div>
      <div class="col-sm-2"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Welcome',
  props: {
    msg: String
  },
  computed: {
     mode () { return this.$store.state.mode; },
     btn_mode () { return "justify-content-md-center btn btn-outline-primary btn-" + this.mode; }
  }            

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.hello {
   margin-top: 40px;
   margin-bottom: 40px;
}
.oldstyle ul {
    list-style-type: square !important;
    padding-left: 1em;
}
.oldstyle ul>li {
    display: list-item;
}
</style>
