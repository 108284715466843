<template>
  <div>
   <div :class="currentClass">
       <span v-html="currentNotice"></span>
       <button type="button" class="close" v-on:click="setDisappear()">&times;</button>
   </div>
 </div>
</template>

<script>
   export default {
      name: 'Notices',
      emits: [ 'notice-closed' ],
      data () {
        return {
           test: '<h3>Hi</h3>',
        }
      },
      methods: {
        setDisappear() {
           this.$emit('notice-closed');
           this.$store.commit('setStatusMessage', '');
        }
      },
      computed: {
        currentNotice () { 
            return "<h3>" + this.$store.state.status_title + "</h3>" + 
                    this.$store.state.status_body; 
        },
        currentClass ()  { 
            var cls = "text-center alert ";
            cls += "alert-" + this.$store.state.status_alert;
            return cls;
        }
      }
  }
</script>
