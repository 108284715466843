<template>
  <div>
     <h2 class="dbhead">Reset Members Panel Password</h2>

   <div class="row">
     <div class="col-sm-12">
       This changes the password for logging into this dashboard. It does NOT change
     your email password. Thanks!
     </div>
   </div>
   <div class="row">
     <div class="col-sm-4">
       <br>
       <p class="center-block">
          <img src="@/assets/img/reset-passwd-panel.png" alt="Reset Password Icon">
       </p>
     </div>
     <div class="col-sm-8">
         <br>
         <VForm class="form-group" @submit="chgPwd">
            <VField class="form-control" :type="type" v-model="userData.new_password"
                      name="new_password" placeholder="New Password" aria-label="New Password" 
                      rules="required|min:8" />
            <ErrorMessage class="form-error" name="new_password" /><br>
            <VField class="form-control" :type="type" v-model="userData.conf_password"
                      name="conf_password" placeholder="Confirm Password" aria-label="Confirm Password" 
                      rules='required|confirmed:new_password' />
            <ErrorMessage class="form-error" name="conf_password" /><br>
            <button class="btn btn-outline-info"
                  type="submit">Change Password</button>
            <button class="btn btn-outline-info" type="button" v-on:click="togglePassword"> <i class='fa fa-eye'></i> {{ showHide }} </button>
         </VForm>
     </div>
   </div>
   <div class="row justify-content-center">
       <button class="btn btn-primary" v-on:click="$router.push('/home')">Back</button>
   </div>

   <div class="row">
      <div class="text-info col-md-12">
         {{ status }}<br>
         {{ idinfo }}
      </div>
   </div>
  </div>
</template>

<script>

// This will be replaced by a PHP version because of the issue of not being
// able to route directly to it from the outside world.

   import axios from "axios";
   import { Form as VForm, Field as VField, ErrorMessage } from "vee-validate";
   export default {
      name: 'ResetPanelPassword',
      emits: [ 'message' ],
      components: { VForm, VField, ErrorMessage },
      data () {
        return {
           userData: {
               username: '',
               reset_token: '',
               new_password: '',
               conf_password: ''
           },
           showHide: "Show Passwords",
           type: "password",
           idinfo: '',
           response_title: '',
           response_body: '',
           menu: [],
           status: ''
        }
      },
      methods: {
         responseMessage(title, body, type, alert, footer, menu) {
           var args = { title: title, body: body,
                        type: type, alert: alert,
                        footer: footer, menu: menu };
           this.$store.commit('setStatus', args);
           this.$emit('message');
           return;
         },
         togglePassword() {
              if (this.type === "password") {
                  this.type = "text";
                  this.showHide = "Hide Passwords";
              } else {
                  this.type = "password";
                  this.showHide = "Show Passwords";
              }
         },
         chgPwd(values) {
            this.status = "This password is for the members panel only";
            var url = "/api/user-reset-password"; 
            this.userData.reset_token = this.get_reset_token;
            this.idinfo = "Token:" + this.userData.reset_token;
            axios.post(url, this.userData, [] )
            .then (
                   response => {
                      if (response.data.message == "") {
                          this.response_title = "Password Changed";
                          this.response_body = `Your password has been changed. This effects the login for this dashboard only. 
                                                It does NOT effect your email password.`;
                          this.responseMessage(this.response_title, this.response_body, 'thankyou', 'info', '', [ { name: 'Login', url: '/home' } ]);
                      } else {
                          this.response_title = 'Problem';
                          this.response_body = response.data.message;
                          this.responseMessage(this.response_title, this.response_body, 'modal', 'serious', '', []);
                      }
                      console.log(response);
                   })
            .catch(error => {
                      if (error.response) {
                         if (error.response.data.status && error.response.data.status == "expired_token") {
                             this.$store.dispatch('expired');
                         }
                         this.response_title = 'Reset Failed';
                         this.response_body = error.response.data.message;
                         this.responseMessage(this.response_title, this.response_body, 'modal', 'serious', '', []);
                      }
                      console.log(error);
                 })
          }
      },
      computed: {
          get_reset_token () { return this.$route.params.token; },
          current_user ()    { return this.$store.state.current_user; },
          jwt ()             { return this.$store.state.jwt; },
          customer_email ()  { return this.$store.state.reply_to; }
      }
   }
</script>

<style>
.rsam-info.card-body {
   background-color: #ffffee;
   margin-bottom: 15px;
}
.sam-info.card-body {
   background-color: #def6c5;
}
</style>
