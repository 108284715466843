<template>
  <div class="col-md-12">
   <h2 class='dbhead'> Databases for <span class="swcp-em">{{current_user}}</span></h2>
    <vue-good-table
      :columns="columns"
      :rows="rows"
      :theme="vgt_theme"
      :sort-options="{
          enabled: true,
          initialSortBy: {field: 'name', type: 'asc'}
      }"
      :pagination-options="{
        enabled: true,
        mode: 'records',
        perPage: 10,
        position: 'top',
        perPageDropdown: [ 10, 25, 50],
        dropdownAllowAll: false,
        setCurrentPage: 1,
        nextLabel: 'next',
        prevLabel: 'prev',
        rowsPerPageLabel: 'Rows per page',
        ofLabel: 'of',
        pageLabel: 'page', // for 'pages' mode
        allLabel: 'All',
      }"
      :search-options="{
         enabled: false,
         placeholder: 'Search All Fields'
       }"
       styleClass="vgt-table bordered condensed"
     >
   </vue-good-table>
  </div>
</template>

<script>

import VueGoodTable from '@/components/vue3-good-table/Table';
import axios from "axios";

export default {
     name: 'Databases',
     components: {
        VueGoodTable
     },
     data () {
         return {
           account: '',
           columns: [  
             {
               field: "name",
               label: "DB Name",
               sortable: true,
               filterOptions: {
                  enabled: true, // enable filter for this column
                  placeholder: 'Search', // placeholder for filter input
               }
             },
             {
               field: "url",
               label: "PhpMyAdmin",
               tdClass: 'text-center',
               sortable: false,
               html: true
             }
           ],
           rows: []
         }
     },
     mounted() {
         this.DatabaseList();
     },
     watch: {
             current_user: function (newUser, oldUser) {
               console.log("watch called in Databases for " + newUser);
               this.account = this.current_user;
               this.DatabaseList();
             }
     },
     methods: {
       pma_btn (url) {
          return "<a class='btn btn-info' target='_pma' href='" + url + "'>PHP My Admin</a>";
       },
       newItem (item) {
          item['url'] = this.pma_btn(item['pma_url']);
          return item;
       },
       add_buttons (items) {
          return items.map(this.newItem);
       },
       DatabaseList () {
         var config = { headers:
                             { Authorization: "Bearer " + this.jwt }
                      };
         this. account = this.current_user;
         axios.get("/api/databases/" + this.account, config )
         .then (
                 response => {
                       this.rows = this.add_buttons(response.data.data);
                 })
         .catch(error => {
                 var notes = "";
                 if (error.response) {
                    if (error.response.data.message) {
                        notes = error.response.data.message;
                    }
                    if (error.response.data.status && error.response.data.status == "expired_token") {
                        this.$store.dispatch('expired');
                    }
                 } 
                 this.rows = [ { 'name': 'unknown', 
                                 'user': 'unknown',
                                 'PhpMyAdmin': notes } ];
                 })
       }
     },
     computed: {
          currentRouteName() { return this.$route.name; },
          operator ()        { return this.$store.state.operator; },
          current_user ()    { return this.$store.state.current_user; },
          mode()             { return this.$store.state.mode; },
          jwt ()             { return this.$store.state.jwt; },
          vgt_theme()        {
              if (this.mode == "light") {
                 return "swcptab";
              } else {
                 return "swcpdark";
              }
          }

     }
   
   }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
