<template>
<div>
  <h2 v-if="title !=''" class="dbhead">{{title}}</h2>

  <div v-bind:id="accord_id">
    <div v-for="(item, index) in items" :class="card_mode" v-bind:key="index">
      <div class="card-header" :id="quest_id(index)">
        <h5 class="mb-0">
          <button class="btn btn-link" data-toggle="collapse" :data-target="tagit(collapse_id(index))" aria-expanded="true" :aria-controls="collapse_id(index)">
             {{ item.question }}
          </button>
        </h5>
      </div>

      <div :id="collapse_id(index)" class="collapse hide" :aria-labelledby="quest_id(index)" :data-parent="tagit(accord_id)">
        <div class="card-body">
          <span v-html="item.answer"></span>
        </div>
      </div>
    </div>
  </div>
 </div>
</template>

<script>
   export default {
       name: 'FAQ',
       props: {
          faqname: String,
          title: String,
          items: Array
       },
       data () {
         return {
            id: 1,
            accordion_name: '_faqAccord',
            collapse_name: 'rollup',
            question_name: 'q',
            card_modes: {
               light: "card",
               dark:  "card bg-dark text-white"
            }
       }
     },

      methods: {
          quest_id (id) {
             return this.question_name + id;
          },
          tagit (str) {
             return "#" + str;
          },
          collapse_id (id) {
             return this.collapse_name + id;
          },
      },
      computed: {
           accord_id ()       { return this.faqname + this.accordion_name; },
           accord_tag ()      { return '#' + this.faqname + this.accordion_name; },
           operator ()        { return this.$store.state.operator; },
           current_user ()    { return this.$store.state.current_user; },
           jwt ()             { return this.$store.state.jwt; },
           mode()             { return this.$store.state.mode; },
           card_mode()        { return this.card_modes[this.mode]; }
      }
   }
</script>

<style>
.dboffset {
    margin: -30 5% 0 20%;
    display: block;
}
</style>
