<template>
  <div v-if="! logged_in" id="login">
     <login />
  </div>
  <div v-if="logged_in" id="overview">
    <NetwStats v-if="this.outage_visable" v-on:network_status="set_status"/>
    <welcome>SWCP Portal</welcome>
  </div>
</template>

<script>
import Login from '@/components/Login.vue';
import Welcome from '@/components/Welcome.vue';
import NetwStats from '@/components/NetwStats.vue';

export default {
  name: 'Home',
  components: { Welcome, NetwStats, Login },
  data () {
     return {
       outage_visable: true
     }
  },
  methods: {
    set_status (status) {
      this.outage_visable = status;
    }
  },
  computed: {
        logged_in () {
           return this.$store.state.logged_in;
        }
  }
}

</script>
