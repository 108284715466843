<template>
  <div>
   <div class="row">
      <h2>Remove an Admin from all domains</h2>
   </div>
   <div class="boxed form-group row">
          <h4 class="col-sm-4">Delete this Admin</h4>
          <input type="text" v-model="adminform.admin_to_delete" placeholder="SWCP account name" required>

          <div class="col-sm-2">
               <button v-on:click="deleteAdmin" class="btn btn-primary">Delete</button>
          </div>
   </div>
   <div class="row">
        <hr />
   </div>
   <div class="row justify-content-center">
       <GoBack title="Cancel"></GoBack>
   </div>
   <div class="row">
       <h4>Warning</h4>
       <p>If you just need to remove an admin from one domain use "Domain Permissions". This tool
          will remove an admin from all your domains. </p>
   </div>
 </div>
</template>
 
<script>
   import axios from "axios";
   import GoBack from "@/components/GoBack";

   export default {
      name: "Permissions",
      components: { GoBack },
      emits: [ 'message' ],
      data () {
        return {
           mydomain: '',
           message: '',
           status: 0,
           adminform: {
              admin_to_delelete: '',
              current_user: '',
              all: 0
          }
        }
     },
     methods: {
       responseMessage(title, body, type, alert, footer, menu) {
              var args = { title: title, body: body,
                           type: type, alert: alert,
                           footer: footer, menu: menu };
              this.$store.commit('setStatus', args);
              this.$emit('message');
              return;
       },
       createList(domains) {
          const lis = domains.map(this.buildLi);
          return "<ul>" + lis.join(" ") + "</ul>";
       },
       buildLi (value) {
           return "<li>" + value + "</li>";
       },
       deleteAdmin() {
          var url = "/api/delete-admin-from-all";
          var config = { headers:
                          { Authorization: "Bearer " + this.jwt }
                       };
          this.adminform.current_user = this.current_user;
          axios.post(url, this.adminform, config )
            .then (
               response => {
                  this.message = response.data.message;
                  this.status = response.data.status;
                  console.log("Status " + this.status + " message " + this.message);
                  if (response.data.status) {
                      this.domains = response.data.data.domains;
                      this.responseMessage("Deleting admin " + 
                                     this.adminform.admin_to_delete + " from:", 
                                     this.createList(this.domains), 'thankyou', 'secondary', '', [{ name: 'Back', url: '/remove-permissions' }] );
                      this.adminform.admin_to_delete = "";
                  } else {
                      this.responseMessage(this.message, this.adminform.admin_to_delete, 'modal', 'serious', '', []);
                      this.adminform.admin_to_delete = "";
                  }
               })
            .catch(error => {
                  this.responseMessage("Deletion Failed", error.message, 'modal', 'serious', '', []);
                  this.status = false;
             })
       }

     },

     computed: {
          currentRouteName() { return this.$route.name; },
          operator ()        { return this.$store.state.operator; },
          current_user ()    { return this.$store.state.current_user; },
          jwt ()             { return this.$store.state.jwt; }
     }
   }
</script>

<style>
.boxed {
  color: white;
  border: 1px black solid;
  padding-top: 15px;
  padding-bottom: 15px;
  background-color: #555555;
}
.boxed h4 {
  color: white;
}

</style>


