import { createApp } from 'vue'
import { store } from './store'
import router from "@/router"
import App from '@/App.vue'
import { defineRule } from 'vee-validate';

// Validation Rules for Veevalidate
defineRule('required_number', value => {
  if (!value) {
    return 'Required';
  }
  return true;
});
defineRule('required', value => {
  if (!value || !value.trim().length) {
    return 'Required';
  }
  return true;
});

defineRule('min', (value, [limit]) => {
  // The field is empty so it should pass
  if (!value || !value.length) {
    return true;
  }
  if (value.trim().length < limit) {
    return `Must be at least ${limit} characters`;
  }
  return true;
});

defineRule('notnegative', value => {
   if (value < 0) {
      return 'Required';
   }
   return true;
});

defineRule('confirmed', (value, [target], ctx) => {
  if (value === ctx.form[target]) {
    return true;
  }
  return 'Passwords must match';
});
 
defineRule('validccn', (value) => {
   if (luhn(value)) {
       return true;
   }
   return "Invalid Credit Card Number";
});

defineRule('validexpdate', (value) => {
    if (! valid_mmyy_date(value)) {
        return "Invalid date (MMYY)";
    }
    if (valid_expiration_date(value)) {
        return true;
    }
    return "Expired Card (MMYY)";
});

defineRule('nonzero', (value) => {
    if (nonzero(value)) {
        return true;
    }
    return "Amount must be > 0";
});

defineRule('email', value => {
  // Field is empty, should pass
  if (!value || !value.length) {
    return true;
  }
  // Check if email
  if (!/[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,6}/.test(value)) {
    return 'This field must be a valid email address';
  }
  return true;
});

function nonzero(value) {
    let num = parseFloat(value).toFixed(2);
    if (num <= 0) { return false; }
    return true;
}

function remove_slash (str) {
    return str.replace('/', '');
}

function valid_mmyy_date(value) {
    let mm, yy;
    let cvalue = remove_slash(value);
    if (cvalue.length != 4) {
       return false;
    }
    mm = cvalue.substr(0,2);
    if (mm < 1 || mm > 12) { return false; }
    return true;
}

function valid_expiration_date(value) {
    let mm, yy, now, now_mm, now_yy;
    let cvalue = remove_slash(value);
    if (typeof(cvalue) === 'string') {
       if (cvalue.length != 4) {
           return false;
       } else {
          mm = cvalue.substr(0,2);
          yy = cvalue.substr(2,2);
          if (mm < 1 || mm > 12) { return false; }
          now = new Date(); 
          now_mm = now.getMonth() + 1;
          now_yy = now.getYear() % 100;
          if (yy > now_yy) { return true; }
          if (yy < now_yy) { return false; }
          if (mm >= now_mm) { return true; }
          return false;
       }
    } else {
       return false;
    }
}

function luhn (ccn) {
   let i, chksum, sum, which, digit;
   if (typeof(ccn) !== 'string') { return false; }
   if (/[^0-9-\s]+/.test(ccn)) { return false; }
   sum = 0;
   which = true;
   ccn = ccn.replace(/\D/g, "");
   chksum = parseInt(ccn.charAt(ccn.length - 1));
   console.log("luhn from main.js");

   for (i = ccn.length - 2; i >= 0; i--) {
       digit = parseInt(ccn.charAt(i));
       if (which) {
           digit *= 2;
           if (digit > 9) { digit -= 9; }
           which = false;
       } else { which = true; }
       sum += digit;
   }
   return ( (sum + chksum) % 10 == 0);
}
// End Rules for VeeValidate

const app = createApp(App)

app.use(store)
app.use(router)

app.mount('#app')

