<template>
   <div>
    <h2 class='dbhead'>Quick References</h2>
    <table :class='table_mode'>
    <tbody>
    <tr v-for='ref in refs' :key="ref.id">
      <th :class="ref.ref_role">{{ ref.ref_name }}</th>
        <td :class="ref.ref_role"> {{ ref.ref_value }} </td></tr>
    </tbody>
    </table>
    <div class="text-center">
        <button class="btn btn-primary" v-on:click="$router.push('home')">Back</button>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
        data () {
           return {
              table_modes: { light: 'table table-striped',
                             dark:  'table table-striped table-dark' },
              refs: []
           }
        },
        mounted() {
              axios.get("/api/quickrefs")
              .then (
                     response => {
                       this.refs = response.data.references;
                     })
              .catch(error => {
                       this.refs = [ { 'ref_name': 'Failed', 'ref_value': error } ];
                     })

        },
        methods: {
           link_or_no (role, value) {
               console.log(role + " " + value);
               if (role == "url") {
                  return "<a href='" + value + "'>" + value + "</a>";
               } else {
                  return value;
               }
            }
        },
        computed: {
           logged_in ()  { return this.$store.state.logged_in; },
           mode ()       { return this.$store.state.mode; },
           table_mode () { return this.table_modes[this.mode]; },
           number_of_refs: function () { return this.refs.length; }
        }
}
</script>

<style scoped>
.notice {
    color: #cc5555;
    font-weight: bold;
}
.table td, .table th {
    padding: .5rem .75rem;
}
.table th {
    width: 40%;
    border-right: 1px solid #aaaaaa;
}
</style>
