<template>
  <div>
   <FAQ faqname="jkc" title="" :items="items"> </FAQ>
   <GoBack title="Back" />
 </div>

</template>

<script>
  import FAQ from "@/components/FAQ";
  import GoBack from "@/components/GoBack";

export default {
      name: 'FAQTest',
      components: { FAQ, GoBack },
      computed: {
           user () { return this.$store.state.user; },
           staff () { return this.$store.state.staff; },
           logged_in () { return this.$store.state.logged_in; },
           jwt () { return this.$store.state.jwt; }
      },
      data () {
        return {
           items: [ { question: 'Who are you?',
                      answer: 'The green knight',
                      id: 1
                    },
                    { question: 'What is your favorite color?',
                      answer: 'Blue, no red ... Ahhhhhhh', 
                      id: 2
                    }
                  ],
           preface: 'FAQ: '
         }
      }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped> 
.table td, .table th {
    padding: .5rem .75rem;
}
.fa {
    color: dodgerblue;
}
.table th {
    color: #333333;
}
.top-row h1, h2, h3, h4, h5, h6 {
    margin-top: .5em;
}

.boxed {
    background-color: white;
    padding: 20px;
    color: #555555;
    border: 1px black solid;
    width: 60%;
    min-height: 100px%;
    overflow-wrap: break-word;
}

</style>
