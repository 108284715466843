export const DomReg_template = `
We have received a web-authenticated request from account {{logname}}
to register/transfer a domain:
----------------------------------------------------------------------
Domain:           {{domain}}
Type:             {{{reg_type}}}
Logname:          {{logname}} 
Organization:     {{{organization}}}
Email Contact:    {{contact_email}}
Reg Period:       {{numyears}} Year{{ess}}
WHOIS Privacy:    {{whois_priv}}
Website Options:  {{website_option}}
For-Profit:       {{profit_nonprofit}} 

Registration Agreement Accepted:  Yes

Estimated Total Domain Registration Cost: $ {{totalprice}}
{{additional_fees}}

---Notes--------------------------------------------------------------
{{{notes}}}

----------------------------------------------------------------------

           Submission Timestamp: {{date}}
`;
export const DomReg_items = {
   logname: '',
   domain: '',
   reg_type: '',
   organization: '',
   contact_email: '',
   numyears: '',
   ess: '',
   whois_priv: '',
   website_option: '',
   profit_nonprofit: '',
   totalprice: '',
   additional_fees: '',
   notes: '',
   date: ''
};

