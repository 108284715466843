<template>
<nav class="fixed-top navbar navbar-expand-lg navbar-dark " style="background-color: #041630;">
  <a class="navbar-brand" href="https://www.swcp.com">
          <img src="@/assets/img/swcp-logo-50x50.png" alt="SWCP Logo">
        </a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" 
          data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" 
          aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto">
      <li class="activeuser">Logname: {{operator}}</li>
      <li class="activeacct" v-if="staff">Working on: {{current_user}}</li>
      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" 
           data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          Resources
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
          <a class="dropdown-item" target="asf" href="https://members.swcp.com/mailfilter/">
             Anti-Spam Filter</a>
          <a class="dropdown-item" target="asf" href="https://members.swcp.com/mailfilter/">
             Forwarding & Autoresponders</a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" target="swcpwiki" href="https://members.swcp.com/wiki">
             Support Wiki</a>
          <a class="dropdown-item" target="swcpwiki" 
             href="https://members.swcp.com/wiki/Useful_Website_building_Tools">
             Pointers to Web Building Resources</a>
          <a class="dropdown-item" target="webacl" href="https://members.swcp.com/webdiracl/">
             Web Directory Access Control</a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" target="burn" href="https://burn.swcp.com">
             SWCP Secure Message Exchange</a>
          <a class="dropdown-item" target="blog" 
             href="https://www.swcp.com/our-blog">Our Blog</a>
          <div v-if="staff" class="dropdown-divider"></div>
          <a v-if="staff" class="dropdown-item" target="_boo" href='https://clbt.swcp.com'>CLBT</a>
        </div>
      </li>
      <li v-if="staff" class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button"
           data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
           Admin Tools
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
           <a class="dropdown-item" target="asf" :href="login_url">
             Login to Admin Area</a>
           <a class="dropdown-item" target="asf" :href="user_man_url">
             User Management</a>
           <a class="dropdown-item" target="asf" :href="ref_url">
             Quick References</a>
           <a class="dropdown-item" target="asf" :href="ns_url">
             Network Status Reports</a>
        </div>
      </li>
          
      <li class="nav-item">
        <a class="nav-link" v-on:click="changeMode()"> 
           <i :class=next_mode_class></i> {{ next_mode_name }}</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" v-on:click="logoutUser()"> 
           <i class="fa fa-lg fa-fw fa-sign-out-alt "></i> Logout</a>
      </li>
    </ul>
    <form class="form-inline my-2 my-lg-0" v-on:submit.prevent="onSearch" v-if="staff">
      <input class="form-control mr-sm-2" name="customer" type="text" v-model="customer" 
             placeholder="Logname or UID" aria-label="Search" v-if="staff">

      <button class="btn btn-plain btn-outline-info my-2 my-sm-0" 
              type="submit" v-if="staff">Find Account</button>
    </form>
  </div>
</nav>
</template>

<script>
   import axios from "axios";
   export default {
      name: "DashNav",
      emits: [ 'logout', 'change_mode', 'setCurrentUser', 'userNotFound' ],
      props: {
          db_url: null,
          aud_url: null
      },
      created(){
              this.curr_mode = this.mode;
              this.next_mode = this.otherMode;
      },
      data () {
           return {
              customer: '',
              savecustomer: '',
              curr_mode: '',
              next_mode: '',
              mode_classes: { dark: 'fa fa-lg fa-fw fa-moon', 
                              light: 'fa fa-lg fa-fw fa-sun' },
              mode_names: { dark: "Dark Mode", 
                            light: "Light Mode" }
           }
      },
      methods: {
           changeMode() {
              if (this.curr_mode == 'dark') { 
                  this.curr_mode = 'light'; 
                  this.next_mode = 'dark'; 
              } else {
                  this.curr_mode = 'dark'; 
                  this.next_mode = 'light'; 
              }
              this.$emit('change_mode', this.curr_mode);
           },
           logoutUser() {
              localStorage.setItem('id_token', '');
              this.$emit('logout');
           },
           onSearch() {
               var config = { headers:
                               { Authorization: "Bearer " + this.jwt }
                            };

               this.savecustomer = this.customer;
               this.customer = ''; // Clear the form on enter
               axios.get("/api/lookup-logname-uid/" + this.savecustomer, config )
               .then (
                      response => {
                         if (response.data.status) {
                            this.$emit('setCurrentUser', response.data.data.logname, response.data.data.bill_email);
                         } else {
                            this.$emit('userNotFound', this.savecustomer);
                         }
                      })
               .catch(error => {
                       if (error.response) {
                          console.log("Error Status=" + error.response.status);
                          if (error.response.status == 404) {
                             this.$emit('userNotFound', this.savecustomer);
                          } else {
                              this.$store.dispatch('expired');
                         }
                       }
                      })
           }
      },
      computed: {
           login_url ()    { return this.db_url + "/login"; },
           ns_url ()       { return this.db_url + "/ns/all"; },
           ref_url ()      { return this.db_url + "/ref/all"; },
           user_man_url () { return this.db_url + "/user/all"; },

           next_mode_class ()   { return this.mode_classes[this.next_mode]; },
           next_mode_name ()    { return this.mode_names[this.next_mode]; },
           
           operator()      { return this.$store.state.operator; },
           current_user () { return this.$store.state.current_user ; },
           reply_to ()     { return this.$store.state.reply_to ; },
           staff()         { return this.$store.state.staff; },
           role()          { return this.$store.state.role; },
           mode()          { return this.$store.state.mode; },
           otherMode()     { if (this.$store.state.mode == "light") { return 'dark' }
                             else { return 'light' } },
           jwt ()          { return this.$store.state.jwt; }
      }
   }
</script>
<style>
.activeuser {
   color: #ffff8e;
   padding-top: 8px;
   padding-bottom: 8px;
   padding-left: 10px;
   padding-right: 10px;
   border: 1px #5476b0 solid;
}
.btn-plain {
   box-shadow: none !important;
}
.activeacct {
   color: white;
   padding-top: 8px;
   padding-bottom: 8px;
   padding-left: 10px;
   padding-right: 10px;
   border: 1px #2cc4ef solid;
   background-color: #007bff;
}
li.nav-link> a:hover {
    color: rgba(255,255,255,.75);
    text-decoration: none;
}
li.nav-link> a {
    color: rgba(255,255,255,.5);
}
</style>

