<template>
  <div>
   <div class="row top-row">
     <h4 class="col-12">The Answer</h4>
     <div class="col-11 boxed">
        {{ answer }}
     </div>
     <div class="col-12">
          <form class="form-group row align-items-center" @submit.prevent>
            <h4 class="col-md-12">Base 64 Encrypt/Decrypt</h4>
            <div class="col-md-12">
                <textarea class="form-control" rows="6" v-model="userData.item"></textarea><br>
            </div>
            <div class="col-md-6 justify-content-center">
              <button v-on:click="convert_base64(1)" class="form-control my-2 btn btn-outline-info "
                  id="encode" type="submit">Encode</button>
            </div>
            <div class="col-md-6 justify-content-center">
              <button v-on:click="convert_base64(0)" class="form-control my-2 btn btn-outline-info "
                  id="decode" type="submit">Decode</button>
            </div>
          </form>
      </div>
   </div>
 </div>

</template>

<script>

import axios from "axios";
export default {
      data () {
        return {
           answer: "",
           displace: 0,
           timestamp: 0,
           userData: {
               item: '',
               cleartext: ''
           },
         }
      },
      methods: {
         convert_base64(enc) {
            var url;
            if (enc) { url = "/api/encode-base64"; }
            else { url = "/api/decode-base64"; }
            var config = { headers:
                            { Authorization: "Bearer " + this.jwt }
                         };
            axios.post(url, this.userData, config )
                .then (
                   response => {
                      this.answer = response.data.data.result;
                   })
                .catch ( error => {  
                       if (error.response.data.status && error.response.data.status == "expired_token") {
                           this.$store.dispatch('expired');
                       }
                       this.answer = "Error: " + error;
                   })
         }
      },
      computed: {
          jwt () { return this.$store.state.jwt; }
      }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped> 
.table td, .table th {
    padding: .5rem .75rem;
}
.fa {
    color: dodgerblue;
}
.table th {
    color: #333333;
}
.top-row h1, h2, h3, h4, h5, h6 {
    margin-top: .5em;
}

.boxed {
    background-color: white;
    padding: 20px;
    color: #555555;
    border: 1px black solid;
    width: 60%;
    min-height: 100px%;
    overflow-wrap: break-word;
}

</style>
