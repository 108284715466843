<template>
  <div>
   <div class="row" v-if="this.admins.length">
      <h2>Accounts that can access {{ mydomain }}</h2>
      <table :class="tab_class">
      <tr><th>Account</th><th>&nbsp;</th></tr>
      <tr v-for="admin in admins" :key="admin">
        <td>{{ admin.logname }}</td>
        <td><button v-on:click="deleteAdmin(mydomain, admin.logname)" 
                    :class="btn_mode">Delete</button></td>
      </tr>
      </table>
   </div>
   <div class="row" v-else>
      <h2>No Additional Admin Accounts for {{ mydomain }}</h2>
   </div>
   <div class="boxed form-group row">
                 <h4 class="col-sm-3">Add an Admin</h4>
                 <input type="text" v-model="adminform.newadmin" placeholder="SWCP account name" required>

           <div class="col-sm-3">
                <input type="radio" v-model="adminform.all" value=0 /> This Domain Only<br>
                <input type="radio" v-model="adminform.all" value=1 /> All My Domains<br>
           </div>
          <div class="col-sm-2">
            <button v-on:click="addAdmin" class="btn btn-primary">
            Add 
            </button>
         </div>
   </div>
   <div class="row">
        <hr />
   </div>
   <div class="row justify-content-center">
       <GoBack title="Cancel"></GoBack>
   </div>
   <div class="row">
       <h4>Permissions</h4>
          <p>When a domain name is first added to your account, only 
          the primary account holder is allowed to make changes to it. 
          Sometimes it is useful for the account holder to specify that 
          another SWCP user be allowed to edit their aliases. This could 
          be a consultant who works with your company, or one of your 
          employees, co-workers, or family members.</p>
   </div>
 </div>
</template>
 
<script>
   import axios from "axios";
   import GoBack from "@/components/GoBack";

   export default {
      name: "Permissions",
      components: { GoBack },
      emits: [ 'message' ],
      data () {
        return {
           mydomain: '',
           message: '',
           status: 0,
           admins: [ { logname: 'me' }, { logname: 'you' } ],
           adminform: {
              domain: '',
              newadmin: '',
              logname: '',
              all: 0
           },
           delform: {
              domain: '',
              logname: ''
          }
        }
     },
     methods: {
       getDomain() {
          var domain = this.$route.params.domain;
          this.mydomain = domain;
       },
       responseMessage(title, body, type, alert, footer, menu) {
              var args = { title: title, body: body,
                           type: type, alert: alert,
                           footer: footer, menu: menu };
              this.$store.commit('setStatus', args);
              this.$emit('message');
              return;
       },
       createList(domains) {
          const lis = domains.map(this.buildLi);
          return "<ul>" + lis.join(" ") + "</ul>";
       },
       buildLi (value) {
           return "<li>" + value + "</li>";
       },
       getAdminList() {
          var url = "/api/domainadmins/" + this.mydomain;
          var config = { headers:
                          { Authorization: "Bearer " + this.jwt }
                       };
          axios.get(url, config )
            .then (
               response => {
                  this.admins = response.data.data;
                  this.status = true;
               })
            .catch(error => {
                  this.status = false;
             })

       },
       addAdmin() {
          var url = "/api/addadmin";
          var config = { headers:
                          { Authorization: "Bearer " + this.jwt }
                       };
          this.adminform.domain = this.mydomain;
          this.adminform.logname = this.current_user;
          axios.post(url, this.adminform, config )
            .then (
               response => {
                  this.message = response.data.message;
                  this.status = response.data.status;
                  console.log("Status " + this.status + " message " + this.message);
                  if (response.data.status) {
                      this.domains = response.data.data.domains;
                      this.responseMessage("New Admin " + this.adminform.newadmin + " set for:", 
                                     this.createList(this.domains), 'review', 'info', '', [] );
                      this.getAdminList();
                      this.adminform.newadmin = "";
                  } else {
                      this.responseMessage('Failed', this.message, 'modal', 'serious', '', []);
                      this.adminform.newadmin = "";
                  }
               })
            .catch(error => {
                  this.responseMessage("Add Failed ", error.message, 'modal', 'serious', '', []);  
                  this.status = false;
             })
       },
       deleteAdmin(domain, logname) {
          var url = "/api/deleteadmin";
          var config = { headers:
                          { Authorization: "Bearer " + this.jwt }
                       };
          this.delform.domain = domain;
          this.delform.logname = logname;
          axios.post(url, this.delform, config )
            .then (
               response => {
                  this.message = response.data.message;
                  this.status = true;
                  this.responseMessage("Deleted " + this.delform.logname, "", 'review', 'warning', '', []); 
                  this.getAdminList();
               })
            .catch(error => {
                  this.responseMessage("Delete Failed ", error.message, 'modal', 'serious', '', []);  
                  this.status = false;
             })
       }
     },
     mounted() {
        this.getDomain();
        this.getAdminList();
     },

     computed: {
          currentRouteName() { return this.$route.name; },
          operator ()        { return this.$store.state.operator; },
          current_user ()    { return this.$store.state.current_user; },
          jwt ()             { return this.$store.state.jwt; },
          mode ()            { return this.$store.state.mode; },
          tab_class ()	     { 
              if (this.mode == "light") {
                  return "table table-bordered"; }
              else {
                  return "table table-bordered table-dark"; 
              }
          },
          btn_mode () { return "btn btn-outline-primary btn-" + this.mode; }
     }
   }
</script>

<style>
.boxed {
  color: white;
  border: 1px black solid;
  padding-top: 15px;
  padding-bottom: 15px;
  background-color: #555555;
}
.boxed h4 {
  color: white;
}

</style>


