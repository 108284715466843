<template>
   <div class="col-12">
    <h2 class='dbhead'>Temporary Email for <span class="swcp-em">{{current_user}}</span></h2>
   </div>
   <div class="col-12">
     <div class="row form-group my-2">
         <div class="col-5">
             Alias 
           <div class="maxial">
             <input class="form-control" type='text' v-model="addData.name" /> @shadowz.com =>
           </div>
         </div>
         <div class="col-5">
             Forwards to <input class="form-control maxial" type='text' v-model="addData.forwards_to" /> 
         </div>
     </div>
     <div class="row form-group my-2">
         <div class="col-5">
              Expires at
              <select class='maxial email-select mb-5' v-model="addData.expires">
                  <option v-for="expdate in expdates"
                          v-bind:key="expdate.date"
                          v-bind:value="expdate.date"> {{ expdate.label }} </option>
              </select>
          </div>
         <div class="col-5">
             Notes <input class="form-control maxial" type='text' v-model="addData.notes" /> 
         </div>
         <div class="col-2">
             <button class="btn btn-info" v-on:click="createAlias">Add New Alias</button>
         </div>
     </div>

     <div class="col-12"><hr></div>
     <div class="col-12">
        <vue-good-table
          :columns="columns"
          :rows="rows"
          :theme="vgt_theme"
          @on-row-click="selectRow"
          :sort-options="{
              enabled: true,
              initialSortBy: {field: 'name', type: 'asc'}
          }"
          :pagination-options="{
            enabled: false
          }"
          :search-options="{
             enabled: false,
             placeholder: 'Search All Fields'
           }"
           styleClass="vgt-table bordered condensed"
         >
       </vue-good-table>
    </div>
   </div>

</template>

<script>
import axios from "axios";
import VueGoodTable from '@/components/vue3-good-table/Table';
import UpdateEmailAlias from "@/components/UpdateEmailAlias";
import CreateEmailAlias from "@/components/CreateEmailAlias";
export default {
    name: 'TempEmail',
    components: { UpdateEmailAlias, CreateEmailAlias, VueGoodTable },
    emits: [ 'message' ],
    mounted () {
        this.getExpList();
        this.getTempEmailList();
    },
    watch: {
          current_user: function (newUser, oldUser) {
            console.log("Watch called for " + newUser);
            this.getExpList();
            this.getTempEmailList();
          }
    },
    methods: {
       createAlias () {
           var url = "/api/add-tempemail";
           var config = { headers:
                            { Authorization: "Bearer " + this.jwt }
                         };
           this.addData.logname = this.current_user;
           console.log(this.addData);

           axios.post(url, this.addData, config)
               .then (
                  response => {
                     console.log(response.data);
                     if (response.data.status == 1) {
                         this.responseMessage('Success', 'Added ' + this.addData.name + "@shadowz.com",
                                              'review', 'info', '', [] );
                         this.getTempEmailList();
                     } else {
                         this.responseMessage('Failed', response.data.message, 'modal', 'serious', '', []);
                     }
                  })
               .catch(error => {
                  this.responseMessage('Failed', error, 'modal', 'serious', '', []);
               })
       },
       adds_dom (alias) {
          return alias + "@shadowz.com";
       },
       colorCode (row) {
          if (row.expired == 1) { return "blue-class"; }
          else { return "white-class"; }
       },
       selectRow(params) {
            var row = params.row;
            this.selected_row = row;
            this.$router.push({ name: 'edit-temp-email', params: { alias: row.name }});
       },
       responseMessage(title, body, type, alert, footer, menu) {
              var args = { title: title, body: body,
                           type: type, alert: alert,
                           footer: footer, menu: menu };
              this.$store.commit('setStatus', args);
              this.$emit('message');
              return;
       },
       getExpList() {
           var url = "/api/tempemail-explist";
           var config = { headers:
                            { Authorization: "Bearer " + this.jwt }
                         };
           console.log("Get Exp List");
           axios.get(url, config)
               .then (
                  response => {
                      this.expdates = response.data.data.list;
                      this.addData.expires = response.data.data.selected_date;
                  })
               .catch(error => {
                  if (error.response.data.status && error.response.data.status == "expired_token") {
                      this.$store.dispatch('expired');
                  } else {
                      this.responseMessage('Failed', error, 'modal', 'serious', '', []);
                  }
               })
       },
       append_buttons(aliases) {
           aliases.forEach(alias => {
              if (alias.src == "*") {
                  alias.delbtn = "";
                  alias.edbtn = "";
              } else {
                  alias.delbtn = "<button class='btn btn-info'>Delete</button>";
                  alias.edbtn = "<button class='btn btn-info'>Edit</button>";
              }
           });
           return aliases;
       },
       getTempEmailList() {
           var url = "/api/tempemails/";
           var config = { headers:
                            { Authorization: "Bearer " + this.jwt }
                        };
           console.log("GetTempEmail list for " + this.current_user);
           axios.get(url + this.current_user, config )
              .then (
                 response => {
                    this.rows = response.data.data;
                    this.err = "";
                    if (! this.rows.length) { this.err = "No Temp Emails Found"; }
                 })
              .catch(error => {
                    if (error.response.data.status && error.response.data.status == "expired_token") {
                        this.$store.dispatch('expired');
                    }
                    console.log("Didn't find temp emails for " + this.current_user);
                    this.rows = [ ];
               })
       }
    },
    computed: {
          currentRouteName() { return this.$route.name; },
          operator ()        { return this.$store.state.operator; },
          current_user ()    { return this.$store.state.current_user; },
          mode()             { return this.$store.state.mode; },
          jwt ()             { return this.$store.state.jwt; },
          vgt_theme()        {
              if (this.mode == "light") {
                 return "swcptab";
              } else {
                 return "swcpdark";
              }
          }
   },
   data () {
      return {
        current_domain: '',
        expdates: [],
        first_date: '',
        selected_row: [],
        selected_cell: '',
        addData: {
            logname: '',
            name: '',
            forwards_to: '',
            expires: '',
            notes: ''
        },
        columns: [
             {
               field: "name",
               label: "Alias",
               sortable: true,
               formatFn: this.adds_dom,
               tdClass: this.colorCode
             },
             {
               field: "forwards_to",
               label: "Forwards to",
               type: "string",
               sortable: false,
               tdClass: this.colorCode
             },
             {
               field: "expires",
               label: "Expires",
               sortable: false,
               globalSearchDisabled: true,
               tdClass: this.colorCode
             },
             {
               field: "goes_toedash",
               label: "Fades",
               sortable: false,
               globalSearchDisabled: true,
               tdClass: this.colorCode
             },
             {
               field: "notes",
               label: "Notes",
               sortable: false,
               globalSearchDisabled: true,
               tdClass: this.colorCode
             }
        ],
        domains: [],
        rows: [ ]
      }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.email-select {
   padding: 10px;
   border: 1px solid dodgerblue;
   border-radius: 5px;
}
.maxial {
  width: 100%;
}
.padme {
   padding-left: 5%;
}
</style>
