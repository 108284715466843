<template>
  <div>
   <div class="row">
     <h4 class="col">Result</h4>
   </div>
   <div class="row">
     <div class="boxed">
        {{ answer }}
     </div>
   </div>
   <div class="row">
       <form class="col-11" @submit.prevent>
        <h4>Hash generator [MD5/SHA1/bcrypt]</h4>
        <div class="row">
        <textarea required name='userData.item' class="form-control" rows=8 
                  v-model="userData.item" aria-label="plaintext">
        </textarea>
        </div>
        <div class="row my-3 justify-content-center">
        <button v-on:click="encrypt_string('md5')" class="col-2-offset col-2 btn btn-outline-primary"
                type="submit">Encrypt using MD5</button>
        <button v-on:click="encrypt_string('sha1')" class="col-2-offset col-2 btn btn-outline-primary"
                type="submit">Encrypt using SHA-1</button>
        <button v-on:click="encrypt_string('bcrypt')" class="col-2-offset col-2 btn btn-outline-primary"
                type="submit">Encrypt using bcrypt</button>
        </div>
       </form>
   </div>
 </div>

</template>

<script>

import axios from "axios";
export default {
      data () {
        return {
           answer: "",
           displace: 0,
           plaintext: '',
           userData: {
               item: '',
               cleartext: ''
           },
         }
      },
      methods: {
         encrypt_string(enctype) {
            var url;
            url = "api/" + enctype; 
            var config = { headers:
                            { Authorization: "Bearer " + this.jwt }
                         };
            axios.post(url, this.userData, config )
                .then (
                   response => {
                      this.answer = response.data.data.result;
                   })
                .catch ( error => {  
                    if (error.response.data.status && error.response.data.status == "expired_token") {
                        this.$store.dispatch('expired');
                    }
                    this.answer = "Error: " + error;
                   })
         }
      },
      computed: {
           user ()      { return this.$store.state.user; },
           staff ()     { return this.$store.state.staff; },
           logged_in () { return this.$store.state.logged_in; },
           jwt ()       { return this.$store.state.jwt; }
      }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped> 
.table td, .table th {
    padding: .5rem .75rem;
}
.fa {
    color: dodgerblue;
}
.table th {
    color: #333333;
}
.top-row h1, h2, h3, h4, h5, h6 {
    margin-top: .5em;
}

.boxed {
    background-color: white;
    padding: 20px;
    color: #555555;
    border: 1px black solid;
    width: 80%;
    min-height: 100px%;
    overflow-wrap: break-word;
}

</style>
