<template>
  <div>
     <h2 class="dbhead">Change Email/Unix Password</h2>

   <div class="row">
     <div class="col-sm-12">
       This changes your email and Unix login password, but the password required here
       is your panel password. There is extra security to change your email 
       password because it is used to authenticate you and do password recovery.
     </div>
   </div>

   <div class="row">
     <div class="col-sm-4">
       <br>
       <p class="center-block">
          <img src="@/assets/img/email-password-small.png" alt="Envelope and Lock">
       </p>
     </div>
     <div class="col-sm-8">
         <br>
         <form class="form-group my-2 my-lg-0" @submit.prevent="chgPwd">
            <ErrorMessage :error="problems.panel_password" /><br>
            <input class="form-control mr-sm-2" :type="type" v-model="userData.panel_password"
                      name="panel_password" placeholder="Panel Password" aria-label="Panel Password" /><br>
            <ErrorMessage :error="problems.new" /><br>
            <input class="form-control mr-sm-2" :type="type" v-model="userData.new"
                      name="new" placeholder="New Password" aria-label="New Password" /><br>
            <ErrorMessage :error="problems.confirm" /><br>
            <input class="form-control mr-sm-2" :type="type" v-model="userData.confirm"
                      name="confirm" placeholder="Confirm Password" aria-label="Confirm Password" /><br>
            <button class="btn btn-outline-info my-2 my-sm-0"
                  type="submit">Change Password</button>
            <button class="btn btn-outline-info" type="button" v-on:click="togglePassword"> <i class='fa fa-eye'></i> {{ showHide }} </button>
         </form>
     </div>
   </div>
   <div class="row">
      <div class="col-md-12">
         {{ status }}
      </div>
   </div>
  </div>
</template>

<script>
   const fail_title = "Sorry, something has failed";
   const fail_body = "Incorrect Password "; 
   const success_title = "Thank You!";
   const success_body = "Password changes take about ten minutes to propagate through out our network.";
   const aux_menu = [ 
                  { name: 'Home', url: '/home' },
                  { name: 'Check your passwords', url: '/pwned'},
                  { name: 'Recent Payments', url: '/recent-payments'}
                ];

   import axios from "axios";
   import { useVuelidate } from '@vuelidate/core'
   import { required, minLength, sameAs, helpers } from '@vuelidate/validators';
   import ErrorMessage from '@/components/ErrorMessage.vue'

   export default {
      name: 'ChangeEmailPassword',
      emits: [ 'message' ],
      components: { ErrorMessage },
      setup: () => ({ v$: useVuelidate() }),
      data () {
        return {
           showHide: "Show Passwords",
           type: "password",
           min_passwd: 8,
           userData: {
               logname: '',
               panel_password: '',
               new: '',
               confirm: '',
               id: 0
           },
           problems: {
               panel_password: '',
               new: '',
               confirm: ''
           },
           fields: [ 'panel_password', 'new', 'confirm' ],
           response_title: '',
           response_body: '',
           menu: [],
           status: ''
        }
      },
      validations () {
          return {
             userData: {
                 panel_password: { required },
                 new: { required, minLength: minLength(8) },
                 confirm: { sameAs: helpers.withMessage('Passwords must match', sameAs(this.userData.new))} 
             }
         }
      },
      methods: {
         responseMessage(title, body, type, alert, footer, menu) {
           var args = { title: title, body: body,
                        type: type, alert: alert,
                        footer: footer, menu: menu };
           console.log("responseMessage");
           console.log(args); 
           this.$store.commit('setStatus', args);
           this.$emit('message');
           return;
         },
         clearProblems() {
            this.fields.forEach ((item) => {
               this.problems[item] = '';
            });
          },
         togglePassword() {
              if (this.type === "password") {
                  this.type = "text";
                  this.showHide = "Hide Passwords";
              } else {
                  this.type = "password";
                  this.showHide = "Show Passwords";
              }
         },
         clearForm() {
            this.userData.logname = ''; 
            this.userData.panel_password = '';
            this.userData.new = '';
            this.userData.confirm = '';
         },
         async chgPwd() {
            var msg, message, property;
            this.userData.logname = this.current_user;
            const result = await this.v$.$validate();
            msg = ''; 
            this.clearProblems();
            if (! result) {
                  msg = "Validation problems found:";
                  this.v$.$errors.forEach (err => {
                      property = err.$property;
                      message = err.$message;
                      this.problems[property] = message;
                      if (message != "") {
                          msg += " " + property + "=" + message + "\n";
                      }
                  });
                  console.log(msg);
            } else {

                var url = "/api/change-unix-password"; 
                var config = { headers:
                                { Authorization: "Bearer " + this.jwt }
                             };
                axios.post(url, this.userData, config )
                .then (
                       response => {
                          if (response.data.status) {
                              this.responseMessage(success_title, success_body, 'thankyou', 'info', '', aux_menu);
                          } else {
                              this.responseMessage("Problems", response.data.message, 'modal', 'serious', '', []);
                          } 
                       })
                .catch(error => {
                         this.response_title = "Problems";
                         this.response_body = error;
                         this.responseMessage(this.response_title, this.response_body, 'modal', 'serious', '', []);
                       })
            }
         }
      },
      computed: {
           current_user ()   { return this.$store.state.current_user; },
           jwt ()            { return this.$store.state.jwt; },
           customer_email () { return this.$store.state.reply_to; }
      }
   }
</script>

<style>
.rsam-info.card-body {
   background-color: #ffffee;
   margin-bottom: 15px;
}
.sam-info.card-body {
   background-color: #def6c5;
}
</style>
