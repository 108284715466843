<template>
  <div>
   <div class="row top-row">
     <h4 class="col-12">The Answer</h4>
     <div class="col-11 boxed">
        {{ answer }}
     </div>
     <div class="col-12">
         <h4 class="col-12">Unix Timestamp converter</h4>
         <b>One Day: 86400; One month (30 days): 2,592,000; On year: 31,536,000.</b><br>
         <form @submit.prevent>
           <div class="form-inline row justify-content-around my-3">
                 <label for="displace">Seconds to Add/Subtract</label>
                 <input required class="form-control col-md-2" type="text" v-model="displace"
                        id="displace" placeholder="0" aria-label="Displacement">
                 <button v-on:click="current_time()" class="col-md-4 btn btn-outline-info"
                         type="submit">Current Time +/- seconds</button>
           </div>
           <div class="form-inline row justify-content-around my-3">
                 <label for="ts">Unix Timestamp</label>
                 <input required class="form-control col-md-2" type="text" v-model="timestamp"
                        placeholder="0" aria-label="Timestamp">
                 <button v-on:click="convert_timestamp()" class="col-md-4 btn btn-outline-info"
                         type="submit">Convert Timestamp</button>
           </div>
         </form>
     </div>
   </div>
 </div>

</template>

<script>

import axios from "axios";
export default {
      data () {
        return {
           answer: "",
           displace: "0",
           timestamp: 0,
           userData: {
               item: '',
               cleartext: ''
           },
         }
      },
      methods: {
         current_time() {
            var url;
            url = "/api/unixtime/" + this.no_commas(this.displace); 
            var config = { headers:
                            { Authorization: "Bearer " + this.jwt }
                         };
            axios.get(url, config )
                .then (
                   response => {
                      this.answer = response.data.data.unixtime;
                      this.timestamp = response.data.data.unixtime;
                   })
                .catch ( error => {  
                        if (error.response.data.status && error.response.data.status == "expired_token") {
                            this.$store.dispatch('expired');
                        }
                       this.answer = "Error: " + error;
                   })
         },
         no_commas (str) {
            console.log(typeof(str));
            return str.replaceAll(',', '');
         },
         convert_timestamp() {
            var url;
            url = "/api/humantime/" + this.timestamp; 
            var config = { headers:
                            { Authorization: "Bearer " + this.jwt }
                         };
            axios.get(url, config )
                .then (
                   response => {
                      this.answer = response.data.data.datetime;
                   })
                .catch ( error => {  
                       this.answer = "Error: " + error;
                   })
         }
      },
      computed: {
          jwt () { return this.$store.state.jwt; }
      }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped> 
.table td, .table th {
    padding: .5rem .75rem;
}
.fa {
    color: dodgerblue;
}
.table th {
    color: #333333;
}
.top-row h1, h2, h3, h4, h5, h6 {
    margin-top: .5em;
}

.boxed {
    background-color: white;
    padding: 20px;
    color: #555555;
    border: 1px black solid;
    width: 60%;
    min-height: 100px%;
    overflow-wrap: break-word;
}

</style>
