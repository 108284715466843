<template>
   <div>
    <div v-if="staff" class="row">
      <div class="col-12" v-if="noNameError!=''">
           <span class="invalid-large">{{ noNameError }}</span>
      </div>
      <div class="tap-down proper-padding-vert col-12">
           <label class="proper-padding-horiz">Name  </label>
           <input class="comp-width form-control" type="text" name="name"
                  placeholder="Individual or Company" v-model="name" /> 
      </div>
    </div>
    <div class="row">
      <div class="proper-padding-vert col-4">
           <label class="proper-padding-horiz">SWCP Discount?</label>
              <input v-on:change="changeDiscount()" type="checkbox" 
                 true-value="yes"
                 false-value="no"
                 id="swcp_disc" v-model="swcp_disc"/>
                 <label class="proper-padding-horiz" for="swcp_disc"> {{ swcp_disc }} </label>&nbsp;&nbsp;
      </div>
      <div class="proper-padding-vert col-4">
           <label class="proper-padding-horiz">Quantity</label>
           <input type="text" class="col-2" name="quantity" v-model="quantity" /> 
      </div>
      <div class="proper-padding-vert redbox col-4">
           <label class="proper-padding-horiz">Total</label>
              ${{ total }}
      </div>
    </div>
    
    <div class="row">
    <table :class="table_mode">
       <thead class="thead-dark">
         <tr>
          <th>&nbsp;</th>
          <th>Name</th>
          <th>Price</th>
          <th>Per</th>
          <th>Description</th>
         </tr>
       </thead>
       <tbody>
         <tr v-for="prod in prodlist" :key="prod.pcode">
          <td>
              <input type="radio" :id=prod.pcode :value="prod" v-model="cowork_product"/>
          </td>
          <td>{{prod.name}}</td>
          <td>{{prod.actual_price}}</td>
          <td>{{prod.unit}}</td>
          <td>{{prod.description}}</td>
         </tr>
       </tbody>
    </table>
    </div>
    <div v-if="staff">
        <CoworkPayForm 
            v-bind:amount = "total"
            v-bind:name = "name" 
            v-bind:cowork_product = "cowork_product"
            v-bind:quantity = "quantity"
            v-bind:key="keymaster"
            v-on:noname="noName"
            v-on:message="setMessage" />
    </div>

  </div>
</template>

<script>

import axios from "axios";
import CoworkPayForm from '@/components/CoworkPayForm.vue'

export default {
      name: 'CoworkingProds',
      components: { CoworkPayForm },
      emits: [ 'message' ],
      data () {
        return {
          table_modes: { light: 'table table-bordered ',
                         dark:  'table table-bordered table-dark' },
          noNameError: '',
          keymaster: 0,
          prodlist: [],
          name: '',
          cowork_product: { name: 'Default', pcode: 0, actual_price: 0.00, description: 'Lorem Ipsum Cats' },
          swcp_disc: 'no',
          quantity: 1,
          email: '',
          cwpData: {
              disctype: 1 
          }
        }
      },
      watch: {
          name: function (newSum, oldSum) {
               this.noNameError = '';
          }
      },
      mounted() {
          this.getProductList();
      },
      methods: {
         noName () {
            this.noNameError = "A reservation name is required";
            console.log("Got a noName emission");
         },
         setMessage () {
            this.$emit('message');
         },
         responseMessage(title, body, type, alert, footer, menu) {
           var args = { title: title, body: body,
                        type: type, alert: alert,
                        footer: footer, menu: menu };
           this.$store.commit('setStatus', args);
           this.$emit('message');
           return;
         },
         changeDiscount() {
            this.cowork_product.actual_price = 0;
            this.cowork_product.name = "";
            this.cowork_product.pcode = 0;
            this.cowork_product.description = '';
            this.cwpData.disctype = this.disctype;
            this.keymaster++;
            this.getProductList();
         },
         getProductList() {
            var config = { headers:
                            { Authorization: "Bearer " + this.jwt }
                         };

            var url = "/api/get-coworking-prods";
            axios.post(url, this.cwpData, config )
            .then (
                   response => {
                      this.prodlist = response.data.data;
                   })
            .catch(error => {
                   if (error.response) {
                      if (error.response.data.status && error.response.data.status == "expired_token") {
                          this.$store.dispatch('expired');
                      } else {
                          this.responseMessage('Get Coworking Products', error, 'modal', 'serious', '', []); 
                      }
                   }
                   this.rows = [ ];
                   })
         }
      },
      computed: {
          operator ()        { return this.$store.state.operator; },
          staff ()           { return this.$store.state.staff; },
          current_user ()    { return this.$store.state.current_user; },
          jwt ()             { return this.$store.state.jwt; },
          total ()           { return this.cowork_product.actual_price * this.quantity; },
          mode ()            { return this.$store.state.mode; },
          table_mode ()      { return this.table_modes[this.mode]; },
          disctype()         { if (this.swcp_disc == "yes") { return 2 } else { return 1 } }
      }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.tap-down {
   margin-top: 10px;
}
.proper-padding-vert {
   padding: 10px 0px;
}
.proper-padding-horiz {
   padding: 0px 10px;
}
.redbox {
   font-weight: bold;
   border-radius: 5px;
}
.comp-width {
   width: 75%;
}
</style>
