<template>
  <div>
     <h2 class="dbhead">Request Password Reset for Members Panel</h2>

   <div class="row">
     <div class="col-sm-12">
       This is a reset for the SWCP members panel. This will NOT change
       your email password. Thanks! You should receive an email message within
       a few minutes. The link will be valid for 30 minutes.
     </div>
   </div>
   <div class="row">
     <div class="col-sm-4">
       <br>
       <p class="center-block">
          <img src="@/assets/img/reset-passwd-panel-gray.png" alt="Reset Password Icon">
       </p>
     </div>
     <div class="col-sm-8">
         <br>
         <VForm class="form-group" @submit="reqReset">
            <ErrorMessage class="form-error" name="account" /><br>
            <VField class="form-control" :type="text" v-model="userData.account"
                      name="account" placeholder="SWCP account" aria-label="SWCP Account" 
                      rules="required" />
            <ErrorMessage class="form-error" name="email" /><br>
            <VField class="form-control" :type="text" v-model="userData.email"
                      name="email" placeholder="Recovery Email" aria-label="Recovery Email" 
                      rules='required|email' /><br>
            <button class="btn btn-outline-info"
                  type="submit">Request Reset</button>
         </VForm>
     </div>
   </div>
   <div class="row justify-content-center">
       <GoBack title="Cancel"></GoBack>
   </div>

   <div class="row">
      <div class="text-info col-md-12">
         {{ status }}<br>
      </div>
   </div>
  </div>
</template>

<script>

   import axios from "axios";
   import GoBack from '@/components/GoBack'
   import { Form as VForm, Field as VField, ErrorMessage } from "vee-validate";
   export default {
      name: 'RequestPanelReset',
      emits: [ 'message' ],
      components: { GoBack, VForm, VField, ErrorMessage },
      data () {
        return {
           reset_message: '',
           userData: {
               account: '',
               email: '',
               burn_it: 0
           },
           sendData: {
               send_from: '',
               message: '',
               subject: 'Password Reset for SWCP Members Panel',
               footer: ''
           },
           type: "password",
           response_title: '',
           response_body: '',
           menu: [],
           status: ''
        }
      },
      methods: {
         responseMessage(title, body, type, alert, footer, menu) {
           var args = { title: title, body: body,
                        type: type, alert: alert,
                        footer: footer, menu: menu };
           this.$store.commit('setStatus', args);
           console.log(args);
           this.$emit('message');
           return;
         },
         reqReset(values) {
            var url = "/api/forgot-panel-password"; 
            axios.post(url, this.userData, [] )
            .then (
                   response => {
                      if (response.data.status) {
                          this.reset_message = response.data.data.reset_message;
                          this.responseMessage('Reset request submitted', 'Please check your email for reset link', 
                                               'thankyou', 'info', '', [ { name: 'Login', url: '/home' } ]);
                      } else {
                          this.response_title = "Problems";
                          this.response_body = response.data.message;
                          this.responseMessage(this.response_title, this.response_body, 'modal', 'serious', '', []);
                      }
                      console.log(response);
                   })
            .catch(error => {
                      if (error.response) {
                         if (error.response.data.status && error.response.data.status == "expired_token") {
                             this.$store.dispatch('expired');
                         }
                         this.response_title = 'Problems';
                         this.response_body = error.response.data.message + " -- Please contact help@swcp.com";
                         this.responseMessage(this.response_title, this.response_body, 'modal', 'serious', '', []);
                      }
                      console.log(error);
                 })
          }
      },
      computed: {
          get_reset_token () { return this.$route.params.token; },
          current_user ()    { return this.$store.state.current_user; },
          jwt ()             { return this.$store.state.jwt; },
          customer_email ()  { return this.$store.state.reply_to; }
      }
   }
</script>

<style>
.rsam-info.card-body {
   background-color: #ffffee;
   margin-bottom: 15px;
}
.sam-info.card-body {
   background-color: #def6c5;
}
</style>
