<template>
    <div class="row justify-content-md-center alert alert-warning">
        <h3>Breaking News</h3>
    </div>
    <div class="row">
    <table :class='tab_class'>
    <tbody>
    <tr v-for='stat in stats' :key="stat.id">
      <td :class="item_class(stat.level)"><b>{{stat.subject}}</b><br>{{stat.modified}} <br>
          <pre>{{ stat.report }}</pre></td>
    </tr>
    </tbody>
    </table>
   </div>
</template>
<script>
  import axios from "axios";
export default {
  name: 'NetwStats',
  emits: [ 'network_status' ],
  data () {
        return {
           stats: []
        }
  },
  mounted() {
     this.getNetworkStatus();
  },
  methods: {
     item_class(item)   { 
        if (item == 3) { 
            return "emergclass"; 
        } else { 
            if (item == 2) {
                return "warnclass";
            }
        }
        return "";
     },
     getNetworkStatus() {
        var config = { headers:
                            { Authorization: "Bearer " + this.jwt }
        };
         axios.get("/api/networkstatus", config)
         .then (
             response => {
             this.stats = response.data.data;
             if (this.stats.length > 0) {
                 this.$emit('network_status', true);
             } else {
                 this.$emit('network_status', false);
             }
          })
         .catch(error => {
             this.stats = [ { 'subject': 'Failed', 'report': error } ];
             console.log("Failed in Network Status");
             this.$emit('network_status', true);
          })
     }
  },
  computed: {
          currentRouteName() { return this.$route.name; },
          operator ()        { return this.$store.state.operator; },
          current_user ()    { return this.$store.state.current_user; },
          jwt ()             { return this.$store.state.jwt; },
          mode()             { return this.$store.state.mode; },
          tab_class()        { return "table table-hover table-striped table-" + this.mode; }
  }
}
</script>
<style scoped>
.emergclass {
   border: red 3px solid;
}
.warnclass {
   border: orange 3px solid;
}
</style>

