<template>
  <div>
  <h2 class="dbhead">Domain Registration or Transfer</h2>
  <form  @submit.prevent="processReg">
  <div class="col-sm-12">
       <div class="row justify-content-md-center">
                  <ErrorMessage :error="problems.domain" />
       </div>
       <div class="form-group row">
                 <label class="col-sm-3">Domain Name</label>
                 <input class="form-control col-sm-8" type="text" 
                        name="domain" placeholder="Domain Name" aria-label="Domain Name" 
                        v-on:focus="this.valid = false"
                        v-on:blur="this.valid = true"
                        v-model="dgData.domain" />
       </div>
       <div class="form-group row">
                 <label class="col-sm-3">Organization</label>
                 <input class="form-control col-sm-8" type="text" 
                        name="organization" placeholder="Organization" aria-label="Organization" 
                        v-model="dgData.organization" />
       </div>
       <div class="row justify-content-md-center">
                  <ErrorMessage :error="problems.contact_email" />
       </div>
       <div class="form-group row">
                 <label class="col-sm-3">Contact Email Address</label>
                 <input class="form-control col-sm-8" type="text" 
                        name="contact_email" placeholder="Email" aria-label="Email" 
                        v-model="dgData.contact_email" />
       </div>
       <div class="row justify-content-md-center">
                  <ErrorMessage :error="problems.numyears" />
       </div>
       <div class="form-group row">
                 <label class="col-sm-3">Registration Period</label>
                 <TldPrices v-bind:init="tldinit" 
                            v-bind:domain="dgData.domain" 
                            v-bind:valid="valid"
                            v-on:select_num_years="getNumYears" />
       </div>
       <div class="form-group row">
                 <label class="col-sm-4">Would you like Whois Privacy?</label>
                 <div class="form-check form-check-inline col-sm-5">
                      <label class="form-check-label" >
                      <input class="form-check-input" type="radio" 
                             name="whois_priv" 
                             v-model="dgData.whois_priv"
                             value="No" aria-label="No" />
                             No &nbsp;&nbsp;</label>
                      <label class="form-check-label">
                      <input class="form-check-input" type="radio" 
                             v-model="dgData.whois_priv"
                             name="whois_priv" value="Yes" aria-label="Yes" />
                             Yes ($5/yr) </label>
                </div>
       </div>
       <div class="form-group row">
            <label class="col-sm-4">
                   <router-link v-bind:to="{ name: 'domain-registration-help' }">Type</router-link></label>
            <div class="form-check form-check-inline col-sm-5">
                <select name="reg_type" as="select" v-model="dgData.reg_type">
                    <option v-for="(reg_type, index) in reg_types"
                            v-bind:key="index"
                            v-bind:value="reg_type"> {{ reg_type }} </option>
                </select>
            </div>
       </div>
       <div class="form-group row">
            <label class="col-sm-4">Website Options</label>
            <div class="form-check form-check-inline col-sm-5">
                <select name="website_options" as="select" v-model="dgData.website_option">
                    <option v-for="(website_option, index) in website_options"
                            v-bind:key="index"
                            v-bind:value="website_option"> {{ website_option }} </option>
                </select>
            </div>
       </div>
       <div class="form-group row">
           <p>Please include any special instructions below:<br></p>
           <textarea class="form-control" name="instructions" v-model="dgData.instructions">
           </textarea>
       </div>
       <div class="row">
         <p>
             You must agree to the OpenSRS Registration Agreement to continue. Please note the following:
         </p>
         <div class="oldstyle">
            <ul>
                <li>Tucows/OpenSRS is Southwest Cyberport's domain registration partner.</li>
                <li>Each top-level domain (TLD, such as .com, .org, .doctor, .co.uk, etc.) 
                is governed by both ICANN and the registry which owns that TLD. </li>
                <li>The Registration Agreement includes by reference the policies and procedures and requirements of ICANN and all of the domain registries.</li>
                <li>The Registration Agreement names Tucows/OpenSRS as the Designated Agent for your domain. This allows Tucows 
                to automatically approve of Registrant changes on your behalf instead of requiring a back-and-forth 
                email approval process for every registrant change.</li>
                <li>You must agree to the Registration Agreement in order for SWCP to register or transfer a domain on your behalf.</li>
            </ul>
             <div class="row justify-content-md-center">
                <button type="button" class="btn btn-info" @click="showModal">
                      Read the Registration Agreement
                </button>
             </div>
         </div>
       </div>
       <div class="row mt-3">
          <div class="alert alert-info">
              <h4>Special rules apply to .app, .dev, and .page domains</h4>
              .APP, .DEV and .PAGE domains are secure domains that require HTTPS for all websites of these TLD types.
              You can buy your .APP, .DEV or .PAGE domain name now, but for it to work correctly in any browser, you must first configure HTTPS serving.
              For more information about setting up HTTPS, including resources to obtain an SSL certificate, please visit SWCP's SSL Certificates
          </div>
        </div>
  </div>
  <div class="form-group row">
        <label class="col-sm-2">Please Choose.</label>
        <div class="form-check col-sm-8">
             <input class="form-check-input" type="radio" 
                             name="terms" 
                             v-model="dgData.terms"
                             value="DontAgree" aria-label="DontAgree"/>
             <label class="form-check-label" >No, I don't agree.</label><br>
             <input class="form-check-input" type="radio" 
                             v-model="dgData.terms"
                             name="terms" value="Agree" aria-label="Agree" />
             <label class="form-check-label">Yes, I have read the Registration Agreement and agree to all terms.</label>
        </div>
  </div>
  <div class="form-group row justify-content-md-center">
       <button class="btn btn-outline-info align-center"
               type="submit">Register Domain</button>
  </div>
  </form>
   <Modal v-model:body="bodytext" v-show="isModalVisible"
      title="OpenSRS Agreement"
      footer=""
      @close="closeModal" />

  </div>
</template>

<script>
   import axios from "axios";
   import dayjs from "dayjs";
   import { useVuelidate } from '@vuelidate/core';
   import { required, email, helpers } from '@vuelidate/validators';
   import ErrorMessage from '@/components/ErrorMessage.vue';
   import TldPrices from '@/components/TldPrices';
   import Modal from '@/components/Modal';
   import { build_mail_message } from '@/helpers/utils.js';
   import { DomReg_template, DomReg_items } from '@/helpers/mail_templates/DomReg.js';

   export default {
      name: 'DomReg',
      components: { ErrorMessage, TldPrices, Modal },
      emits: [ 'message' ],
      mounted() {
          this.bodytext = this.getAgreement();
          this.date = dayjs().format('MMM D, YYYY h:mm:ss A');
      },
      setup () {
        return { v$: useVuelidate() }
      },
      methods: {
         closeModal () { this.isModalVisible = false; },
         showModal ()  { this.isModalVisible = true; },
         responseMessage(title, body, type, alert, footer, menu) {
                 var args = { title: title, body: body,
                              type: type, alert: alert,
                              footer: footer, menu: menu };
                 this.$store.commit('setStatus', args);
                 this.$emit('message');
                 return;
         },
         clearProbs() {
            this.fields.forEach ((item) => {
               this.problems[item] = '';
            });                  
         },             
         getAgreement () {
            var url = "/api/gettext";
            var config = { };
            axios.post(url, this.textData, config )
             .then (
                     response => {
                           this.bodytext = response.data.data;
                     })
             .catch(error => {
                     var notes = "";
             })
         },
         sign_agreement() {
            var url = "/api/signagreement";
            var config = { headers:
                            { Authorization: "Bearer " + this.jwt }
                         };
            this.signData.domain = this.dgData.domain;
            axios.post(url, this.signData, config )
             .then (
                     response => {
                           this.bodytext = response.data.data;
                     })
             .catch(error => {
                      console.log("Failed");
                      console.log(error);
                      this.responseMessage('Signature Failed', error, 'modal', 'serious', '', []);
             })
         },
         send_message( msg ) {
            var url = "/api/sendmessage";
            var success = "Your domain registration has been submitted. <br>If you have any questions you can reach us at 505-232-7992 or help@swcp.com.";
            var config = { headers:
                            { Authorization: "Bearer " + this.jwt }
                         };
            this.sendData.send_from = this.customer_email;
            this.sendData.message = msg;
            this.sendData.subject = "Domain registration " + this.dgData.domain;
            this.sendData.footer = this.footer_tag;
            axios.post(url, this.sendData, config )
            .then (
                   response => {
                      this.response_status = response.data.status;
                      if (this.response_status) {
                          this.responseMessage('Domain Registration Submitted', success, 'thankyou', 
                                               'info', '', [ {name: 'Register another domain', 'url': 'domain-registration'}] );
                      } else {
                          this.responseMessage('Error sending registration', response.data.message, 'modal', 'serious', '', []);
                      }
                   })
            .catch(error => {
                      if (error.response) {
                         if (error.response.data.status && error.response.data.status == "expired_token") {
                             this.$store.dispatch('expired');
                         }
                      }
                      this.response_status = error;
                      this.responseMessage('Problems with Registration', error, 'modal', 'serious', '', []);
                   })

         },
         async processReg({ e }) {
            const result = await this.v$.$validate();
            var msg = "";
            var message = "";
            var property;

            if (! result) {
                  this.clearProbs();
                  msg = "Validation problems found:";
                  this.v$.$errors.forEach (err => {
                      property = err.$property;
                      message = err.$message;
                      if (message != "") {
                          msg += " " + property + "=" + message + "\n";
                      }
                      this.problems[property] = message;
                  });
                  console.log(msg);
             } else {
               if (this.dgData.terms == "DontAgree") {
                   alert("We can't register your domain unless you agree to the terms");
               } else {
                   if (this.dgData.numyears == '') {
                       alert("Please select number of years for the registration");
                   } else {
                       this.send_message(this.constructMessage());
                       this.sign_agreement();
                   }
               }
            }
         },
         constructMessage() {
           var totalprice = this.dgData.price;
           if (this.dgData.whois_priv == "Yes") { totalprice += 5; }

           var subject  = "Domain registration request for " + this.current_user;
           var from  = this.customer_email;
           var message = "";
           var ess = (this.dgData.numyears > 1) ? "s" : "";
           if (this.dgData.website_option != "NONE") {
               DomReg_items.additional_fees = "(Additional fees may apply for web hosting options)";
           }

           DomReg_items.logname = this.current_user;
           DomReg_items.domain = this.dgData.domain;
           DomReg_items.reg_type = this.dgData.reg_type;
           DomReg_items.organization = this.dgData.organization;
           DomReg_items.contact_email = this.dgData.contact_email;
           DomReg_items.numyears = this.dgData.numyears;
           DomReg_items.ess = ess;
           DomReg_items.whois_priv = this.dgData.whois_priv;
           DomReg_items.website_option = this.dgData.website_option;
           DomReg_items.profit_nonprofit = this.dgData.profit_nonprofit;
           DomReg_items.totalprice = totalprice;
           DomReg_items.notes = this.dgData.instructions;
           DomReg_items.date = this.date;

           message = build_mail_message (DomReg_template, DomReg_items);
           return message;
         },

         getNumYears(numyears, price) {
            this.dgData.numyears = numyears;
            this.dgData.price = price;
         }
      },
      computed: {
          footer_tag () {
              if (this.operator === this.current_user) {
                 return "Message sent by " + this.operator;
              } else {
                 return "Message sent by " + this.operator + " for " + this.current_user;
              }
          },
          currentRouteName() { return this.$route.name; },
          operator ()        { return this.$store.state.operator; },
          current_user ()    { return this.$store.state.current_user; },
          jwt ()             { return this.$store.state.jwt; },
          customer_email () { return this.$store.state.reply_to; }
      },
      data () {
        return {
           isModalVisible: false,
           date: '',
           bodytext: '',
           signData: {
               domain: ''
           },
           sendData: {
               send_from: '',
               send_to: 'help@swcp.com,hostmaster@swcp.com',
               footer: '',
               message: ''
           },
           textData: {
               text: 'opensrs-agreement.html'
           },
           tldinit: "Number of years to register",
           valid: false,
           reg_types: [ "New Domain",
                        "SWCP/OpenSRS will be Registrar Only",
                        "SWCP will be DNS Host Only",
                        "SWCP will be Registrar & DNS Host",
                        "SWCP will be Web/Email Host Only"],
           website_options: [ "NONE", 
                              "Basic Webhosting",
                              "Pro Webhosting",
                              "Alias for another site" ],
           dgData: {
              domain: '',
              organization: '',
              contact_email: '',
              numyears: '',
              price: '',
              reg_type: 'New Domain',
              whois_priv: 'No',
              website_option: 'NONE',
              profit_nonprofit: 'profit',
              instructions: '',
              terms: 'DontAgree' 
           },
           problems: {
              domain: '',
              contact_email: '',
              numyears: ''
           },
           fields: [ 'domain', 'contact_email', 'numyears' ],
        }
      },
      validations () {
          return {
             dgData: {
                   domain: { required },
                   contact_email: { email },
                   numyears: { required }
             }
          }
      }
   }

</script>

<style>
::placeholder { 
  color: #a2bbd3 !important;
}
div.oldstyle ul {
    list-style-type: square !important;
    padding-left: 1em;
}
.oldstyle ul>li {
    display: list-item;
}
</style>
