<template>
  <section>
   <div class="col-12">
    <h2 class='dbhead'>Account Contacts for <span class="swcp-em">{{current_user}}</span></h2>
   </div>

   <div class="col-12">
        <vue-good-table
          :columns="columns"
          :rows="rows"
          :theme="vgt_theme"
          @on-row-click="selectRow"
          :sort-options="{
              enabled: true,
              initialSortBy: {field: 'contact', type: 'asc'}
          }"
          :pagination-options="{
            enabled: false
          }"
          :search-options="{
             enabled: false,
             placeholder: 'Search All Fields'
           }"
           styleClass="vgt-table bordered condensed"
         >
       </vue-good-table>
   </div>
  </section>
  <section v-if="selected_row.tcid != 0" class="section_box">
   <div class="col-12">
       <h3>{{ selected_row.contact }}</h3>
       <div class="row">
         <div class="col-4">
           <table :class="tab_class">
             <tr><td>Has restrictions?</td><td>{{ yesno(selected_row.restricted) }}</td></tr>
             <tr><td>Technical Contact?</td><td>{{ yesno(selected_row.technical) }}</td></tr>
             <tr><td>Billing Contact?</td><td>{{ yesno(selected_row.billing) }}</td></tr>
           </table>
         </div>
         <div class="offset-1 col-6">
           <section class="section_box">
           <h4>Contact Info</h4>
             <span v-html="newlines_to_br(selected_row.contact_info)"></span>
           </section>
         </div>
       </div>
       <section class="section_box">
          <div class="col-12">
             <h4>Restrictions</h4>
             <span v-html="newlines_to_br(selected_row.restriction)"></span>
          </div>
       </section>
       <div class="text-center">
            <button class="btn btn-info" v-on:click="editTechContact">Edit Contact</button>&nbsp;&nbsp;
            <button class="btn btn-info" v-on:click="deleteTechContact">Delete Contact</button>
       </div>
   </div>
 </section>
<div class="col-12 text-center">
   <button class="btn btn-info" v-on:click="addTechContact">Add New Contact</button>
</div>

</template>

<script>
import axios from "axios";
import VueGoodTable from '@/components/vue3-good-table/Table';
export default {
    name: 'TechContacts',
    components: { VueGoodTable },
    emits: [ 'message' ],
    mounted () {
        this.getTechContacts();
    },
    watch: {
          current_user: function (newUser, oldUser) {
            console.log("Watch called for " + newUser);
            this.getTechContacts();
          }
    },
    methods: {
       addTechContact () {
          this.$router.push({ name: 'add-tc-contact' });
       },
       editTechContact () {
          this.$router.push({ name: 'edit-tc-contact', params: { tcid: this.selected_row.tcid }});
       },
       deleteTechContact () {
          var url = "/api/delete-contact";
          var config = { headers:
                           { Authorization: "Bearer " + this.jwt }
                        };
          this.curr_contact = this.selected_row.contact;
          this.deleteData.id = this.selected_row.tcid;
          this.deleteData.account = this.current_user;
          axios.post(url, this.deleteData, config )
               .then (
                  response => {
                      this.responseMessage('Success', 'Deleted ' + this.curr_contact,
                                            'review', 'info', '', [] );
                      this.getTechContacts();

                  })
               .catch(error => {
                  if (error.response.data.status && error.response.data.status == "expired_token") {
                      this.$store.dispatch('expired');
                  } else {
                      this.responseMessage('Deletion failed', 
                           error, 'modal', 'serious', '', []);
                  }
               })
       },
       newlines_to_br(str) {
          if (str == null) { return ""; }
          return str.replaceAll('\n', '<br>');
       },
       yesno (str) {
            if (str == "1") { return "Yes"; }
            if (str == "0") { return "No"; }
            return "Unknown";
       },
       selectRow(params) {
            var row = params.row;
            this.selected_row = row;
            // this.$router.push({ name: 'edit-contact', params: { alias: row.name }});
       },
       colorCode (row) {
          if (row.tcid == this.selected_row.tcid ) { return "blue-class"; }
          else { return "white-class"; }
       },      
       responseMessage(title, body, type, alert, footer, menu) {
              var args = { title: title, body: body,
                           type: type, alert: alert,
                           footer: footer, menu: menu };
              this.$store.commit('setStatus', args);
              console.log("Contacts: responseMessage");
              console.log(args);
              this.$emit('message');
              return;
       },
       getTechContacts() {
           var url = "/api/contacts/";
           var config = { headers:
                            { Authorization: "Bearer " + this.jwt }
                         };
           console.log("Get Tech Contacts for " + this.current_user);
           this.selected_row = { "tcid": 0 };
           axios.get(url + this.current_user, config )
               .then (
                  response => {
                      this.rows = response.data.data.contacts;
                  })
               .catch(error => {
                  if (error.response.data.status && error.response.data.status == "expired_token") {
                      this.$store.dispatch('expired');
                  } else {
                      this.responseMessage('Permission denied', 
                           "Secondary accounts don't have access to this information", 'modal', 'warning', '', []);
                  }
               })
       }
    },
    computed: {
          currentRouteName() { return this.$route.name; },
          operator ()        { return this.$store.state.operator; },
          current_user ()    { return this.$store.state.current_user; },
          mode()             { return this.$store.state.mode; },
          jwt ()             { return this.$store.state.jwt; },
          vgt_theme()        {
              if (this.mode == "light") {
                 return "swcptab";
              } else {
                 return "swcpdark";
              }
          },
          tab_class () {
            return "table table-responsive table-borderless table-" + this.mode;
          }
   },
   data () {
      return {
        selected_row: { tcid: 0 },
        selected_cell: '',
        curr_contact: '',
        deleteData: {
            id: 0,
            account: ''
        },
        addData: {
            logname: '',
            contact: '',
            contact_info: '',
            restricted: '',
            technical: '',
            billing: '',
            restriction: ''
        },
        columns: [
             {
               field: "contact",
               label: "Contact",
               type: "string",
               sortable: true,
               tdClass: this.colorCode
             },
             {
               field: "contact_info",
               label: "Info [Select row to see more information]",
               sortable: false,
               tdClass: this.colorCode
             }
        ],
        domains: [],
        rows: [ ]
      }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

section {
   margin-top: 20px;
   margin-bottom: 20px;
}
.section_box {
   padding: 20px;
   border: 1px #dcdcdc solid;
   border-radius: 20px;
   box-shadow: 5px 5px 7px rgb(0 100 200 / 0.25);
}
</style>
