<template>
   <div v-if="logged_in" :class='boxmode'> <h3>{{banner_msg}}</h3> </div>
   <div v-else :class='boxout'> <h3>{{ logout_msg }}</h3> </div>
</template>

<script>

export default {
   name: "Initial",
   data () {
     return {
        banners: { 'light': '../assets/img/triangles-banner.png',
                   'dark':  '../assets/img/yellow-8167591_1280-banner.jpg'
                 }
     }
   },
   computed: {
      logged_in()  { return this.$store.state.logged_in; },
      logout_msg() { return this.$store.state.logout_msg; },
      banner_msg() { return this.$store.state.banner_msg; },
      mode()       { return this.$store.state.mode; },
      boxmode()    { return "box box-" + this.mode; },
      boxout()     { return "box box-lightsubtle"; }
   }
}
</script>

<style scoped>
.box {
   padding: 15px 25px 10px 25px;
   border: 1px #555555 solid;
   color: white;
   background-color: #333388;
   width: 100%;
   margin-top: 0px;
   min-height: 65px;
   display: inline-block;
   text-align: center;
}
.box-lightsubtle {
   background-image: url('@/assets/img/white-patt.jpg');
}
.box-light {
   background-image: url('@/assets/img/triangles-banner.png');
}
.box-altdark {
   background-image: url('@/assets/img/art-deco-banner.png');
}
.box-dark {
   background-image: url('@/assets/img/yellow-8167591_640.jpg');
}
.box-darkmarble {
   background-image: url('@/assets/img/multi198.jpg');
}
.box-darklines {
   background-image: url('@/assets/img/dgrey027.gif');
}
.box h3 {
   margin-bottom: 0px;
}

</style>
