<template>
   <div class="container" id="accordian">
       <div class="row">
           <h2>Domain Registration</h2>
            <p>
               There are many different ways that SWCP provides DNS service 
               for our customers. The option you select here will tell the Network 
               Operations Center how to set up the domain with our system.
            </p>
       </div>
       <div class="row">
        <div class="col-md-6">
         <a class="btn btn-primary" data-toggle="collapse" href="#helpBox1" 
            role="button" aria-expanded="false" aria-controls="helpBox1">
            <i class="fa fa-info-circle"></i> Registering a New Domain?
          </a>
        </div>
        <div class="col-md-6">
           <a class="btn btn-primary align-center" data-toggle="collapse" href="#helpBox2" 
              role="button" aria-expanded="false" aria-controls="helpBox2">
              <i class="fa fa-info-circle"></i> Transferring Domains?
           </a>
         </div>
       </div>
        <div class="row">
            <hr />
        </div>
        <div class="row">
          <div class="collapse" id="helpBox1" data-parent="#accordian">
           <h3>Select "New Domain"</h3>
           <div :class="cardBody">
             <p>
               This is a new domain, not currently registered on the Internet. We will register 
               it with OpenSRS/SWCP as the registrar. SWCP will also be the DNS host. We will 
               set up services for the domain according the the web/email options you choose. 
               It is usually assumed that if we register a new domain we will also be the DNS host. 
               If that is not the case, include the names of the DNS servers that we should use to set 
               the NS records for this domain in the Notes field.
             </p>
           </div>
         </div>
       </div>

        <div class="row">
           <div class="collapse" id="helpBox2" data-parent="#accordian">
              <h3>Options for Transferring Domains</h3>
              <div class="card-columns">
               <div :class="cardBody">
                  <h4 class="card-title">SWCP/OpenSRS Will Be Registrar Only</h4>
                    SWCP will be the registrar, and will charge the end-user for yearly domain renewals, 
                    but we will NOT provide DNS services. We will set the NS records to point to another 
                    provider's DNS servers. Please mention in the Notes field whether we should change 
                    the NS records for the domain or leave them as-is.
               </div>
                <div :class="cardBody">
                 <h4 class="card-title">SWCP Will Be Registrar & DNS Host</h4>
                   In this case we will transfer the domain to OpenSRS, and we will provide DNS service for the domain. 
                   We will set up email and web options according to the options chosen on this form.
                </div>
               <div :class="cardBody">
                 <h4 class="card-title">SWCP Will Be DNS Host Only</h4>
                   SWCP will provide DNS service for the domain, but we will not transfer the domain to 
                   OpenSRS. The domain will remain at its current registrar, and the customer will continue 
                   to pay that registrar for yearly renewals. The customer will set the NS records on the domain 
                   to point at SWCP's DNS servers. If the customer wants us to make this change for them we are happy 
                   to do so IF the customer can provide us with the username and password for their registrar account.
                </div>
                <div :class="cardBody">
                 <h4 class="card-title">SWCP Will Be Web/Email Host Only</h4>
                   In this case, we will NOT transfer the registration to OpenSRS, and we will NOT provide 
                   DNS service for the domain. The existing DNS host will point DNS A records and/or MX records 
                   at SWCP servers. Usually this is done for just email or just a web site but not both.
                </div>
              </div>
           </div>
        </div>
        <div class="row">
            <hr />
        </div>
        <div class="row"> 
           <h4>If you have a situation which is not covered by one of these options, <br>please email NOC (noc@swcp.com) for advice.</h4>
        </div>
        <div class="row justify-content-center">
           <GoBack title="Back"></GoBack>
        </div>
  </div>
</template>

<script>

import GoBack from "@/components/GoBack";
export default {
      name: "DomRegHelp",
      components: { GoBack },
      data () {
        return {
          card_modes: {
                  light: "card border-primary card-body drh-info",
                  dark:  "card border-primary card-body bg-dark"
          },
          account: ''
        }
      },
      computed: {
          currentRouteName() { return this.$route.name; },
          operator ()        { return this.$store.state.operator; },
          current_user ()    { return this.$store.state.current_user; },
          jwt ()             { return this.$store.state.jwt; },
          mode()             { return this.$store.state.mode; },
          cardBody()         { return this.card_modes[this.mode]; }
      }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped >
.rsam-info.card-body {
   background-color: #ffffee;
   margin-bottom: 15px;
}
.drh-info.card-body {
   background-color: white;
   background-image: linear-gradient(white, white, #eeeeee);
}
.card-columns { 
    column-count: 2;
}
@media (max-width: 767px) 
.card-columns { 
    column-count: 1;
}

</style>
