<template>
  <div>
   <h2 class='dbhead'> Show Logs {{vsData.virt_server}}</h2>
   <h3 v-if='totalRows==0'>This may take a few minutes to be registered for viewing logs</h3>
   <i>Total Rows: {{ totalRows }}, New Rows: {{ numRows }}</i>
    <vue-good-table
      :columns="columns"
      :rows="rows"
      :theme="vgt_theme"
      :pagination-options="{
        enabled: true,
        mode: 'records',
        perPage: 25,
        position: 'top',
        perPageDropdown: [25, 50, 75, 100],
        dropdownAllowAll: false,
        setCurrentPage: 1,
        nextLabel: 'next',
        prevLabel: 'prev',
        rowsPerPageLabel: 'Rows per page',
        ofLabel: 'of',
        pageLabel: 'page', // for 'pages' mode
        allLabel: 'All',
      }"
      :search-options="{
         enabled: false,
         placeholder: 'Search All Fields'
       }"
       styleClass="vgt-table bordered condensed"
     >
   </vue-good-table>
   <hr />
   <div class="row justify-content-center">
        <GoBack title="Back"></GoBack>
   </div>       

  </div>
</template>

<script>

import VueGoodTable from '@/components/vue3-good-table/Table';
import GoBack from '@/components/GoBack';
import axios from "axios";

export default {
     name: 'ShowLogs',
     components: {
        VueGoodTable, GoBack
     },
     emits: [ 'message' ],
     data () {
         return {
           account: '',
           pollInterval: null,
           numRows: 0,
           totalRows: 0,
           vsData: {
              virt_server: '',
              vid: 0,
              logname: '',
              last_log_id: 0
           },
           columns: [  
             {
               field: "msg",
               label: "Log",
               filterOptions: {
                  enabled: true, // enable filter for this column
                  placeholder: 'Search', // placeholder for filter input
               },
               sortable: true
             }
           ],
           newrows: [],
           rows: []
         }
     },
     mounted() {
         this.getLogs();
         this.pollInterval = setInterval(function() { 
                  this.getLogs() 
         }.bind(this), 12000);
     },
     watch: {
             current_user: function (newUser, oldUser) {
               // console.log("watch called in WS for " + newUser);
               this.account = this.current_user;
               this.$router.push({ name: "home"});
             }
     },
     beforeUnmount: function(){
          // console.log("Destroying the polling interval");
          clearInterval(this.pollInterval);
     },
     methods: {
       responseMessage(title, body, type, alert, footer, menu) {
              var args = { title: title, body: body,
                           type: type, alert: alert,
                           footer: footer, menu: menu };
              this.$store.commit('setStatus', args);
              this.$emit('message');
              return;
       },
       craft_message (data) {
           let msg = "";
           msg = "logname=" + data.logname + " UID=" + data.uid + " virt=" + data.virtname + " vid=" + data.vid;
           return msg;
       },
       getLogs () {
         var config = { headers:
                             { Authorization: "Bearer " + this.jwt }
                      };
         this.vsData.logname = this.current_user;
         this.vsData.virt_server = this.$route.params.virtname;
         this.vsData.vid = this.$route.params.vid;
         console.log(this.$route.params);

         if (this.vsData.virt_server != "") {
             axios.post("/api/geterrorlogs", this.vsData, config )
             .then (
                     response => {
                           if (response.data.status == 1) {
                              this.newrows = response.data.data.logs;
                              if (this.newrows) {
                                  this.rows = this.newrows.concat(this.rows);
                                  this.totalRows = this.rows.length;
                              }
                              this.numRows = response.data.data.numrows;
                              this.vsData.last_log_id = response.data.data.new_last_log_id;
                           } else {
                              // console.log(response.data);
                              this.responseMessage(response.data.message, this.craft_message(response.data.data), 'modal', 'serious', '', []);
                           }
                     })
             .catch(error => {
                     var notes = "";
                     if (error.response) {
                        if (error.response.data.message) {
                            notes = error.response.data.message;
                        }
                        if (error.response.data.status && error.response.data.status == "expired_token") {
                            this.$store.dispatch('expired');
                        }
                     } 
                     this.rows = [ { 'id': 0, 
                                     'msg': 'No logs found' } ];
                     })
           }
   
       }
     },
     computed: {
          currentRouteName() { return this.$route.name; },
          operator ()        { return this.$store.state.operator; },
          current_user ()    { return this.$store.state.current_user; },
          mode()             { return this.$store.state.mode; },
          jwt ()             { return this.$store.state.jwt; },
          vgt_theme()        {
              if (this.mode == "light") {
                 return "swcptab";
              } else {
                 return "swcpdark";
              }
          }
     }
   
   }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
