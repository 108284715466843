<template>
  <div>
    <h2 class='dbhead'>Generate Passwords</h2>
    <div class="row">
     <div class="col-sm-6">
       <h3>Word Based Passwords</h3>
          Several random words glued together with some numbers.
          In case you're curious why this is a pretty good idea .. here's
          an <a target='boo' href="https://xkcd.com/936/">explanation by XKCD</a>.
          You can generate up to 28 passwords. Set the 'use numbers' checkbox if
          you want numerical separators in the word based passwords.
          <br><br>
      </div>
     <div class="col-sm-6">
       <h3>Mixed Character Passwords</h3>
          Generate passwords that include upper case, lowercase, numbers and
          special characters.<br><br>
      </div>
    </div>
    <div class="row">
     <div class="col-sm-4">
         <b>Number of passwords</b>
         <div class="my-2 my-lg-0">
            <div class="form-row">
              <div class="col">
                <input class="form-control" type="text" v-model="number_passwords"
                       placeholder="Max 28" aria-label="Number" />
                Use Numbers: <input type="checkbox" true-value="1" false-value="0" v-model="use_numbers" />
              </div>
            </div>
         </div>
     </div>
     <div class="col-sm-8">
         <b>Select Password Type</b>
         <div class="my-2 my-lg-0">
            <div class="form-row">
              <div class="col">
                <button v-on:click="genWords" class="btn btn-outline-primary"
                    type="submit">Word Based Passwords</button>
              </div>
              <div class="col">
                <button v-on:click="genHard" class="btn btn-outline-primary"
                    type="submit">Mixed Char Passwords</button>
              </div>
              <div class="col">
                <button v-on:click="genSoft" class="btn btn-outline-primary"
                    type="submit">Soft Passwords</button>
              </div>
            </div>
         </div>
     </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
         <h2 class="dbhead">Passwords</h2>
         <table :class="tab_class">
           <thead></thead>
           <tbody>
              <tr v-for="i in rowCount" :key="i.id">
                   <td v-for="pwd in itemCountInRow(i)" :key="pwd.id">
                       {{ pwd }}
                   </td>
              </tr>
           </tbody>
         </table>
      </div>
    </div>
    <div>
       <hr>
        <strong>Remember:</strong> don't re-use passwords, and be sure your passwords don't have
        obvious ties to you. Social engineering often works better than 
        password cracking.
        
    </div>
  </div>
</template>

<script>

import axios from "axios";
export default {
      data () {
        return {
           number_passwords: 8,
           use_numbers: 0,
           password_strength: 'soft',
           itemsPerRow:2,
           axios_msg: '',
           passwords: []
        }
      },
      methods: {
         genWords() {
            var url = "/api/genwordpass/" + this.number_passwords + "/" + this.use_numbers;
            this.genPass(url);
         },
         genSoft() {
            var url = "/api/gensoftpass/" + this.number_passwords;
            this.genPass(url);
         },
         genHard() {
            var url = "/api/genpass/" + this.number_passwords;
            this.genPass(url);
         },
         genPass(url) {
            var config = { headers:
                          { Authorization: "Bearer " + this.jwt }
                       };

            axios.get(url, config )
            .then (
                   response => {
                      this.axios_message = response.data.message; 
                      this.passwords = response.data.data.passwords;
                   })
            .catch(error => {
                       if (error.response.data.status && error.response.data.status == "expired_token") {
                           this.$store.dispatch('expired');
                       }
                       this.passwords = [ this.axios_message  ];
                   })
         },
         itemCountInRow:function(index){
            return this.passwords.slice((index - 1) * this.itemsPerRow, index * this.itemsPerRow)
         }
      },
      computed: {
           rowCount:function(){     
               return Math.ceil(this.passwords.length / this.itemsPerRow);
           },
           user () { return this.$store.state.user; },
           staff () { return this.$store.state.staff; },
           logged_in () { return this.$store.state.logged_in; },
           jwt () { return this.$store.state.jwt; },
           mode() { return this.$store.state.mode; },
           tab_class () {
               if (this.mode == "light") { return "table table-bordered"; }
               return "table table-bordered table-dark";
           }  
      }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped> 
.table td, .table th {
    padding: .5rem .75rem;
}
.fa {
    color: dodgerblue;
}
.table th {
    color: #aaaaaa;
}
</style>
