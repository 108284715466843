<template>

  <form v-on:submit.prevent="processPayment" ref="form">
    <div class="row justify-content-md-center">
       <div class="col-9 offset-3">
         <ErrorMessage :error="problems.amount" />
        </div>
    </div>
    <div class="form-group row">
         <label class="col-sm-3" for="amount">Amount</label>
         <input name="amount" readonly class="form-control col-sm-8" type="text" v-model="userData.amount"
                id="amount" placeholder="Amount" aria-label="Amount" />
    </div>
    <div>
       <CreditCard v-model:ccname="userData.ccname"
                   v-model:ccnumber="userData.ccnumber"
                   v-model:address="userData.address"
                   v-model:city="userData.city"
                   v-model:state="userData.state"
                   v-model:zipcode="userData.zipcode"
                   v-model:country="userData.country"
                   v-model:expdate="userData.expdate"
                   v-model:cvv="userData.cvv"
                   v-model:cardtype="userData.cardtype"
                   v-model:problems="problems"
                   v-on:clear-probs="clearProblems"
                   :key="cckey">
        </CreditCard>
    </div>

    <div class="row justify-content-md-center">
         <ErrorMessage :error="problems.email" />
    </div>
    <div class="form-group row">
         <label class="col-sm-3" for="email">Send receipt to</label>
         <input name="email" type="text" class="form-control col-sm-8" v-model="userData.email"
                     id="email" placeholder="Email Address" aria-label="Email Address" />
     </div>
     <div class="form-group row">
           <label class="col-sm-3" for="comments">Comments</label>
           <textarea id="comments" class="form-control col-sm-8" rows=5 
                     v-model="userData.comments">
           </textarea>
     </div>
     <div class="form-group row">
               <div class="offset-sm-4 col-sm-4">
                    <button class="btn btn-outline-primary"
                            type="submit">Submit</button>
               </div>
               <div class="col-sm-4"></div>
     </div>

   </form>
</template>

<script>
   import axios from 'axios';
   import CreditCard from '@/components/CreditCard.vue';
   import ErrorMessage from '@/components/ErrorMessage.vue';
   import { useVuelidate } from '@vuelidate/core';
   import { required, numeric, minValue, helpers } from '@vuelidate/validators';
   import { luhn, valid_mmyy_date, valid_expiration_date, positive_amount } from '@/helpers/utils.js';

   export default {
      name: 'CoworkPayForm',
      components: { CreditCard, ErrorMessage },
      setup: () => ( { v$: useVuelidate() } ),
      validations: {
        amount: { 
            positive_amount: helpers.withMessage('Amount must be > 0', positive_amount),
            required: helpers.withMessage('Required', required),
            numeric: helpers.withMessage('Must be a number', numeric)
        },
        name: { required: helpers.withMessage('Required', required) }
      },
      props: {
          name: String,
          amount: Number,
          cowork_product: Object,
          quantity: Number
      }, 
      emits: [ 'made-payment', 'message', 'noname' ],
      computed: {
          footer_tag ()      { return "Message sent by " + this.operator; },
          currentRouteName() { return this.$route.name; },
          operator ()        { return this.$store.state.operator; },
          current_user ()    { return this.$store.state.current_user; },
          jwt ()             { return this.$store.state.jwt; }
      },
      watch: {
          amount: function (newSum, oldSum) {
               this.userData.amount = this.amount;
               this.cckey++;
          },
          name: function (newName, oldName) {
               this.userData.ccname = this.name;
               this.cckey++;
          }
       },
      methods: {
         appendNotice(msg) {
           var body = this.$store.state.status_body + msg;
           this.$store.commit('setStatusBody', body);
         },
         responseMessage(title, body, type, alert, footer, menu) {
           var args = { title: title, body: body,
                        type: type, alert: alert,
                        footer: footer, menu: menu };
           this.$store.commit('setStatus', args);
           this.$emit('message');
           return;
         },
         kickIt() {
            this.cckey++; 
         },
         clearProblems() {
            this.fields.forEach ((item) => { this.problems[item] = ''; });
         },
         clearForm() {
            this.fields.forEach ((item) => { this.userData[item] = ''; });
            this.userData.country = "US";
            this.clearProblems();
            this.cckey++;
         },
         sendMsg(send_from, send_to, message, subject) {
            var url = "/api/sendmessage";
            var config = { headers:
                            { Authorization: "Bearer " + this.jwt }
                         };
            this.msgData.send_from = send_from;
            this.msgData.send_to = send_to;
            this.msgData.message = message;
            this.msgData.subject = subject;
            this.msgData.footer = this.footer_tag;
            axios.post(url, this.msgData, config )
            .then (
                   response => {
                      this.response_status = response.data.status;
                   })
            .catch(error => {
                      if (error.response) {
                         if (error.response.data.status && error.response.data.status == "expired_token") {
                             this.$store.dispatch('expired');
                         }
                      }
                      this.response_status = error;
                   })
         },
         who_to_message (email) {
            if (email == "") {
               return "No receipt sent";
            } else { 
               return "Receipt sent to " + email;
            }
         },
         sendEmail (trans_id, account, ccn4, amount, client_email, 
                    product, quantity, comments, payer, name) {
// Message 1: Receipt of payment for the client
            var to = client_email;
            var from = "info@ideasandcoffee.com";
            var now = new Date;
            var curr_time = now.toString();
            var who_to = this.who_to_message(client_email);
            var body = 
 `Ideas and Coffee Receipt for ${name}
   ----------------------------------------------------------
   We received a payment of $${amount} from ${payer}

   CCN: ....${ccn4}
   Transaction ID: ${trans_id}
   ${who_to}
   Date: ${curr_time}
   Product: ${product}
   Quantity: ${quantity}
   Notes: ${comments}
                      `;
            var subject = "Ideas and Coffee Payment received";
            // Check to see if the user wants a receipt.
            if (client_email != '') {
                this.sendMsg(from, to, body, subject);
            }

// Message 2: Notification to accounting of payment received.
            to = "accounting@swcp.com";
            from = "noc@swcp.com";
            subject = `Payment received for account ${account}`;
            this.sendMsg(from, to, body, subject);

         },
         buildThanks (data, form, payrec) {
            var message;
            message = "Thank you for your payment! <br>Your Transaction ID is " + data.trans_id;
            message += "<br>Amount: $" + payrec.amount + "<br>";
            message += this.who_to_message(form.email);
            return message;
         },

         async processPayment({ e }) {
//          New credit card, not in CIM.
            var url;
            var title = "";
            var body = "";
            var message, property;
            var config = { headers:
                                { Authorization: "Bearer " + this.jwt }
                         };
            var account = "cowork";
            var msg = "";

            this.clearProblems();
            const result = await this.v$.$validate();
            if (! result) {
                  msg = "Validation problems found:";
                  this.v$.$errors.forEach (err => {
                      property = err.$property;
                      message = err.$message;
                      this.problems[property] = message;
                      if (message != "") {
                          msg += " " + property + "=" + message + "\n";
                      }
                  });
                  if (this.problems['name'] != "") {
                      this.$emit('noname');
                  }
                  console.log(msg);
            } else {
                if (this.pending) { console.log("submit while pending"); return; }
                this.pending = true;
                url = "api/single-payment";
                this.userData.account = "cowork";
                axios.post(url, this.userData, config )
                .then (
                        response => {
                               if (response.data.status) {
                                  this.purchaseProduct(this.userData.account, 
                                                       this.cowork_product.pcode, 
                                                       this.quantity, 
                                                       this.name);
                                  this.auditTrail(this.name, this.cowork_product.name, this.userData.account);
                                  title = "Successful Payment";
                                  body = this.buildThanks (response.data.data, this.userData, response.data.payment_record);
                                  this.responseMessage(title, body, "thankyou", "info", '', []);
                                  this.sendEmail(response.data.data.trans_id, this.userData.account, 
                                                 this.userData.ccnumber.slice(-4), this.userData.amount, 
                                                 this.userData.email, this.cowork_product.name,
                                                 this.quantity,
                                                 this.userData.comments, this.userData.ccname, this.name);
                                  this.v$.$reset();
                                  this.clearForm();
                                  this.pending = false;
                                  this.$emit('made-payment');
                                } else {
                                  title = "No Payment made";
                                  body = response.data.message;
                                  this.responseMessage(title, body, 'modal', "serious", '', []);
                                  this.pending = false;
                                  console.log("Payment failed");
                                  console.log(response.data);
                                }
                        })
                 .catch(error => {
                              title = "API Payment failure";
                              body = "Contact noc@swcp.com " + error;
                              this.responseMessage(title, body, 'modal', "serious", '', []);
                              this.pending = false;
                           })
              }
             },
         purchaseProduct(logname, pcode, units, comment) {
            var config = { headers:
                                { Authorization: "Bearer " + this.jwt }
                         };
            var url = "api/purchase-product";
            var title, body;
            this.purcData.logname = logname;
            this.purcData.pcode = pcode;
            this.purcData.units = units;
            this.purcData.comment = comment;
            axios.post(url, this.purcData, config )
            .then (
                    response => {
                           if (!response.data.status) {
                               console.log("Coworking Purchased prob - " + response.data.message);
                               console.log(this.purcData);
                           }
                    })
             .catch(error => {
                          title = "Purchase complete - problem with recording the purchase (see NOC)";
                          body = "Contact noc@swcp.com " + error;
                          console.log(this.purcData);
                          this.responseMessage(title, body, 'modal', "serious", '', []);
                          this.pending = false;
                       })
         },
         auditTrail(name, product_name, logname) {
            var config = { headers:
                                { Authorization: "Bearer " + this.jwt }
                         };
            var url = "api/addtoaudit";
            var title, body;
            this.auditData.logname = logname;
            this.auditData.message = name + " purchased " + product_name + " (dash)";
            axios.post(url, this.auditData, config )
            .then (
                    response => {
                           if (! response.data.status) {
                               console.log("Audit trail problem:" + response.data.message);
                               console.log(this.auditData);
                           }
                    })
             .catch(error => {
                          title = "Purchase is OK, problems with logging (see NOC)";
                          body = "Contact noc@swcp.com " + error;
                          console.log(this.auditData);
                          this.responseMessage(title, body, 'modal', "serious", '', []);
                          this.pending = false;
                       })
         }
      },
      data () {
        return {
           fields: [ 'amount', 'ccname', 'ccnumber', 'address', 'city', 
                     'state', 'zipcode', 'country', 'expdate', 'cvv', 
                     'cardtype', 'account', 'email', 'comments' ],
           pending: false,
           debug: true,
           card_status: '',
           msgData: {
               send_to: '',
               send_from: '',
               subject: '',
               message: '',
               footer: ''
           },
           acctData: {
               account: ''
           },
           auditData: {
               logname: '',
               message: ''
           },
           purcData: {
               logname: '',
               pcode: 0,
               units: 0,
               comment: ''
           },
           userData: {
               amount: '',
               ccname: '',
               ccnumber: '',
               address: '',
               city: '',
               state: '',
               zipcode: '',
               country: 'US',
               expdate: '',
               cvv: '',
               cardtype: '',
               account: '',
               email: '',
               save_card: '',
               auto_bill: '',
               use_this_card: '',
               save_status: 0,
               comments: ''
           },
           problems: { 
               name: '',
               amount: '',
               ccname: '',
               ccnumber: '',
               address: '',
               city: '',
               state: '',
               zipcode: '',
               country: '',
               expdate: '',
               cvv: '',
               cardtype: '',
               email: '',
               card_status: 0
           },  
           cckey: 1,
           errors: []
        }
      }
    }

</script>
