<template>
   <div>
    <div class="row">
        <h2 class='dbhead'>Domain Summary for <span class="swcp-em">{{current_user}}</span> </h2>
    </div>
    
    <vue-good-table v-if="rows.length > 0"
      :columns="columns"
      :rows="rows"
      :theme="vgt_theme"
      styleClass="vgt-table condensed bordered"
      :sort-options="{
          enabled: true,
          initialSortBy: {field: 'domain', type: 'asc'}
      }"
      :pagination-options="{
        enabled: true,
        mode: 'records',
        perPage: 10,
        position: 'top',
        perPageDropdown: [5, 10, 25, 50],
        dropdownAllowAll: false,
        setCurrentPage: 1,
        nextLabel: 'next',
        prevLabel: 'prev',
        rowsPerPageLabel: 'Rows per page',
        ofLabel: 'of',
        pageLabel: 'page', // for 'pages' mode
        allLabel: 'All',
      }"
      :search-options="{
         enabled: false,
         placeholder: 'Search All Fields'
       }"
    >
   </vue-good-table>
   <h4 v-else>No domains found</h4>
   <div class="form-group row mt-4 justify-content-center">
        <button v-on:click="$router.push('add-webhosting')" type="button" class="spaced btn btn-primary justify-content-center">Add Web Hosting</button>
        <button v-on:click="$router.push('email-aliases')" type="button" class="spaced btn btn-primary justify-content-center">Define Email Aliases</button>
        <button v-on:click="$router.push('websites')" type="button" class="spaced btn btn-primary justify-content-center">Edit Websites</button>
   </div>

 </div>
</template>

<script>

import VueGoodTable from '@/components/vue3-good-table/Table';
import axios from "axios";


export default {
      name: 'DomainUsage',
      components: {
         VueGoodTable
      },
      data () {
        return {
          account: '',
          columns: [
                   {
                     field: "domain",
                     label: "Domain",
                     filterOptions: {
                         enabled: true, // enable filter for this column
                         placeholder: 'Search', // placeholder for filter input
                     },
                     sortable: true
                   },
                   {
                     field: "website",
                     label: "Website?",
                     type: 'string',
                     sortable: false,
                     formatFn: this.yesnoalias
                   },
                   {
                     field: "docroot",
                     label: "Docroot or Alias",
                     type: 'string',
                     sortable: false
                   },
                   {
                     field: "num_aliases",
                     label: "Number of Email Aliases",
                     type: 'number',
                     sortable: false
                   }
                 ],
          rows: [],
          prices: [],
          selected_row: {}
        }
      },
      watch: {
          current_user: function (newUser, oldUser) {
            this.getDomainUsage();
          }
      },

      mounted() {
          this.getDomainUsage();
      },
      methods: {
         yesnoalias: function (value) {
              if (value == -1) { return "Alias"; }
              if (value == 1) { return "Yes"; }
              else { return "No"; }
         },
         getDomainUsage() {
            var config = { headers:
                            { Authorization: "Bearer " + this.jwt }
                         };

            this.account = this.current_user;
            var url = "/api/domain-usage/" + this.account;
            this.rows = [ 
                          { 'domain': 'cat.carp', 
                            'website': 1, 
                            'docroot': '/users/jamii/public_html/cat.carp', 
                            'num_aliases': 20 }
                         ];
            axios.get(url, config )
            .then (
                   response => {
                          this.rows = response.data.data;
                   })
            .catch(error => {
                   if (error.response) {
                      if (error.response.data.status && error.response.data.status == "expired_token") {
                          this.$store.dispatch('expired');
                      }
                   }
                   this.rows = [ { 'domain': 'unknown', 
                                   'Status': 'Failed', 
                                   'account': this.account, 
                                   'error': error } ];
                   })
         }
      },
      computed: {
          activity()         { return this.route_to_activity(this.$route.name); },
          currentRouteName() { return this.$route.name; },
          operator ()        { return this.$store.state.operator; },
          current_user ()    { return this.$store.state.current_user; },
          mode()             { return this.$store.state.mode; },
          jwt ()             { return this.$store.state.jwt; },
          vgt_theme()        { 
              if (this.mode == "light") {
                 return "swcptab";
              } else {
                 return "swcpdark";
              }
          }
      }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.vgt-wrap__footer {
    color: #000000;
    font-size: 1.1rem;
    padding: 0.5em !important;
    background: linear-gradient(#13C5E4, #F1F3F6);
}

</style>
