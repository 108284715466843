<template>
   <div>
     <div >
      <h2 class="dbhead">Whois Lookup Domain</h2>
      <form class="col-11" @submit.prevent="lookup_domain">
        <div class="row">
          <input class="form-control" type="text" v-model="whoisData.domain" />
        </div>
        <div class="row mt-3 justify-content-center">
        <button class="col-2-offset col-2 btn btn-outline-primary"
                type="submit">Lookup</button>
        </div>
       </form>
     </div>

    <div class="row mt-3" v-if="info != ''">
      <div v-html="info"></div>
    </div>
  </div>
</template>

<script>

import axios from "axios";
export default {
      data () {
        return {
           info: '',
           whoisData: {
              domain: '',
              trim: 1
           }
        }
      },
      methods: {
          lookup_domain () {
             var config = { headers:
                             { Authorization: "Bearer " + this.jwt }
                          };

             axios.post("/api/whois", this.whoisData, config )
             .then (
                    response => {
                       this.info = response.data.data.whois;
                    })
             .catch(error => {
                       if (error.response) {
                          if (error.response.data.status && error.response.data.status == "expired_token") {
                              this.$store.dispatch('expired');
                          }
                       }
                       this.info =  error.response;
                    })
          }
      },
      computed: {
          operator ()       { return this.$store.state.operator; },
          current_user ()   { return this.$store.state.current_user; },
          jwt ()            { return this.$store.state.jwt; }
      }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped> 
.table td, .table th {
    padding: .5rem .75rem;
}
.fa {
    color: dodgerblue;
}
.table th {
    color: #333333;
}
</style>
