<template>
  <div class="hello">
    <h1>{{ msg }}</h1>
    <h3>{{ date }}</h3>
   <button type="button" class="btn btn-info" @click="showModal">
      Open Modal!
   </button>

   <Modal v-model:body="bodytext" v-show="isModalVisible"
      title="Pink Cat"
      footer="Paw"
      @close="closeModal" />

    <p>
      For a guide and recipes on how to configure / customize this project,<br>
      check out the
      <a href="https://cli.vuejs.org" target="_blank" rel="noopener">vue-cli documentation</a>.
    </p>
    <h3>Installed CLI Plugins</h3>
    <ul>
      <li><a href="https://github.com/vuejs/vue-cli/tree/dev/packages/%40vue/cli-plugin-babel" target="_blank" rel="noopener">babel</a></li>
      <li><a href="https://github.com/vuejs/vue-cli/tree/dev/packages/%40vue/cli-plugin-eslint" target="_blank" rel="noopener">eslint</a></li>
    </ul>
    <h3>Essential Links</h3>
    <ul>
      <li><a href="https://vuejs.org" target="_blank" rel="noopener">Core Docs</a></li>
      <li><a href="https://forum.vuejs.org" target="_blank" rel="noopener">Forum</a></li>
      <li><a href="https://chat.vuejs.org" target="_blank" rel="noopener">Community Chat</a></li>
      <li><a href="https://twitter.com/vuejs" target="_blank" rel="noopener">Twitter</a></li>
      <li><a href="https://news.vuejs.org" target="_blank" rel="noopener">News</a></li>
    </ul>
    <h3>Ecosystem</h3>
    <ul>
      <li><a href="https://router.vuejs.org" target="_blank" rel="noopener">vue-router</a></li>
      <li><a href="https://vuex.vuejs.org" target="_blank" rel="noopener">vuex</a></li>
      <li><a href="https://github.com/vuejs/vue-devtools#vue-devtools" target="_blank" rel="noopener">vue-devtools</a></li>
      <li><a href="https://vue-loader.vuejs.org" target="_blank" rel="noopener">vue-loader</a></li>
      <li><a href="https://github.com/vuejs/awesome-vue" target="_blank" rel="noopener">awesome-vue</a></li>
    </ul>
  </div>
</template>

<script>
import dayjs from "dayjs"
import axios from "axios"
import Modal from "@/components/Modal.vue"

export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  components: { Modal },
  data () {
        return {
           date: '',
           bodytext: 'Start',
           isModalVisible: false,
           userData: {
              text: "opensrs-agreement.html"
           }
        }
  },
  mounted() {
     this.date = dayjs().format('MMM D, YYYY h:mm:ss A');
     this.bodytext = this.getIpsem();
     console.log("Ipsem: " + this.bodytext);
     console.log ("Date: " + this.date);
  },
  methods: {
     closeModal () { this.isModalVisible = false; },
     showModal ()  { this.isModalVisible = true; },
     getIpsem () {
        var url = "/api/ipsem";
        var config = { };
        axios.get(url, config )
         .then (
                 response => {
                       this.bodytext = response.data.data.result;
                       console.log("Ipsem: " + this.bodytext);
                 })
         .catch(error => {
                 var notes = "";
         })
     }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
