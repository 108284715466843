<template>
   <table :class="tab_class">
       <tbody>
          <tr>
              <th>Account</th> 
              <td> {{ info.logname }}/{{ info.uid }} </td>
          </tr>
          <tr>
              <th>Last Invoice</th> 
              <td> {{ info.last_invoice }} </td>
          </tr>
          <tr>
              <th>Due at last invoice</th> 
              <td>${{ info.amount }}</td>
          </tr>
          <tr>
              <th>Num. payments made since last invoice</th>
              <td>{{ info.num_paymts }}</td>
          </tr>
          <tr>
              <th>Pending payments total </th>
              <td>${{ info.paymt_total }} </td>
          </tr>
          <tr>
              <th>Currently Due</th>
              <td>${{ info.current_due }} </td>
          </tr>
       </tbody>
   </table>

</template>

<script>

   export default {
      name: 'PaymentStatus',
      props: {
         info: Object,
      },
      data () {
        return {
           errors: []
        }
      },
      computed: {
           user () { return this.$store.state.user; },
           current_user () { return this.$store.state.current_user; },
           staff () { return this.$store.state.staff; },
           jwt () { return this.$store.state.jwt; },
           mode() { return this.$store.state.mode; },
           tab_class () {
            return "table table-hover swcp-comp table-" + this.mode;
           }  
      }            
    }

</script>

<style>
::placeholder { 
  color: #a2bbd3 !important;
}
.swcp-comp {
   margin-left: 10%;
   width: 80%;
}
.swcp-comp td, .swcp-comp th {
   padding: .25em;
   width: 25%;
}
</style>
