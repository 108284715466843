<template>
  <div>
     <h2 class="dbhead">Set up Auto-Responder Message</h2>

   <div class="row">
     <div class="col-sm-12">
         <form class="form-group my-2 my-lg-0" @submit.prevent="sendMsg">
           <div class="row breathe">
             <div class="col-sm-6">
               <h4>Start Date & Time</h4>
               <ErrorMessage :error="problems.startDate" />
               <VueDatePicker v-model="startDate"
                           time-picker-inline
                           placeholder="Select Start Date"
                           :alt-position="customPosition"
                           :monthChangeOnScroll="false"
                           :is24="false"
               />

             </div>
             <div class="col-sm-6">
               <h4>End Date & Time</h4>
               <ErrorMessage :error="problems.endDate" />
               <VueDatePicker v-model="endDate"
                           time-picker-inline
                           placeholder="Select End Date"
                           :alt-position="customPosition"
                           :monthChangeOnScroll="false"
                           :is24="false"
               />
             </div>
           </div>

           <div class="row breathe">
            <label><b>Vacation Message</b></label>
            <ErrorMessage :error="problems.vacationMessage" />
            <input as="textarea" class="form-control" rows="7" name="vacationMessage" v-model="vacationMessage" /><br>
          </div>
          <div class="row breathe">
            <label><b>Special Instructions?</b></label>
            <textarea class="form-control" rows="3" v-model="instructions"></textarea><br>

           </div>
           <div class="row form-group">
            <label class="col-sm-2"><b>Reply To</b></label> 
            <ErrorMessage :error="problems.reply_to" />
            <input class="col-sm-10" type="text" name="reply_to"
                      v-model="userData.reply_to"
                      aria-label="Reply To" /><br>
           </div>
            <div class="tap-right">
              <button class="btn btn-outline-primary my-2 my-sm-0"
                  type="submit">Request Auto Responder</button>
            </div>
         </form>
     </div>
   </div>
 </div>
</template>

<script>
   const fail_title = "Sorry, something has failed";
   const fail_body = "The request was not sent<br>" +
                     "If you need to follow up you can reach us at (505) 232-7992 or help@swcp.com.<br>"; 
   const success_title = "Thanks!";
   const success_body = "Your request has been sent to the SWCP help desk. " +
                        "If you need to follow up you can reach us at (505) 232-7992";

   import axios from "axios";
   import { useVuelidate } from '@vuelidate/core';
   import { required, email, helpers } from '@vuelidate/validators';
   import ErrorMessage from '@/components/ErrorMessage.vue';
   import VueDatePicker from '@vuepic/vue-datepicker';
   import '@vuepic/vue-datepicker/dist/main.css';
   import { ref, onMounted } from 'vue';

   export default {
      name: 'AutoResponder',
      components: { ErrorMessage, VueDatePicker },
      emits: [ 'message' ],
      data () {
        return {
           v$: useVuelidate(),
           startDate: null,
           endDate: null,
           instructions: '',
           vacationMessage: '',
           userData: {
               send_to: 'help@swcp.com',
               send_from: '',
               subject: 'Request Auto Responder',
               message: '',
               reply_to: this.customer_email,
               footer: ''
           },
           problems: {
               endDate: '',
               startDate: '',
               reply_to: '',
               vacationMessage: ''
           },
           fields: [ 'endDate', 'startDate', 'reply_to', 'vacationMessage' ],
           is_visible: false,
           response_status: ''
        }
      },
      validations: {
         userData: {
             reply_to: { required, email }
         },
         endDate: { required },
         startDate: { required },
         vacationMessage: { required }
      },
      methods: {
         customPosition () {
             return { top: 0, left: 0 };
         },
         buildMessage () {
            this.userData.message = this.current_user + " requests an auto responder be set up.\n";
            this.userData.message += "Start date: " + this.startDate + "\n";
            this.userData.message += "End date:   " + this.endDate + "\n";
            this.userData.message += "---------------------------------\n";
            this.userData.message += "Special Instructions:\n";
            this.userData.message += this.instructions + "\n";
            this.userData.message += "---------------------------------\n";
            this.userData.message += "Vacation Message:\n";
            this.userData.message += this.vacationMessage + "\n";
         },
         responseMessage(title, body, type, alert, footer, menu) {
                var args = { title: title, body: body,
                             type: type, alert: alert,
                             footer: footer, menu: menu };
                this.$store.commit('setStatus', args);
                this.$emit('message');
                return;
         },
         clearForm() {
            this.instructions = '';
            this.vacationMessage = '';
            this.userData.message = '';
         },
         clearProblems() {
            this.fields.forEach ((item) => {
               this.problems[item] = '';
            });       
         },           
         async sendMsg() {
            var url = "/api/sendmessage"; 
            var rep = this.customer_email;
            var property, message;
            var config = { headers:
                            { Authorization: "Bearer " + this.jwt }
                         };
            if (this.userData.reply_to != "") { 
                rep = this.userData.reply_to;
            }
            const result = await this.v$.$validate();
            if (! result) {
                  this.clearProblems();
                  this.v$.$errors.forEach (err => {
                      property = err.$property;
                      message = err.$message;
                      this.problems[property] = message;
                  });
            } else {

                if (this.startDate == null || this.endDate == null) {
                    this.responseMessage("Dates not set", "Please set a start and end date", 'modal', 'warning', '', []);
                } else {
                    this.buildMessage();
                    console.log(this.vacationMessage);
                    this.userData.send_from = rep;
                    this.userData.footer = this.footer_tag; 
                    axios.post(url, this.userData, config )
                    .then (
                           response => {
                              this.response_status = response.data.status;
                              this.responseMessage(success_title, success_body, 'thankyou', 'info', '', []);
                           })
                    .catch(error => {
                              if (error.response) {
                                 if (error.response.data.status && error.response.data.status == "expired_token") {
                                     this.$store.dispatch('expired');
                                 }
                              }
                              this.response_status = error;
                              this.responseMessage(fail_title, fail_body + error, 'modal', 'serious', '', []);
                           })
                  }
             }
          }
      },
      mounted () {
           this.userData.reply_to = this.customer_email;
      },
      watch: {
          current_user: function (newUser, oldUser) {
             this.userData.reply_to = this.customer_email;
          }
      },
      computed: {
          footer_tag () {
            if (this.operator === this.current_user) {
                       return "Message sent by " + this.operator;
            } else {
                       return "Message sent by " + this.operator + " for " + this.current_user;
            }
          },
          operator ()       { return this.$store.state.operator; },
          current_user ()   { return this.$store.state.current_user; },
          jwt ()            { return this.$store.state.jwt; },
          customer_email () { return this.$store.state.reply_to; }
      }
   }
</script>

<style>
.breathe {
   padding-bottom: 20px;
}
</style>
