<template>
    <h2 class='dbhead'>Unimplemented</h2>
</template>

<script>
export default {

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
