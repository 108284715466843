<template>
  <div>
     <h2 class="dbhead">Change Members Panel Password</h2>

   <div class="row">
     <div class="col-sm-12">
       This changes the password for logging into this dashboard. It does NOT change
     your email password. Thanks!
     </div>
   </div>
   <div class="row">
     <div class="col-sm-4">
       <br>
       <p class="center-block">
          <img src="@/assets/img/panel-password-small.png" alt="Envelope and Lock">
       </p>
     </div>
     <div class="col-sm-8">
         <br>
         <form class="form-group" @submit.prevent="chgPwd">
            <ErrorMessage :error="problems.old_password" /><br>
            <input class="form-control" :type="type" v-model="userData.old_password"
                      autocomplete="old_password"
                      name="old_password" placeholder="Old Password" aria-label="Old Password" /><br>
            <ErrorMessage :error="problems.new_password" /><br>
            <input class="form-control" :type="type" v-model="userData.new_password"
                      autocomplete="new_password"
                      name="new_password" placeholder="New Password" aria-label="New Password" /><br>
            <ErrorMessage :error="problems.conf_password" /><br>
            <input class="form-control" :type="type" v-model="userData.conf_password"
                      autocomplete="conf_password"
                      name="conf_password" placeholder="Confirm Password" aria-label="Confirm Password" /><br>
            <button class="btn btn-outline-info"
                  type="submit">Change Password</button>
            <button class="btn btn-outline-info" type="button" v-on:click="togglePassword"> <i class='fa fa-eye'></i> {{ showHide }} </button>
         </form>
     </div>
   </div>
   <div class="row">
      <div class="text-info col-md-12">
         {{ status }}<br>
         {{ idinfo }}
      </div>
   </div>
  </div>
</template>

<script>

   import axios from "axios";
   import { useVuelidate } from '@vuelidate/core'
   import { required, minLength, sameAs, helpers } from '@vuelidate/validators';
   import ErrorMessage from '@/components/ErrorMessage.vue'

   export default {
      name: 'ChangePanelPassword',
      emits: [ 'message' ],
      components: { ErrorMessage },
      setup: () => ({ v$: useVuelidate() }),
      data () {
        return {
           userData: {
               username: '',
               old_password: '',
               new_password: '',
               conf_password: ''
           },
           problems: {
               old_password: '',
               new_password: '',
               conf_password: ''
           },
           fields: [ 'old_password', 'new_password', 'conf_password' ],
           showHide: "Show Passwords",
           type: "password",
           idinfo: '',
           response_title: '',
           response_body: '',
           menu: [],
           status: ''
        }
      },
      validations () {
         return {
             userData: {
                 old_password: { required },
                 new_password: { required, minLength: minLength(8) },
                 conf_password: { sameAs: helpers.withMessage('Passwords must match', sameAs(this.userData.new_password))}
             }
         }
      },
      methods: {
         responseMessage(title, body, type, alert, footer, menu) {
           var args = { title: title, body: body,
                        type: type, alert: alert,
                        footer: footer, menu: menu };
           this.$store.commit('setStatus', args);
           this.$emit('message');
           return;
         },
         togglePassword() {
              if (this.type === "password") {
                  this.type = "text";
                  this.showHide = "Hide Passwords";
              } else {
                  this.type = "password";
                  this.showHide = "Show Passwords";
              }
         },
         clearForm() {
            this.userData.username = '';
            this.userData.old_password = '';
            this.userData.new_password = '';
            this.userData.conf_password = '';
         },
         clearProblems() {
            this.fields.forEach ((item) => {
               this.problems[item] = '';
            });
          },
         async chgPwd(values) {
            var msg, message, property;
            this.userData.username = this.current_user;
            const result = await this.v$.$validate();
            msg = '';
            this.clearProblems();
            if (! result) {
                 msg = "Validation problems found:";
                  this.v$.$errors.forEach (err => {
                      property = err.$property;
                      message = err.$message;
                      this.problems[property] = message;
                      if (message != "") {
                          msg += " " + property + "=" + message + "\n";
                      }
                  });
                  console.log(msg);
            } else {
                this.status = "This password is for the members panel only";
                this.idinfo = "Username: " + this.userData.username;
                var url = "/api/user-change-password"; 
                var config = { headers:
                                { Authorization: "Bearer " + this.jwt }
                             };
                axios.post(url, this.userData, config )
                .then (
                       response => {
                          if (response.data.message == "") {
                              this.response_title = "Password Changed";
                              this.response_body = `Your password has been changed. This effects the login for this dashboard only. 
                                                    It does NOT effect your email password.`;
                              this.responseMessage(this.response_title, this.response_body, 'thankyou', 'info', '', []);
                          } else {
                              this.response_title = "Problems";
                              this.response_body = response.data.message;
                              this.responseMessage(this.response_title, this.response_body, 'modal', 'serious', '', []);
                          }
                       })
                .catch(error => {
                          if (error.response) {
                             if (error.response.data.status && error.response.data.status == "expired_token") {
                                 this.$store.dispatch('expired');
                             }
                             this.response_title = error.response.data.status;
                             this.response_body = error.response.data.message;
                             this.responseMessage(this.response_title, this.response_body, 'modal', 'serious', '', []);
                          }
                          console.log(error);
                  })
              }
          }
      },
      computed: {
          current_user ()   { return this.$store.state.current_user; },
          jwt ()            { return this.$store.state.jwt; },
          customer_email () { return this.$store.state.reply_to; }
      }
   }
</script>

<style>
.rsam-info.card-body {
   background-color: #ffffee;
   margin-bottom: 15px;
}
.sam-info.card-body {
   background-color: #def6c5;
}
</style>
