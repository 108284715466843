<template>
  <div>
   <div class="row top-row">
     <h2 class="col-12">Install a Web App </h2>

     <div v-if="this.pending.length" class="col-12">
          <h3>Pending Installations</h3>
          <div class="alert alert-info" v-for="item in pending" :key="item"> {{item.appname}} in {{item.appurl}} [ {{item.state}} ] </div>
     </div>
     <form  v-if="this.domains.length" class="col-sm-12" v-on:submit.prevent="installApp" ref="form">
        <div class="row justify-content-md-center">
            <ErrorMessage :error="problems.webappid" /><br>
        </div>
        <div class="row">
            <label class="mb-sm-3 col-sm-3" for="webappid">Select a web app to install</label>
            <select class="selcss mb-sm-3 col-sm-9" id='webappid' name='webappid' v-model='userData.webappid' as="select"
                    v-on:change="selApp" >

               <option value=-1 disabled selected>{{websel}}</option>
               <option v-for="(webapp, index) in webapps"
                       v-bind:key="index"
                       v-bind:value="index"> {{ webapp.appname }} </option>
            </select>
        </div>
        <div class="row justify-content-md-center">
            <ErrorMessage :error="problems.vid" /><br>
        </div>
        <div class="form-group row">
            <label for='vid' class="col-sm-3">Where shall we install?<br><i>Domain/subdirectory</i></label>
            <select id='vid' name="vid" class="selcss col-sm-5" v-model='userData.vid' as="select" >
               <option value="" disabled selected>{{ domsel }}</option>
               <option v-for="(dom, vid) in domains"
                       v-bind:key="vid"
                       v-bind:value="dom.vid"> {{ simple_url(dom) }} </option>
            </select>
            <input name="subdir" id="subdir" class="selcss col-sm-4" v-model="userData.subdir"
                    type="text" />
        </div>
        <div class="row justify-content-md-center">
            <ErrorMessage :error="problems.email" /><br>
        </div>
        <div class="form-group row">
            <label class="col-sm-3" for="email">Email</label>
            <input name="email" type="text" class="form-control col-sm-8" v-model="userData.email"
                          id="email" placeholder="Email Address" aria-label="Email Address" />
        </div>
        <div class="form-group row">
             <button class="offset-sm-3 col-md-4 btn btn-outline-primary"
                     type="submit"><i class="fa fa-lg fa-fw fa-rocket"></i>&nbsp;&nbsp; Install</button>

        </div>

     </form>
     <div v-else>
       <p class="alert alert-primary offset-sm-2 col-sm-8">You don't seem to have any web hosting set up with us.
       Please contact help@swcp.com or call (505) 232-7992 to set
       up web hosting. Thanks!</p>
     </div>

   </div>
  </div>

</template>

<script>

import axios from "axios";
import { useVuelidate } from '@vuelidate/core'
import { required, numeric, email, helpers } from '@vuelidate/validators';
import { not_negative } from '@/helpers/utils.js';
import ErrorMessage from '@/components/ErrorMessage.vue';

export default {
      name: 'WebAppInstall',
      components: { ErrorMessage },
      emits: [ 'message' ],
      setup () {
         return { v$: useVuelidate() }
      },
      data () {
        return {
           webapps: [ ],
           result: [ ],
           pending: [ ],
           websel: 'Select a Web App',
           domains: [ 
                       {
                         "vid": "0",
                         "webserver": "webfarm",
                         "virtname": "unknown",
                         "addr": "216.184.2.32",
                         "docroot": "/var/www/htdocs",
                         "suphp": "1",
                         "ssl": "0"
                        }
                     ],
           domsel: 'Select a domain',
           userData: {
               webappid: -1,
               appname: '',
               vid: '',
               subdir: '',
               email: '',
               nsuphp: 0,
               wfv: ''
           },
           problems: {
               webappid: '',
               vid: '',
               email: ''
           },
           fields: [ 'webappid', 'vid', 'email' ],
         }
      },
      validations () {
        return {
           userData: {
               vid: { required: helpers.withMessage('Select a website', required) },
               webappid: { not_negative: helpers.withMessage('Select an App', not_negative) },
               email: { email }
           }
        }
      },
      mounted() {
         this.checkPending();
         this.setupApps();
      },
      watch: {
          current_user: function (newUser, oldUser) {
            console.log("watch called for " + newUser);
            this.checkPending();
            this.setupApps();
          }
      },
      methods: {
         clearProbs() {
            this.fields.forEach ((item) => {
               this.problems[item] = '';
            });                  
         },             
         responseMessage(title, body, type, alert, footer, menu) {
                var args = { title: title, body: body,
                             type: type, alert: alert,
                             footer: footer, menu: menu };
                this.$store.commit('setStatus', args);
                this.$emit('message');
                return;
         },
         simple_url(dom) {
             return dom.virtname;
         },
         actual_url(dom) {
             var proto = "http://";
             if (dom.ssl == 1) { proto = "https://"; }
             return proto + dom.virtname;
         },
         selApp(event) {
             var index = event.target.value;
             this.userData.appname = this.webapps[index].appname;
             this.userData.subdir = this.webapps[index].urlext;
             this.userData.nsuphp = this.webapps[index].needs_suphp;
             this.userData.wfv = this.webapps[index].wf_versions;
         },
         setupApps() {
            this.getAppList();
            this.getVirtList();
            this.userData.email = this.customer_email;
         },
         clearForm() {
            this.userData.webappid = -1;
            this.userData.appname = '';
            this.userData.vid = '';
            this.userData.subdir = '';
            this.userData.email = '';
            this.userData.nsuphp = 0;
            this.userData.wfv = '';
         },
         checkPending () {
            var config = { headers:
                                { Authorization: "Bearer " + this.jwt }
                         };
            this.account = this.current_user;
            axios.get("/api/pending-installations/" + this.account , config )
            .then (
                    response => {
                          this.pending = response.data.data.installations;
                    })
            .catch(error => {
                    var notes = "";
                    if (error.response) {
                       if (error.response.data.message) {
                           notes = error.response.data.message;
                       }
                       if (error.response.data.status && error.response.data.status == "expired_token") {
                           this.$store.dispatch('expired');
                       }
                    }
                    })
         },

         getAppList () {
            var config = { headers:
                                { Authorization: "Bearer " + this.jwt }
                         };
            this.account = this.current_user;
            axios.get("/api/applist" , config )
            .then (
                    response => {
                          this.webapps = response.data.data;
                    })
            .catch(error => {
                    var notes = "";
                    if (error.response) {
                       if (error.response.data.message) {
                           notes = error.response.data.message;
                       }
                       if (error.response.data.status && error.response.data.status == "expired_token") {
                           this.$store.dispatch('expired');
                       }
                    }
                    this.webapps = [ { 'webapp': 'unknown',
                                       'urlext': "",
                                       'aid': 0,
                                       'version': '1.0'  } ];
                    })
         },
         getVirtList () {
            var config = { headers:
                                { Authorization: "Bearer " + this.jwt }
                         };
            this.account = this.current_user;
            axios.get("/api/compressed-websites/" + this.account , config )
            .then (
                    response => {
                          this.domains = response.data.data;
                    })
            .catch(error => {
                    var notes = "";
                    if (error.response) {
                       if (error.response.data.message) {
                           notes = error.response.data.message;
                       }
                       if (error.response.data.status && error.response.data.status == "expired_token") {
                           this.$store.dispatch('expired');
                       }
                    }
                    this.domains = [ ];
                    })
         },
         async installApp () {
            var config = { headers:
                                { Authorization: "Bearer " + this.jwt }
                         };
            const result = await this.v$.$validate();
            var message, property, email;
            if (! result) {
                this.clearProbs(); // Set all problems to ''
                this.v$.$errors.forEach (err => {
                      property = err.$property;
                      message = err.$message;
                      this.problems[property] = message;
                });
            } else {
                this.account = this.current_user;
            
                axios.post("/api/installapp", this.userData, config )
                .then (
                        response => {
                              if (response.data.status) {
                                  this.responseMessage("Installing ...", this.userData.appname + " in " + response.data.data.url,
                                                       'thankyou', 'info', '', []);
                              } else {
                                  this.responseMessage("Failed ...", response.data.message, 'modal', 'serious', '', []);
                              }
                        })
                .catch(error => {
                        var notes = "";
                        if (error.response) {
                           console.log(error);
                           if (error.response.data.message) {
                               notes = error.response.data.message;
                               this.responseMessage("Failed", notes, 'modal', 'serious', '', []);
                           }
                           if (error.response.data.status && error.response.data.status == "expired_token") {
                               this.$store.dispatch('expired');
                           }
                        }
                        this.result = [ ];
                        })
             }
          }
      },
      computed: {
          operator ()       { return this.$store.state.operator; },
          current_user ()   { return this.$store.state.current_user; },
          jwt ()            { return this.$store.state.jwt; },
          customer_email () { return this.$store.state.reply_to; }
      }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped> 
.selcss {
   padding: 10px;
   border: 1px solid dodgerblue;
   border-radius: 5px;
}
.table td, .table th {
    padding: .5rem .75rem;
}
.table th {
    color: #333333;
}
.top-row h1, h2, h3, h4, h5, h6 {
    margin-top: .5em;
}

</style>
