<template>
  <section>
   <div class="col-12">
    <h2 class='dbhead'>Edit Account Contact for <span class="swcp-em">{{current_user}}</span></h2>
   </div>
  </section>
  <section class="section_box">
   <div class="col-12">
       <div class="row">
         <div class="col-4">
           <table :class="tab_class">
             <tr><td><h4>Contact Name</h4>
                     <input type='text' name='contact' v-model="updateData.contact" />
                     <input type='hidden' name='tcid' v-model="updateData.tcid" />
                  </td></tr>
             <tr><td>Has restrictions?</td><td><input name="restricted" type=checkbox true-value="1" false-value="0" v-model=updateData.restricted  /></td></tr>
             <tr><td>Technical Contact?</td><td><input name="technical" type=checkbox true-value="1" false-value="0" v-model=updateData.technical  /></td></tr>
             <tr><td>Billing Contact?</td><td><input name="billing" type=checkbox true-value="1" false-value="0" v-model=updateData.billing  /></td></tr>
           </table>
         </div>
         <div class="offset-1 col-6">
           <section class="section_box">
           <h4>Contact Info</h4>
             <textarea required name='info' v-model='updateData.info' class="form-control" rows=8></textarea>
           </section>
         </div>
       </div>
       <section class="section_box">
          <div class="col-12">
             <h4>Restrictions</h4>
             <textarea required name='restriction' v-model='updateData.restriction' class="form-control" rows=8></textarea>
          </div>
       </section>
          <div class="col-12 text-center">
             <button class="btn btn-info" v-on:click="updateTechContact">Update</button>&nbsp;&nbsp;
             <button class="btn btn-info" v-on:click="contactList">Contact List</button>
         </div>
 
   </div>
 </section>

</template>

<script>
import axios from "axios";
export default {
    name: 'EditTC',
    emits: [ 'message' ],
    mounted () {
        this.tcid = this.$route.params.tcid;
        this.setUpdateForm (this.tcid);
    },
    watch: {
          current_user: function (newUser, oldUser) {
            console.log("Watch called for " + newUser);
            this.$router.push({ name: 'contacts' });
          }
    },
    methods: {
       setUpdateForm (tcid) {
           var url = "/api/get-contact";
           var config = { headers:
                            { Authorization: "Bearer " + this.jwt }
                         };
           this.getTCData.logname = this.current_user;
           this.getTCData.tcid = tcid;
           this.updateData.tcid = tcid;
           axios.post(url, this.getTCData, config )
               .then (
                  response => {
                      if (response.data.status == 0) {
                          this.responseMessage("Can't find " + tcid,
                               response.data.message, 'modal', 'warning', '', []);
                      } else {
                          var contact = response.data.data.contact;
                          this.updateData.contact = contact.contact;
                          this.updateData.info = contact.contact_info;
                          this.updateData.restricted = contact.restricted;
                          this.updateData.restriction = contact.restriction;
                          this.updateData.technical = contact.technical;
                          this.updateData.billing = contact.billing;
                      }
                  })
               .catch(error => {
                  if (error.response.data.status && error.response.data.status == "expired_token") {
                      this.$store.dispatch('expired');
                  } else {
                      this.responseMessage('Get Contact Failed ' + tcid,
                           error, 'modal', 'serious', '', []);
                  }
               })
       },
       responseMessage(title, body, type, alert, footer, menu) {
              var args = { title: title, body: body,
                           type: type, alert: alert,
                           footer: footer, menu: menu };
              this.$store.commit('setStatus', args);
              console.log("editTC: responseMessage");
              console.log(args);
              this.$emit('message');
              return;
       },
       updateTechContact() {
           var url = "/api/update-contact";
           var config = { headers:
                            { Authorization: "Bearer " + this.jwt }
                         };
           this.updateData.logname = this.current_user;
           axios.post(url, this.updateData, config )
               .then (
                  response => {
                      if (response.data.status == 0) {
                          this.responseMessage('Edit Contact Failed',
                               response.data.message, 'modal', 'warning', '', []);
                      } else {
                         this.$router.push({ name: 'contacts' });
                      }
                  })
               .catch(error => {
                  if (error.response.data.status && error.response.data.status == "expired_token") {
                      this.$store.dispatch('expired');
                  } else {
                      this.responseMessage('Edit Contact Failed',
                           error, 'modal', 'serious', '', []);
                  }
               })
       },
       contactList () {
            this.$router.push({ name: 'contacts' });
       }
    },
    computed: {
          currentRouteName() { return this.$route.name; },
          operator ()        { return this.$store.state.operator; },
          current_user ()    { return this.$store.state.current_user; },
          jwt ()             { return this.$store.state.jwt; },
          mode ()            { return this.$store.state.mode; },
          tab_class () {
            return "table table-borderless table-" + this.mode;
          }

   },
   data () {
      return {
        tcid: 0,
        getTCData: {
            logname: '',
            tcid: 0
        },
        updateData: {
            logname: '',
            tcid: 0,
            contact: '',
            info: '',
            restricted: '0',
            technical: '0',
            billing: '0',
            restriction: ''
        }
      }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

section {
   margin-top: 20px;
   margin-bottom: 20px;
}
.section_box {
   padding: 20px;
   border: 1px #dcdcdc solid;
   border-radius: 20px;
   box-shadow: 5px 5px 7px rgb(0 100 200 / 0.25);
}
.email-select {
   padding: 10px;
   border: 1px solid dodgerblue;
   border-radius: 5px;
}
.maxial {
  width: 100%;
}
.padme {
   padding-left: 5%;
}
</style>
