<template>
    <div class="col-sm-12">
        <div class="row">
        </div>
        <div class="row justify-content-md-center">
             <div class="offset-sm-3 col-sm-4">
                  <ErrorMessage :error="problems.first_name" />
             </div>
             <div class="col-sm-4">
                  <ErrorMessage :error="problems.last_name" />
             </div>
       </div>
       <div class="form-group row">
                 <label class="col-sm-3">{{name_tag}}</label>
                 <input class="form-control col-sm-4" type="text" 
                        name="first_name" placeholder="First Name" aria-label="First Name" 
                        :value="first_name"
                        @input="$emit('update:first_name', $event.target.value)" />
                 <input class="form-control col-sm-4" type="text" 
                        name="last_name" placeholder="Last Name" aria-label="Last Name" 
                        :value="last_name"
                        @input="$emit('update:last_name', $event.target.value)" />
       </div>
       <div class="form-group row">
                 <label class="col-sm-3">Company</label>
                 <input class="form-control col-sm-8" type="text" 
                        name="company" placeholder="Company" aria-label="Company" 
                        :value="company"
                        @input="$emit('update:company', $event.target.value)" />
       </div>
        <div class="row justify-content-md-center">
             <div class="offset-sm-3 col-sm-4">
                  <ErrorMessage :error="problems.address" />
             </div>
       </div>
       <div class="form-group row">
                 <label class="col-sm-3">Address</label>
                 <input class="form-control col-sm-8" type="text" 
                        name="address" placeholder="Address" aria-label="Address" 
                        :value="address"
                        @input="$emit('update:address', $event.target.value)" />
       </div>
        <div class="row justify-content-md-left">
             <div class="offset-sm-3 col-sm-4">
                  <ErrorMessage :error="problems.city" />
             </div>
             <div class="col-sm-1">
                  <ErrorMessage :error="problems.state" />
             </div>
             <div class="col-sm-2">
                  <ErrorMessage :error="problems.zipcode" />
             </div>
             <div class="col-sm-1">
                  <ErrorMessage :error="problems.country" />
             </div>
       </div>
       <div class="form-group row">
                 <label class="col-sm-3">&nbsp;</label>
                 <input class="form-control col-sm-4" type="text"
                        @input="$emit('update:city', $event.target.value)"
                        :value="city"
                        name="city" placeholder="City" aria-label="City" />
                 <input class="form-control col-sm-1" type="text"
                        @input="$emit('update:state', $event.target.value)"
                        :value="state"
                        name="state" placeholder="State" aria-label="State" />
                 <input class="form-control col-sm-2" type="text" 
                        @input="$emit('update:zipcode', $event.target.value)"
                        :value="zipcode"
                        name="zipcode" placeholder="Zipcode" aria-label="Zipcode" />
                 <input class="form-control col-sm-1" type="text"
                        @input="$emit('update:country', $event.target.value)"
                        :value="country"
                        name="country" placeholder="Country" aria-label="Country" />
       </div>
    </div>
</template>

<script>
   import ErrorMessage from '@/components/ErrorMessage.vue';
   import { useVuelidate } from '@vuelidate/core';
   import { required, numeric, helpers } from '@vuelidate/validators';

   export default {
      name: 'Address',
      emits: [ 'update:first_name', 'update:last_name', 'update:address', 'update:city', 
               'update:state', 'update:zipcode', 'update:country', 'update:company'
              ],
      props: {
        name_tag: String,
        hard: String,
        first_name: String,
        last_name: String,
        company: String,
        address: String,
        city: String,
        state: String,
        zipcode: String,
        country: String,
        problems: Object
      },
      components: { ErrorMessage },

      data () {
        return {
           errors: []
        }
      }
   }

</script>

<style>
::placeholder { 
  color: #a2bbd3 !important;
}
</style>
