<template>
<div>
    <div class="row">
      <div class="col-sm-12">
          <h2> Edit {{ info.virtname }} </h2>
          <div v-if="pending_info.pending == true" class="row alert alert-danger"> 
            There is a change pending for this website. Please wait until it's been processed.
            Last update: {{ pending_info.last_genconf }}
          </div>

          <div class="row fat-row">
              <div class="col-3"> Virtual Name </div>
              <div class="col-6"> {{ info.virtname }} </div>
          </div>
          <div class="row fat-row">
              <div class="col-3"> VID </div>
              <div class="col-6"> {{ info.vid }} </div>
          </div>
          <div class="row fat-row">
              <div class="col-3"> Document Root </div>
              <div class="col-6"> {{ info.docroot }} </div>
          </div>
          <div class="row fat-row">
              <div class="col-3"> SSL </div>
              <div class="col-6"> {{ readable_ssl(info.ssl) }} </div>
          </div>
          <div class="row fat-row">
              <div class="col-3"> Current PHP Version </div>
              <div class="col-6"> {{ info.php_version }} </div>
              <input type="hidden" v-model="info.virtname">
          </div>
          <div class="row fat-row">
             <div class="col-3"> Change PHP Version to </div>
             <div class="col-3">
                 <select class="form-control" v-on:change="onChange" v-model="wsData.php_version">
                    <option value="" selected>Select a PHP version</option>
                    <option v-for="(ver, index) in versions"
                            v-bind:key="index"
                            v-bind:value="ver"> PHP {{ ver }} </option>
                 </select>
            </div>
            <div class="col-6"></div>
          </div>
          <div v-if="warning != ''" class="row alert alert-danger"> {{ warning }} </div>

          <div class="form-group row justify-content-center fat-row">
               <button v-on:click="updateWebsite" type="button" class="spaced btn btn-primary justify-content-center">Update</button>
               <GoBack title="Cancel"></GoBack>
          </div>
       </div>
    </div>
 </div>
</template>

<script>
   import axios from "axios";
   import GoBack from "@/components/GoBack";
   export default {
      name: 'EditWebsite',
      components: { GoBack },
      emits: [ 'message' ],
      data () {
        return {
           vid: 0,
           errors: [],
           status: false,
           info: [],
           pending_info: [],
           virt_info: [],
           versions: [],
           warning: "",
           wsData: {
               logname: '',
               virtname: '',
               php_version: '',
               vid: 0
           },
        }
      },
      methods: {
       onChange () {
          if (this.info.php_version == "Disabled") { return; }
          if (this.wsData.php_version < this.info.php_version) {
             this.warning = "You are downgrading your PHP version. Please consider this carefully as older versions of PHP are slower and more prone to security issues.";
          } else if (this.wsData.php_version == this.info.php_version) {
             this.warning = "This is the same version of PHP that you are using currently, no change will be made";
             console.log("Warning:" + this.warning);
          } else {
             this.warning = "";
          }
          if (this.wsData.php_version == "Disabled") {
             this.warning = "This will disable PHP for your website";
          }
       },

       updateWebsite () {
          if (this.pending_info.pending) {
              this.responseMessage('Change is already pending', 'please check back later', 'modal', 'serious', '', []);
          } else if (this.wsData.php_version == "")  {
              this.responseMessage('Select a new PHP version', 'Please choose an option', 'modal', 'serious', '', []);
          } else {
           var config = { headers:
                               { Authorization: "Bearer " + this.jwt }
                        };
           axios.post("/api/update-php-version", this.wsData, config )
           .then (
                   response => {
                         this.pending_info = response.data.data;
                         if (response.data.status == 1) {
                            this.responseMessage("Update queued", 'Change will complete in a few minutes', 'thankyou', 'info', '', []);
                         } else {
                            this.responseMessage('Error queuing PHP change', response.data.message, 'modal', 'serious', '', []);
                         }
                   })
           .catch(error => {
                   if (error.response) {
                      if (error.response.data.message) {
                          this.responseMessage('Error queuing PHP change', error.response.data.message, 'modal', 'serious', '', []);
                      }
                      if (error.response.data.status && error.response.data.status == "expired_token") {
                          this.$store.dispatch('expired');
                      }
                   }
           })
          }
       },

       readable_ssl (value) {
                 if (value == 0) { return "No"; }
                 if (value == 1) { return "SSL-only"; }
                 else { return "Yes"; } 
       },      

       responseMessage(title, body, type, alert, footer, menu) {
           var args = { title: title, body: body,
                        type: type, alert: alert,
                        footer: footer, menu: menu };
           this.$store.commit('setStatus', args);
           this.$emit('message');
           return;
       },

       buildInfo() {
           var config = { headers:
                               { Authorization: "Bearer " + this.jwt }
                        };
           this.info.vid = this.$route.params.vid;
           axios.get("/api/virt-details-protected/" + this.current_user + "/" + this.info.vid, config )
           .then (
                   response => {
                      if (response.data.status == 1) {
                         this.virt_info = response.data.data;
                         this.info.virtname = this.virt_info.virtname;
                         this.info.ssl = this.virt_info.ssl;
                         this.info.docroot = this.virt_info.docroot;
                         this.info.php_version = this.virt_info.php_version;
                         this.wsData.virtname = this.virt_info.virtname;
                         this.wsData.vid = this.virt_info.vid;
                         this.wsData.logname = this.current_user;
                      } else {
                         this.responseMessage('Permissions problem, contact noc@swcp.com', response.data.message, 'modal', 'serious', '', []);
                      }
                   })
       },

       curr_ver(ver)      { 
           if (ver == this.info.php_version) { 
              return 1; 
           } else { 
              return 0; 
           } 
       },

       getPendingStatus () {
           var config = { headers:
                               { Authorization: "Bearer " + this.jwt }
                        };
           axios.post("/api/pending-virt-change", this.wsData, config )
           .then (
                   response => {
                         this.pending_info = response.data.data;
                   })
           .catch(error => {
                   if (error.response) {
                      if (error.response.data.message) {
                          this.responseMessage('Error getting php version list', error.response.data.message, 'modal', 'serious', '', []);
                      }
                      if (error.response.data.status && error.response.data.status == "expired_token") {
                          this.$store.dispatch('expired');
                      }
                   }
           })
       },

       getPhpVersions () {
           var config = { headers:
                               { Authorization: "Bearer " + this.jwt }
                        };
           axios.get("/api/php-versions", config )
           .then (
                   response => {
                         this.versions = response.data.data;
                   })
           .catch(error => {
                   if (error.response) {
                      if (error.response.data.message) {
                          this.responseMessage('Error getting php version list', error.response.data.message, 'modal', 'serious', '', []);
                      }
                      if (error.response.data.status && error.response.data.status == "expired_token") {
                          this.$store.dispatch('expired');
                      }
                   }
           })
        }
      },
      mounted()  {
         this.vid = this.$route.params.vid;
         this.wsData.vid = this.vid;
         this.buildInfo();
         this.getPhpVersions();
         this.getPendingStatus();
      },
      computed: {
          currentRouteName() { return this.$route.name; },
          operator ()        { return this.$store.state.operator; },
          current_user ()    { return this.$store.state.current_user; },
          jwt ()             { return this.$store.state.jwt; }
      }

   }

</script>
<style>
.fat-row {
   padding-top: 7px;
   padding-bottom: 7px;
}
</style>
