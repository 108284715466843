<template>
  <div>
    <h2 class='dbhead'>Account Summary</h2>
    <table :class="tab_class">
     <thead>
     </thead>
     <tbody>
      <tr><th class="w-25"><i class="fa fa-cog"></i> Account Number</th><td class="w-75">{{ info.uid }} </td></tr>
      <tr><th><i class="fa fa-cog"></i> Primary Account Number</th><td>{{ info.bill_uid }} </td></tr>
      <tr><th><i class="fa fa-user"></i> Logname</th><td>{{ info.logname }} </td></tr>
      <tr><th><i class="fa fa-user-secret"></i> Name</th><td>{{ info.first_name }} {{ info.last_name }}</td></tr>
      <tr><th><i class="fa fa-layer-group"></i> Role</th><td>{{ info.role }} </td></tr>
      <tr><th><i class="fa fa-university"></i> Company</th><td>{{ info.company }} </td></tr>
      <tr><th><i class="fa fa-street-view"></i> Address</th><td>{{ info.addr1 }}<br>{{ info.addr2 }}</td></tr>
      <tr><th><i class="fa fa-paper-plane"></i> Contact Email</th><td>{{ info.bill_email }} </td></tr>
      <tr><th><i class="fa fa-phone"></i> Home Phone</th><td>{{ info.home_phone }} </td></tr>
      <tr><th><i class="fa fa-phone"></i> Work Phone</th><td>{{ info.work_phone }} </td></tr>
      <tr><th><i class="fa fa-tag"></i> Pricing Plan</th><td>{{ info.pricing_plan }} </td></tr>
      <tr><th><i class="fa fa-star"></i> Account Type</th><td>{{ info.account_type }} </td></tr>
      <tr><th><i class="fa fa-calendar"></i> Billing Cycle</th><td>{{ info.billing_code }} </td></tr>
      <tr><th><i class="fa fa-credit-card"></i> Auto Billing?</th><td>{{ info.auto_bill }} </td></tr>
      <tr><th><i class="fa fa-umbrella"></i> Realm</th><td>{{ info.realm }} </td></tr>
     </tbody>
    </table>

  </div>
</template>

<script>

import axios from "axios";
export default {
      data () {
        return {
           info: []
        }
      },
      watch: {
          current_user: function (newUser, oldUser) {
            this.refresh_info();
          }
      },
      methods: {
          refresh_info () {
             var config = { headers:
                             { Authorization: "Bearer " + this.jwt }
                          };

             axios.get("/api/customer/" + this.current_user, config )
             .then (
                    response => {
                       this.info = response.data.data;
                    })
             .catch(error => {
                       if (error.response) {
                          if (error.response.data.status && error.response.data.status == "expired_token") {
                              this.$store.dispatch('expired');
                          }
                       }
                       this.info =  [ { 'Status': 'Failed', 
                                   'uid': 0,
                                   'account': this.current_user, 
                                   'error': error } ];
                    })
          }
      },
      mounted () {
          this.refresh_info();
      },
      computed: {
          current_user () { return this.$store.state.current_user; },
          jwt () { return this.$store.state.jwt; },
          logged_in () { return this.$store.state.logged_in; },
          mode ()       { return this.$store.state.mode; },
          tab_class () { 
            return "table table-hover table-striped table-" + this.mode;
          }
      }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped> 
.table td, .table th {
    padding: .5rem .75rem;
}
.fa {
    color: dodgerblue;
}
.table th {
    border-right: #cccccc solid 1px;
}
</style>
