<template>
   <div>
    <div class="row my-3">
        <span class="badge badge-pill badge-primary">ExD - Expiration Date</span>
        <span class="badge badge-pill badge-info">A/R - Auto Renew Yearly</span>
        <span class="badge badge-pill badge-primary">R/P - Renewal Pending</span>
        <span class="badge badge-pill badge-info">L/E - Let Expire (Abandon)</span>
    </div>
    <div class="row">
        <h2 class='dbhead'>{{activity}} for <span class="swcp-em">{{current_user}}</span> </h2>
    </div>
    
    <vue-good-table v-if="rows.length > 0"
      :columns="columns"
      :rows="rows"
      :theme="vgt_theme"
      styleClass="vgt-table condensed bordered"
      @on-row-click="selectDom"
      :sort-options="{
          enabled: true,
          initialSortBy: {field: 'domain', type: 'asc'}
      }"
      :pagination-options="{
        enabled: true,
        mode: 'records',
        perPage: 10,
        position: 'top',
        perPageDropdown: [5, 10, 25, 50],
        dropdownAllowAll: false,
        setCurrentPage: 1,
        nextLabel: 'next',
        prevLabel: 'prev',
        rowsPerPageLabel: 'Rows per page',
        ofLabel: 'of',
        pageLabel: 'page', // for 'pages' mode
        allLabel: 'All',
      }"
      :search-options="{
         enabled: false,
         placeholder: 'Search All Fields'
       }"
    >
   </vue-good-table>
   <h3 v-else>No Domains Found</h3>

  </div>
</template>

<script>

import VueGoodTable from '@/components/vue3-good-table/Table';
import axios from "axios";


export default {
      components: {
         VueGoodTable
      },
      data () {
        return {
          account: '',
          columns: [
                   {
                     field: "domain",
                     label: "Domain",
                     filterOptions: {
                         enabled: true, // enable filter for this column
                         placeholder: 'Search', // placeholder for filter input
                     },
                     sortable: true
                   },
                   {
                     field: "expiration_date",
                     label: "ExD",
                     type: 'string',
                     sortable: true
                   },
                   {
                     field: "auto_renew",
                     label: "A/R",
                     type: 'number',
                     sortable: false,
                     formatFn: this.yesno
                   },
                   {
                     field: "abandon",
                     label: "L/E",
                     type: 'number',
                     sortable: false,
                     formatFn: this.yesno
                   },
                   {
                     field: "renewal_pending",
                     label: "R/P",
                     type: 'number',
                     sortable: false,
                     formatFn: this.yesno
                   },
                   {
                     field: "contact_email",
                     label: "Contact Email",
                     filterOptions: {
                         enabled: true, // enable filter for this column
                         placeholder: 'Search', // placeholder for filter input
                     },
                     sortable: false
                   }
                 ],
          rows: [],
          prices: [],
          selected_row: {}
        }
      },
      watch: {
          current_user: function (newUser, oldUser) {
            this.getDomainList();
          }
      },

      mounted() {
          this.getDomainList();
      },
      methods: {
         yesno: function (value) {
                   if (value >= 1) { return "Yes"; }
                   else { return "No"; }
         },
         getDomainList() {
            var config = { headers:
                            { Authorization: "Bearer " + this.jwt }
                         };

            this.account = this.current_user;
            var url = "/api/domains/" + this.account;
            axios.get(url, config )
            .then (
                   response => {
                      this.rows = response.data.data;
                   })
            .catch(error => {
                   if (error.response) {
                      if (error.response.data.status && error.response.data.status == "expired_token") {
                          this.$store.dispatch('expired');
                      }
                   }
                   this.rows = [ { 'domain': 'unknown', 
                                   'Status': 'Failed', 
                                   'account': this.account, 
                                   'error': error } ];
                   })
         },
         selectDom(params) {
            var row = params.row;
            this.selected_row = row;
            if (this.currentRouteName == "renew-domains") {
               this.$router.push({ name: 'domain-renewal', params: { domain: row.domain }});
            } else if (this.currentRouteName == "domain-permissions") {
               this.$router.push({ name: 'permissions', params: { domain: row.domain }});
            } else {
               this.$router.push({ name: 'edit-domain', params: { domain: row.domain }});
            }
         },
         route_to_activity(route) {
            var mapacts = { 'domain-list': 'Edit Domains',
                            'renew-domains': 'Renew Domains',
                            'domain-permissions': 'Domain Permissions'
                          };
            return mapacts[route];
         }
      },
      computed: {
          activity()         { return this.route_to_activity(this.$route.name); },
          currentRouteName() { return this.$route.name; },
          operator ()        { return this.$store.state.operator; },
          current_user ()    { return this.$store.state.current_user; },
          mode()             { return this.$store.state.mode; },
          jwt ()             { return this.$store.state.jwt; },
          vgt_theme()        { 
              if (this.mode == "light") {
                 return "swcptab";
              } else {
                 return "swcpdark";
              }
          }
      }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.vgt-wrap__footer {
    color: #000000;
    font-size: 1.1rem;
    padding: 0.5em !important;
    background: linear-gradient(#13C5E4, #F1F3F6);
}

</style>
