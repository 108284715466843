<template>
   <div>
    <div class="row">
        <h2 class='dbhead'>BUS Accounts for <span class="swcp-em">{{ current_user}}</span> </h2>
    </div>
    
    <div class="row">
    <table :class="tab_class">
       <thead class="thead-dark">
         <tr>
          <th>Account</th>
          <th>Plan</th>
          <th>Total Quota</th>
          <th>Percent Used</th>
          <th>Active Files</th>
          <th>Retained Files</th>
          <th>Notes</th>
         </tr>
       </thead>
       <tbody>
         <tr v-for="row in rows" :key="row.uid">
          <td>{{row.account}}</td>
          <td>{{row.plan}}</td>
          <td class='text-right'>{{row.total_quota}} GB</td>
          <td class='text-right'>{{row.percent_used}} %</td>
          <td class='text-right'>{{commafy(row.active_files)}}</td>
          <td class='text-right'>{{commafy(row.retained_files)}}</td>
          <td>{{row.comment}}</td>
         </tr>
       </tbody>
    </table>
    </div>
  </div>
</template>

<script>

import axios from "axios";

export default {
      name: 'BusAccounts',
      emits: [ 'message' ],
      data () {
        return {
          rows: [],
          bus_accounts: []
        }
      },
      watch: {
          current_user: function (newUser, oldUser) {
            this.getBusAccounts();
          }
      },

      mounted() {
          this.getBusAccounts();
      },
      methods: {
         commafy (num) {
            return parseInt(num).toLocaleString();
         },
         pretty_date (str) {
            if (str.length >= 8) {
               var year = str.substring(0,4);
               var month = str.substring(4,6);
               var day = str.substring(6,8);
               return  year + "-" + month + "-" + day;
            } else {
               return str;
            }
         },

         getBusAccounts() {
            var config = { headers:
                            { Authorization: "Bearer " + this.jwt }
                         };

            var url = "/api/bus-accounts/" + this.current_user;
            axios.get(url, config )
            .then (
                   response => {
                      this.bus_accounts = response.data.data.bus_accounts;
                      this.rows = this.bus_accounts;
                   })
            .catch(error => {
                   if (error.response) {
                      if (error.response.data.status && error.response.data.status == "expired_token") {
                          this.$store.dispatch('expired');
                      } 
                   } else {
                      this.responseMessage('Get Bus Accounts failed', error, 
                                           'modal', 'serious', '', []);
                   }
                   this.rows = [ ];
                   })
         },
         responseMessage(title, body, type, alert, footer, menu) {
                var args = { title: title, body: body,
                             type: type, alert: alert,
                             footer: footer, menu: menu };
                this.$store.commit('setStatus', args);
                this.$emit('message');
                return;
         }
      },
      computed: {
          operator ()        { return this.$store.state.operator; },
          current_user ()    { return this.$store.state.current_user; },
          jwt ()             { return this.$store.state.jwt; },
          mode ()             { return this.$store.state.mode; },
          tab_class ()       {
              if (this.mode == "light" ) { return "table table-bordered"; }
              else { return "table table-bordered table-dark"; }
          }
      }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
