<template>
   <div class="col-12">
    <h2 class='dbhead'>Edit Temporary Email</h2>
   </div>
   <div class="col-12">
     <div class="row form-group my-2">
         <div class="col-6">
             Alias 
           <div class="maxial">
             {{updateData.name}}@shadowz.com =>
           </div>
         </div>
         <div class="col-6">
             Forwards to <input class="maxial" type='text' v-model="updateData.forwards_to" /> 
         </div>
     </div>
     <div class="row form-group my-2">
         <div class="col-6">
              Expires at
              <select class='maxial email-select mb-5' v-model="updateData.expdate">
                  <option v-for="expdate in expdates"
                          v-bind:key="expdate.date"
                          v-bind:value="expdate.date"> {{ expdate.label }} {{ expdate.selected }}</option>
              </select>
          </div>
         <div class="col-6">
             Notes <input class="maxial" type='text' v-model="updateData.notes" /> 
         </div>
     </div>
     <div class="row form-group my-2">
         <div class="col-12 text-center">
             <button class="btn btn-info" v-on:click="updateAlias">Update Temp Email</button>
             <GoBack title="Back"></GoBack>
         </div>
     </div>

     <div class="col-12"><hr></div>
   </div>

</template>

<script>
import axios from "axios";
import GoBack from "@/components/GoBack";
export default {
    name: 'EditTempEmail',
    components: { GoBack },
    emits: [ 'message' ],
    mounted () {
        this.aliasData.alias = this.$route.params.alias;
        this.updateData.name = this.aliasData.alias;
        this.getAliasInfo (this.aliasData.alias);
    },
    watch: {
            current_user: function (newUser, oldUser) {
                 this.$router.push({ name: "temp-email"});
            }
    },

    methods: {
       getAliasInfo (alias) {
           var url = "/api/tempemail-alias";
           var config = { headers:
                            { Authorization: "Bearer " + this.jwt }
                         };
           this.aliasData.logname = this.current_user;
           axios.post(url, this.aliasData, config)
               .then (
                  response => {
                     if (response.data.status == 1) {
                         this.updateData.notes = response.data.data.notes;
                         this.updateData.forwards_to = response.data.data.forwards_to;
                         this.updateData.expdate = response.data.data.expires;
                         this.updateData.key = response.data.data.id;
                         this.getExpList(1);
                     } else {
                         this.responseMessage('No Alias Found', response.data.message, 'modal', 'serious', '', []);
                         this.$router.push({ name: 'temp-email' });
                     }
                  })
               .catch(error => {
                  this.responseMessage('Failed', error, 'modal', 'serious', '', []);
                  this.$router.push({ name: 'temp-email' });
               })
       },
       updateAlias () {
           var url = "/api/update-tempemail";
           var config = { headers:
                            { Authorization: "Bearer " + this.jwt }
                         };
           this.updateData.logname = this.current_user;
           console.log("Entering updateAlias");
           console.log(this.updateData);

           axios.post(url, this.updateData, config)
               .then (
                  response => {
                     console.log("Response data update-tempemail");
                     console.log(response.data);
                     if (response.data.status == 1) {
                         this.responseMessage('Success', 'Updated ' + this.updateData.name + "@shadowz.com", 'review', 'info', '', []);
                     } else {
                         this.responseMessage('Failed', response.data.message, 'modal', 'serious', '', []);
                     }
                  })
               .catch(error => {
                  this.responseMessage('Failed', error, 'modal', 'serious', '', []);
               })
       },
       adds_dom (alias) {
          return alias + "@shadowz.com";
       },
       responseMessage(title, body, type, alert, footer, menu) {
              var args = { title: title, body: body,
                           type: type, alert: alert,
                           footer: footer, menu: menu };
              this.$store.commit('setStatus', args);
              this.$emit('message');
              return;
       },

       getExpList(item) {
           var url = "/api/tempemail-explist";
           if (item) { url += "/" + this.updateData.expdate; }
           var config = { headers:
                            { Authorization: "Bearer " + this.jwt }
                         };
           axios.get(url, config)
               .then (
                  response => {
                      this.expdates = response.data.data.list;
                      this.updateData.expdate = response.data.data.selected_date;
                  })
               .catch(error => {
                  this.responseMessage('Failed', error, 'modal', 'serious', '', []);
               })
       }
    },
    computed: {
          currentRouteName() { return this.$route.name; },
          operator ()        { return this.$store.state.operator; },
          current_user ()    { return this.$store.state.current_user; },
          jwt ()             { return this.$store.state.jwt; }
   },
   data () {
      return {
        current_domain: '',
        expdates: [],
        first_date: '',
        aliasData: {
            alias: '',
            logname: ''
        },
        updateData: {
            logname: '',
            name: '',
            key: -1,
            forwards_to: '',
            expdate: '',
            notes: ''
        },
      }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.email-select {
   padding: 10px;
   border: 1px solid dodgerblue;
   border-radius: 5px;
}
.maxial {
  width: 100%;
}
.padme {
   padding-left: 5%;
}
</style>
