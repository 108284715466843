<template>
  <button v-on:click="goBack()" class="spaced btn btn-outline-primary justify-content-center">{{ title }}</button>
</template>

<script>
  export default {
    name: 'GoBack',
    props: { title: String },
    methods: {
      goBack() {
         this.$router.go(-1);
      }
    }
  }
</script>
