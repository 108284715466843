<template>
   <div class='formbox border-gradient'>
    <h2 class="dbhead">Login</h2>
    <form class="form-horizontal" method="post" v-on:submit.prevent="onSubmit" >
      <div class="form-group row">
        <label for="username" class="col-md-2 col-form-label">Username</label>
        <div class="col-md-10">
          <input autocomplete="username" name="username" class="form-control" id="username" 
                 type="text" v-model="userData.username"/><br />
        </div>
      </div>

      <div class="form-group row">
        <label for="password" class="col-md-2 col-form-label">Password</label>
        <div class="col-md-8">
          <input autocomplete="password" name="password" id="password" :type="type"
                 class="form-control" v-model="userData.password"/>
        </div>
        <div class="col-md-2">
          <button class="btn btn-outline-info" type="button" v-on:click="togglePassword"> <i class='fa fa-eye'></i> {{ showHide }} </button>
        </div>
      </div>

      <div class="form-group col text-center">
         <input type="submit" class="btn btn-info" value="Login" />
      </div>

       <div class="form-group col text-center">
          <p id='status'>{{ status.problems }}</p>
       </div>
       <div class="form-group col text-center">
          <router-link v-bind:to="{ name: 'request-panel-reset' }">Don't know your panel password?</router-link><br>
          <router-link v-bind:to="{ name: 'request-email-reset' }">Need to reset your email password?</router-link><br>
          <router-link v-bind:to="{ name: 'quickref' }">Quick References</router-link>
       </div>
    </form>
   </div>
</template>

<script>
   import axios from "axios";
   export default {
        name: "Login",
        emits: [ 'message' ],
        data () {
           return { 
              type: 'password',
              showHide: "Show",
              btnclass: "btn btn-outline-info",
              userData: {
                  username: '',
                  password: ''
              },
              status: {
                  logged_in: false,
                  problems: '',
                  failed_count: 0,
              }
           }
        },
        methods: {
           responseMessage(title, body, type, alert, footer, menu) {
             var args = { title: title, body: body,
                          type: type, alert: alert,
                          footer: footer, menu: menu };
             this.$store.commit('setStatus', args);
             this.$emit('message');
             return;         
           },             
           check_logname(logname) {
              return (! logname.includes('@'));
           },
           togglePassword() {
              if (this.type === "password") {
                  this.type = "text";
                  this.showHide = "Hide";
              } else {
                  this.type = "password";
                  this.showHide = "Show";
              }
           },
           onSubmit() { 
              this.userData.username = this.userData.username.toLowerCase();
              console.log("onSubmit: " + this.userData.username);
              if (this.check_logname(this.userData.username)) {
                  axios.post("/api/miglogin", this.userData)
                  .then (
                     response => {
                       this.status.logged_in = true;
                       this.status.problems = "";
                       this.status.failed_count = 0;
                       localStorage.setItem('id_token', response.data.id_token);
                       localStorage.setItem('current_user', this.userData.username);
                       localStorage.setItem('operator', this.userData.username);
                       localStorage.setItem('reply_to', response.data.bill_email);
                       localStorage.setItem('logged_in', true);
                       localStorage.setItem('mode', response.data.mode);
                       this.$store.commit('setJWT', response.data.id_token);
                       this.$store.commit('setCurrentUser', this.userData.username);
                       this.$store.commit('setOperator', this.userData.username);
                       this.$store.commit('setReplyTo', response.data.bill_email);
                       this.$store.commit('setLogState', true);
                       this.$store.commit('changeBanner', 'Logged in ' + this.userData.username);
                     })
                  .catch (error => {
                       console.log("Login Failed for " + this.userData.username);
                       this.$store.commit('setLogState', false);
                       this.status.failed_count++;
                       this.status.problems = "Login Failed " + 
                            this.status.failed_count + " Time(s) " + error;
                     })
              } else {
                 let msg = "Please use your account name, not your email address for logging in";
                 this.responseMessage("Login Failed", msg, "modal", "serious", '', []);
              }
           }
        }

   }
</script>

<style>

.swcp-short {
   width: 75%;
}
.formbox {
   margin-top: 20px;
   padding-left: 25px;
   padding-right: 25px;
   width: 100%;
   text-align: center;
}
.border-gradient {
  border-width: 12px;
  border-style: solid;
  border-image: linear-gradient(139deg, #0FC2E4, #034EA1, #2A21A8, #43197F) 1;
}
button.btn.noeye::before {
  font-family: fontAwesome;
  content: "\f044\00a0";
}
button.btn.eye::before {
  font-family: fontAwesome;
  content: "\f06e\00a0";
}
</style>
