import { createStore } from 'vuex'

export const store = createStore({
  state: {
    mode: 'light',
    banner_msg: 'SWCP Members Portal',
    logout_msg: '',
    status_body: 'Password changes take about ten minutes to propagate through our system. ',
    status_title: '',
    status_footer: '',
    status_alert: 'info',
    message_type: 'review',
    selected_domain: '',
    status_msg: '',
    status_visible: 0,
    status_type: 1,
    operator: '',
    current_user: '',
    reply_to: '',
    staff: false,
    currentPage: 'Empty' ,
    content_args: {},
    mini_menu: [ ], 
    logged_in: false,
    jwt: ''
  },
  mutations: {
    setStatus (state, args) {
      state.message_type = args.type;
      state.status_body = args.body;
      state.status_title = args.title;
      state.status_alert = args.alert;
      state.status_footer = args.footer;
      state.mini_menu = args.menu;
    },
    setMessageType(state, type) {
      state.message_type = type;
    },
    setStatusBody(state, body) {
      state.status_body = body;
    },
    setStatusTitle(state, title) {
      state.status_title = title;
    },
    setStatusAlert(state, alert) {
      state.status_alert = alert;
    },
    setStatusFooter(state, footer) {
      state.status_footer = footer;
    },
    setStatusMenu(state, menu) {
      state.mini_menu = menu;
    },
    setStatusType(state, type) {
      state.status_type = type;
    },
    setSelectedDomain(state, domain) {
      state.selected_domain = domain;
    },
    setStatusMessage(state, msg) {
      state.status_msg = msg;
      if (msg == '') {
         state.status_visible = 0;
      } else {
         state.status_visible = 1;
      }
    },
    setOperator(state, operator) {
      state.operator = operator;
    },
    setReplyTo(state, email) {
      state.reply_to = email;
    },
    setCurrentUser(state, user) {
      state.current_user = user;
    },
    setMode(state, mode) {
      state.mode = mode;
    },
    setStaff(state, staff) {
      state.staff = staff;
    },
    setLogState(state, logstate) {
      state.logged_in = logstate;
    },
    changeBanner(state, banner_msg) {
      state.banner_msg = banner_msg;
    },
    newPage(state, page) {
      state.currentPage = page;
    },
    setBanner(state, title) {
      state.banner_msg = title;
    },
    contentArgs(state, args) {
      state.content_args = args;
    },
    setJWT(state, jwt) {
      var claims;
      // JWT's are two base64-encoded JSON objects and a trailing signature
      // joined by periods. The middle section is the data payload.
      state.jwt = jwt;
      if (state.jwt) {
          claims = JSON.parse(atob(state.jwt.split('.')[1]));
      } else {
          claims = { username: '', altuser: '', role: '' }
      }
      state.operator = claims.username;
      state.staff = 0;
      if (claims.role === "admin" || claims.role === "staff") {
          state.staff = 1;
      }
    }
  },
  actions: {
    rehydrate(context, jwt ) {
      context.commit('setJWT', jwt);
      context.commit('changeBanner', 'SWCP Members Portal');
      context.commit('newPage', 'Empty');
      context.commit('setCurrentUser', localStorage.getItem('current_user'));
      context.commit('setOperator', localStorage.getItem('operator'));
      context.commit('setReplyTo', localStorage.getItem('reply_to'));
      context.commit('setLogState', localStorage.getItem('logged_in'));
      context.commit('setMode', localStorage.getItem('mode'));
    },
    expired(context) {
      console.log("Expired");
      context.commit('setJWT', '');
      context.commit('changeBanner', 'Logged Out');
      context.commit('newPage', 'Empty');
      context.commit('setCurrentUser', '');
      context.commit('setOperator', '');
      context.commit('setReplyTo', '');
      context.commit('setLogState', false);
      context.commit('setStatus', 
              { title: '', body: '', type: 'review', alert: 'info', footer: '', menu: [] });
      localStorage.removeItem('id_token');
      localStorage.removeItem('current_user');
      localStorage.removeItem('operator');
      localStorage.removeItem('reply_to');
      localStorage.removeItem('logged_in');
      localStorage.removeItem('mode');
    }
  }
})

