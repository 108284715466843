<template>
  <div class="container">
    <form @submit.prevent>
      <div class="row">
        <div class="col-sm-8">
        <table :class="tab_class">
          <tbody>
            <tr><th>Credit Card Number</th><td> xxxxxx {{card_method.ccn4}} </td></tr>
            <tr><th>Card Type</th><td>{{card_method.card_type}}</td></tr>
            <tr><th>Current Expiration Date</th><td>{{card_method.nice_expdate}}</td>
            </tr>
            <tr><th>On Auto Billing?</th><td>{{yesno(card_method.on_auto_billing)}}</td></tr>
          </tbody>
        </table>
        </div>
        <div class="col-sm-4"></div>
       </div>
       <div class="form-group row">
                 <label class="col-sm-3" for="expdate">New Expiration Date (MMYY)</label>
                 <input class="form-control col-sm-2" id="expdate" type="text" 
                               name="expdate"
                               v-model="userData.expdate"
                               placeholder="MMYY" aria-label="Expiration Date MMYY"> 
                 <ErrorMessage class="col-sm-2" :error="problems.expdate" />
       </div>
       <div class="form-group row">
                 <label class="col-sm-3">New Card Type</label>
                 <div class="form-check form-check-inline col-sm-7">
                      <label class="form-check-label">
                      <input class="form-check-input" type="radio"
                             v-model="userData.card_type"
                             name="cardtype" value="Visa" aria-label="Visa"/>
                             Visa &nbsp;&nbsp;</label>
                      <label class="form-check-label">
                      <input class="form-check-input" type="radio"
                             v-model="userData.card_type"
                             name="cardtype" value="M/C" aria-label="MasterCard" />
                             MasterCard  &nbsp;&nbsp; </label>
                      <label class="form-check-label">
                      <input class="form-check-input" type="radio"
                             v-model="userData.card_type"
                             name="cardtype" value="Discover" aria-label="Discover" />
                             Discover  &nbsp;&nbsp; </label>
                      <label class="form-check-label">
                      <input class="form-check-input" type="radio"
                             v-model="userData.card_type"
                             name="cardtype" value="AmExp" aria-label="American Express" />
                             AmExp  </label>
                </div>
       </div>

       <div class="form-group row">
                 <input type="hidden" v-model="userData.ccn4" id="ccn4">
       </div>
       <div class="form-group row">
            <h5 class="demark">Billing Address (Leave empty if you aren't changing the billing address)</h5>
            <Address
                   name_tag="Card Holder Name"
                   v-model:first_name="userData.first_name"
                   v-model:last_name="userData.last_name"
                   v-model:company="userData.company"
                   v-model:address="userData.address"
                   v-model:city="userData.city"
                   v-model:state="userData.state"
                   v-model:zipcode="userData.zipcode"
                   v-model:country="userData.country"
                   v-model:problems="problems"
            >
            </Address>

       </div>
       <div class="form-group row demark">
             Should card be used for auto billing? &nbsp;&nbsp;
             <div class="form-check form-check-inline">
                <label class="form-check-label">
                <input class="form-check-input" name="auto_bill" type="radio" 
                       v-model="userData.auto_bill"
                             value="1" aria-label="Yes"
                       :checked="this.card_method.on_auto_billing == 1">
                       Yes &nbsp;</label>
             </div>
             <div class="form-check form-check-inline">
                <label class="form-check-label">
                <input class="form-check-input" name="auto_bill" type="radio" 
                       v-model="userData.auto_bill"
                             value="0" aria-label="No"
                       :checked="this.card_method.on_auto_billing == 0">
                       No &nbsp;</label>
             </div>
      </div>
      <div class="form-group row">
           <div class="offset-sm-3 col-sm-6">
                <button v-on:click="updateCard" class="btn btn-outline-primary"
                        type="button">Update Card</button>
                &nbsp;
                <button v-on:click="deleteCard" class="btn btn-outline-primary"
                        type="button">Delete Card</button>
           </div>
           <div class="col-sm-3"></div>
      </div>
   </form>
  </div>
</template>

<script>
// Note: We aren't validating billing address information because if a field is
// left empty Auth.net doens't update it. So we don't need to require all fields
// to be filled in. The only validation we need is the expiration date.
//
   import axios from "axios";
   import { useVuelidate } from '@vuelidate/core'
   import { required, numeric, helpers } from '@vuelidate/validators';
   import { luhn, valid_mmyy_date, valid_expiration_date, positive_amount } from '@/helpers/utils.js';
   import ErrorMessage from '@/components/ErrorMessage.vue'
   import Address from '@/components/Address.vue';
   export default {
      name: 'UpdateCreditCard',
      components: { Address, ErrorMessage },
      emits: [ 'updated-card', 'deleted-card', 'message' ],
      props: {
          which_card: Number,
          card_method: Object
      },
      data () {
        return {
           v$: useVuelidate(),
           fields: [ 'first_name', 'last_name', 'address', 'city',
                     'state', 'zipcode', 'country', 'expdate', 'card_type' ],
           userData: {
               account: '',
               id: 0,
               auto_bill: this.card_method.on_auto_billing,
               card_type: '',
               ccn4: '',
               expdate: '',
               first_name: '',
               last_name: '',
               company: '',
               address: '',
               city: '',
               state: '',
               zipcode: '',
               country: ''
           },
           problems: {
               first_name: '',
               last_name: '',
               company: '',
               address: '',
               city: '',
               state: '',
               zipcode: '',
               country: '',
               expdate: '',
               card_type: ''
           }
        }
      },
      validations: {
        userData: {
            expdate: { valid_expiration_date: helpers.withMessage('Invalid Expiration', valid_expiration_date) }
        }
      },                
      methods: {
         responseMessage(title, body, type, alert, footer, menu) {
           var args = { title: title, body: body,
                        type: type, alert: alert,
                        footer: footer, menu: menu };
           this.$store.commit('setStatus', args);
           this.$emit('message');
           return;
         },
         clearForm() {
            this.userData.expdate = '';
            this.userData.auto_bill = 0;
         },
         clearProblems() {
            this.fields.forEach ((item) => { 
               this.problems[item] = ''; 
            });       
         },           
         yesno(item) {
            if (item == 1) {
               return "Yes";
            } else {
               return "No";
            }
         },

         async updateCard() {
            var url = "api/update-card";
            var config = { headers:
                            { Authorization: "Bearer " + this.jwt }
                         };
            var message, property, msg;

            this.userData.account = this.current_user;
            this.userData.id = this.card_method.id;
            this.userData.ccn4 = this.card_method.ccn4;
            // console.log(JSON.stringify(this.userData));
            const result = await this.v$.$validate();
            msg = '';
            if (! result) {
                  msg = "Validation problems found:";
                  this.clearProblems();
                  this.v$.$errors.forEach (err => {
                      property = err.$property;
                      message = err.$message;
                      this.problems[property] = message;
                      if (message != "") {
                          msg += " " + property + "=" + message + "\n";
                      }
                  });
                  console.log(msg);
            } else {
//          Remember you need to emit from within the then/catch promise block, NOT after the axios!!!
//          It isn't done until you reach the then/catch blocks!!

                 axios.post(url, this.userData, config )
                 .then (
                        response => {
                           if (response.data.status) {
                               this.responseMessage("Success", "Your card has been updated", "review", "info", '', []);
                           } else {
                               this.responseMessage("Failed", response.data.message, "modal", "serious", '', []);
                           }
                           this.$emit('updated-card');
                        })
                 .catch(error => {
                           this.responseMessage("Failed", "Unable to update this card " + error, "modal", "serious", '', []);
                           this.$emit('updated-card');
                        })
            }     
         },
         deleteCard() {
            var url = "api/delete-card";
            var title = "";
            var body = "";
            var config = { headers:
                            { Authorization: "Bearer " + this.jwt }
                         };
            this.userData.account = this.current_user;
            this.userData.id = this.card_method.id;
            this.userData.ccn4 = this.card_method.ccn4;
            // console.log(JSON.stringify(this.userData));
            var card_descr = "(..." + this.card_method.ccn4; 
            card_descr += " " + this.card_method.card_type + " ";
            card_descr += this.card_method.nice_expdate + ")"; 
            if(confirm("Do you really want to delete this card? " + card_descr)) {
               axios.post(url, this.userData, config )
               .then (
                      response => {
                         if (response.data.status) {
                             title = "Success";
                             body = "Card has been deleted";
                             this.$emit('deleted-card');
                         } else {
                             title = "Failed";
                             body = response.data.message;
                             console.log("Delete Failed " + body);
                         }
                         this.responseMessage(title, body, "review", "info", '', []);
                      })
               .catch(error => {
                         this.responseMessage("Failed", "Unable to update this card " + error, "modal", "serious", '', []);
                      })
           }
         }
      },
      computed: {
           user () { return this.$store.state.user; },
           current_user () { return this.$store.state.current_user; },
           staff () { return this.$store.state.staff; },
           jwt () { return this.$store.state.jwt; },
           mode() { return this.$store.state.mode; },
           tab_class () {
               return "table table-sm table-" + this.mode;
           }  
      }
   }

</script>

<style scoped>
.demark {
    border-bottom: 2px #458fea solid;
    padding-bottom: 4px;
    margin-bottom: 30px;
}
</style>
