<template>
  <span :class="errorClass(error)"> {{ error }} </span>
</template>

<script>
  export default {
    name: 'ErrorMessage',
    props: { error: String },
    methods: {
      errorClass(error) {
         if (error == "") { return ""; }
         else { return "invalid"; }
      }
    }
  }
</script>
