<template>
     <div class="row form-group my-5">
         <div class="col-6">
             <input type='text' v-model="addData.src_user" /> @ {{addData.src_domain}} =>
         </div>
         <div class="col-6">
             <textarea class="form_control maxial" rows=5  v-model="addData.targets"></textarea>
         </div>
     </div>
       
     <div class="row justify-content-center">
         <button class="btn btn-info" v-on:click="createAlias">Add Alias</button>
         <GoBack title="Cancel"></GoBack>
     </div>

</template>

<script>

import GoBack from "@/components/GoBack";
import axios from "axios";
export default {
    name: 'CreateEmailAlias',
    components: { GoBack },
    emits: [ 'message' ],
    methods: {
       full_email (src, domain) {
          return src + "@" + domain;
       },
       createAlias() {
           var url = "/api/add-domain-alias";
           var config = { headers:
                            { Authorization: "Bearer " + this.jwt }
                         };
           axios.post(url, this.addData, config)
               .then (
                  response => {
                      if (response.data.status) {
                         this.addData.src_user = '';
                         this.addData.targets = '';
                         this.responseMessage('Additions', response.data.message, 'review', 'info', '', [] );
                      } else {
                         this.responseMessage('Addition Failed', response.data.message, 'modal', 'serious', '', []);
                      }
                  })
               .catch(error => {
                  this.responseMessage('Failed', error, 'modal', 'serious', '', []);
               })

       },
       responseMessage(title, body, type, alert, footer, menu) {
              var args = { title: title, body: body,
                           type: type, alert: alert,
                           footer: footer, menu: menu };
              this.$store.commit('setStatus', args);
              console.log("createEmailAlias: responseMessage");
              console.log(args);
              this.$emit('message');
              return;
       }
    },
    mounted () {
        this.addData.src_domain = this.$route.params.src_domain;
    },
    computed: {
          operator ()        { return this.$store.state.operator; },
          current_user ()    { return this.$store.state.current_user; },
          jwt ()             { return this.$store.state.jwt; }
   },
   data() {
      return {
        addData: {
            src_user: '',
            src_domain: '',
            targets: ''
        }
      }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.maxial {
  width: 100%;
}

</style>
