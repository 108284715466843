<template>
  <div>
    <h2 class='dbhead'>Search Support Wiki</h2>
    <div>Search <input type="txt" v-model="search" name="search">
         <button @click="search_wiki">Search</button>
    </div>
    <iframe class='bigger' :src="source"></iframe>
  </div>
</template>

<script>

import axios from "axios";
export default {
      data () {
        return {
           html: "<p>Loading...</p>",
           search: "",
           source: ""
        }
      },
      methods: {
          build_url (search) {
             let base="https://members.swcp.com/w/index.php?title=Special%3ASearch&search=";
             let search_type = "&fulltext=Search";
             let url = base + search + search_type;
             return url;
          },
          search_wiki () { 
             this.source = this.build_url(this.search);
          }
      }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped> 
.bigger {
    width: 100%;
    min-height: 500px;
}

.table td, .table th {
    padding: .5rem .75rem;
}
.fa {
    color: dodgerblue;
}
.table th {
    color: #333333;
    border-right: #cccccc solid 1px;
}
</style>
