<template>
  <div>
    <div class="row">
      <div class="col-sm-12">
       <div v-if="status">
          <h2> Edit {{ domainData.domain }} </h2>
          <div class="form-group row">
                 <label class="col-sm-3">Auto Renew Yearly?</label>
                 <div class="form-check form-check-inline col-sm-5">
                      <input class="form-check-input" type="radio" v-model="domainData.auto_renew" 
                             value=1 aria-label="Yes"> Yes &nbsp;&nbsp;
                      <input class="form-check-input" type="radio" v-model="domainData.auto_renew" 
                             value=0 aria-label="No"> No<br>
                </div>
          </div>
          <div class="form-group row">
                 <label class="col-sm-3">Let Domain Expire</label>
                 <div class="form-check form-check-inline col-sm-5">
                      <input class="form-check-input" type="radio" v-model="domainData.abandon" 
                             value=1 aria-label="Yes"> Yes &nbsp;&nbsp;
                      <input class="form-check-input" type="radio" v-model="domainData.abandon" 
                             value=0 aria-label="No"> No<br>
                </div>
          </div>
          <div class="form-group row">
                 <label class="col-sm-3">Contact Email Address</label>
                 <input class="form-control col-sm-8" type="text" v-model="domainData.contact_email"
                        placeholder="Leave blank to use primary account's SWCP email address" aria-label="Contact Email">
          </div>
          <div class="form-group row justify-content-center">
               <button v-on:click="updateDomain" type="button" class="spaced btn btn-primary justify-content-center">Update</button>
               <GoBack title="Cancel"></GoBack>
          </div>
       </div>
    </div>
  </div>
 </div>
</template>

<script>
   import axios from "axios";
   import GoBack from "@/components/GoBack";
   export default {
      name: 'DomainEdit',
      components: { GoBack },
      emits: [ 'message' ],
      data () {
        return {
           errors: [],
           status: false,
           info: [],
           domainData: {
               domain: 0,
               auto_renew: 0,
               abandon: 0,
               contact_email: '' 
           },
        }
      },
      methods: {
        responseMessage(title, body, type, alert, footer, menu) {
           var args = { title: title, body: body,
                        type: type, alert: alert,
                        footer: footer, menu: menu };
           this.$store.commit('setStatus', args);
           this.$emit('message');
           return;
         },
        updateDomain() {
            var url = "/api/domainedit";
            var config = { headers:
                            { Authorization: "Bearer " + this.jwt }
                         };
            axios.post(url, this.domainData, config )
              .then (
                 response => {
                    if (response.data.status) {
                        this.responseMessage(this.domainData.domain + " is updated", '', 'review', 'info', '', []);
                        this.status = true;
                    } else {
                        this.responseMessage('Failed', response.data.message, 'modal', 'serious', '', []);
                        this.status = true;
                    }
                 })
              .catch(error => {
                    if (error.response.data.status && error.response.data.status == "expired_token") {
                        this.$store.dispatch('expired');
                    } else {
                        this.responseMessage("Not authorized to edit " + this.domainData.domain, error, 'modal', 'serious', '', []);
                    }
                    this.status = false;
                 })
         },
        getDomainInfo() {
            var domain = this.$route.params.domain;
            var url = "/api/domain-status/" + domain;
            var config = { headers:
                            { Authorization: "Bearer " + this.jwt }
                         };
            this.domainData.domain = domain;
            axios.get(url, config )
              .then (
                 response => {
                    if (response.data.status) {
                        this.info = response.data.data;
                        this.domainData.auto_renew = this.info.auto_renew;
                        this.domainData.abandon = this.info.abandon;
                        this.domainData.contact_email = this.info.contact_email;
                        this.status = true;
                    } else {
                        this.responseMessage(response.data.message, this.domainData.domain, 'modal', 'serious', '', []);
                        this.status = false;
                    }
                 })
              .catch(error => {
                    if (error.response.data.status && error.response.data.status == "expired_token") {
                        this.$store.dispatch('expired');
                    } else {
                       this.responseMessage("Can't get status for " + this.domainData.domain, error, 'modal', 'serious', '', []);
                    }
                    this.status = false;
                 })
         }
      },
      mounted()  {
         this.getDomainInfo();
      },
      computed: {
          currentRouteName() { return this.$route.name; },
          operator ()        { return this.$store.state.operator; },
          current_user ()    { return this.$store.state.current_user; },
          jwt ()             { return this.$store.state.jwt; }
      }

   }

</script>

