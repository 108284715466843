<template>
  <div>
   <h2 class='dbhead'> Mailing Lists for <span class="swcp-em">{{current_user}}</span></h2>
    <vue-good-table
      :columns="columns"
      :rows="rows"
      :theme="vgt_theme"
      :sort-options="{
          enabled: true,
          initialSortBy: {field: 'listname', type: 'asc'}
      }"
      :pagination-options="{
        enabled: true,
        mode: 'records',
        perPage: 10,
        position: 'top',
        perPageDropdown: [5, 10, 25, 50],
        dropdownAllowAll: false,
        setCurrentPage: 1,
        nextLabel: 'next',
        prevLabel: 'prev',
        rowsPerPageLabel: 'Rows per page',
        ofLabel: 'of',
        pageLabel: 'page', // for 'pages' mode
        allLabel: 'All',
      }"
      :search-options="{
         enabled: false,
         placeholder: 'Search All Fields'
       }"
       styleClass="vgt-table bordered condensed"
     >
   </vue-good-table>
  </div>
</template>

<script>

import VueGoodTable from '@/components/vue3-good-table/Table';
import axios from "axios";

export default {
     name: 'MailingLists',
     components: {
        VueGoodTable
     },
     data () {
         return {
           account: '',
           columns: [  
             {
               field: "listname",
               label: "List Name",
               filterOptions: {
                  enabled: true, // enable filter for this column
                  placeholder: 'Search', // placeholder for filter input
               },
               sortable: true
             },
             {
               field: "list_link",
               label: "List Link",
               sortable: false,
               html: true
             },
             {
               field: "list_type",
               label: "List Type",
               type: "string",
               sortable: false,
               formatFn: this.expandType
             }
           ],
           rows: [ {'listname': 'MyList', 'list_type': 'MM' },
                   {'listname': 'YourList', 'list_type': 'Boo' } ]
         }
     },
     mounted() {
         this.getMailingLists();
     },
     watch: {
             current_user: function (newUser, oldUser) {
               this.account = this.current_user;
               this.getMailingLists();
             }
     },
     methods: {
       addURL (list) { 
            return "https://mailman.swcp.com/mailman/admin/" + list; 
       },
       link_btn (name, url, type) {
          if (type == "MM") { 
              return "<a class='btn btn-outline-info' target='_ml' href='" + url + "'>" + name + "</a>";
          } else {
              return name;
          }
       },
       newItem (item) {
          var url = this.addURL(item['listname']);
          item['list_link'] = this.link_btn(item['listname'], url, item['list_type']);
          return item;
       },
       convert_names_2_links (lists) {
          return lists.map(this.newItem);
       },
       getMailingLists () {
         var config = { headers:
                             { Authorization: "Bearer " + this.jwt }
                      };
         this.account = this.current_user;
         axios.get("/api/mailing-lists/" + this.account, config )
         .then (
                 response => {
                       this.rows = this.convert_names_2_links(response.data.data.mailing_lists);
                 })
         .catch(error => {
                 var notes = "";
                 if (error.response) {
                    if (error.response.data.message) {
                        notes = error.response.data.message;
                    }
                    if (error.response.data.status && error.response.data.status == "expired_token") {
                        this.$store.dispatch('expired');
                    }
                 } 
                 this.rows = [ { 'listname': 'unknown', 
                                 'list_type': 'MM' } ];
                 })
   
       },
       expandType: function (value) {
                 if (value == "MM") { return "Mailman"; }
                 if (value == "MJ") { return "Majordomo"; }
                 else { return "Unknown-" + value; }
       }
     },
     computed: {
          currentRouteName() { return this.$route.name; },
          operator ()        { return this.$store.state.operator; },
          current_user ()    { return this.$store.state.current_user; },
          mode()             { return this.$store.state.mode; },
          jwt ()             { return this.$store.state.jwt; },
          vgt_theme()        {
              if (this.mode == "light") {
                 return "swcptab";
              } else {
                 return "swcpdark";
              }
          }
     }
   
   }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
