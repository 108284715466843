<template>
   <div class="col-12">
    <h2 class='dbhead'>Add and Delete Recovery Email addresses for <span class="swcp-em">{{current_user}}</span></h2>
   </div>
   <div class="col-12">
     <div class="row form-group my-2">
         <div class="col-10 ducks">
             Recovery Email <input class="form-control midial" type='text' v-model="addData.email" /> 
         </div>
         <div class="col-2">
             <button class="btn btn-info" v-on:click="createRecovery">Add Email</button>
         </div>
     </div>

     <div class="col-12"><hr></div>
     <div class="col-12">
        <vue-good-table
          :columns="columns"
          :rows="rows"
          :theme="vgt_theme"
          @on-cell-click="selectCell"
          :sort-options="{
              enabled: true,
              initialSortBy: {field: 'contact_info', type: 'asc'}
          }"
          :pagination-options="{
            enabled: false
          }"
          :search-options="{
             enabled: false,
             placeholder: 'Search All Fields'
           }"
           styleClass="vgt-table bordered condensed"
         >
       </vue-good-table>
    </div>
   </div>

</template>

<script>
import axios from "axios";
import VueGoodTable from '@/components/vue3-good-table/Table';
import UpdateEmailAlias from "@/components/UpdateEmailAlias";
import CreateEmailAlias from "@/components/CreateEmailAlias";
export default {
    name: 'RecoveryContacts',
    components: { UpdateEmailAlias, CreateEmailAlias, VueGoodTable },
    emits: [ 'message' ],
    mounted () {
        this.getRecEmailList();
    },
    watch: {
          current_user: function (newUser, oldUser) {
            this.getRecEmailList();
          }
    },
    methods: {
       append_buttons(emails) {
           emails.forEach(email => {
              email.delbtn = "<button class='btn btn-info'>Delete</button>";
           });
           return emails;
       },
       createRecovery () {
           var url = "/api/add-recovery-email";
           var config = { headers:
                            { Authorization: "Bearer " + this.jwt }
                         };
           this.addData.logname = this.current_user;
           console.log(this.addData);

           axios.post(url, this.addData, config)
               .then (
                  response => {
                     console.log(response.data);
                     if (response.data.status == 1) {
                         this.responseMessage('Success', 'Added ' + this.addData.email,
                                              'review', 'info', '', [] );
                         this.addData.email = '';
                         this.getRecEmailList();
                     } else {
                         this.responseMessage('Failed', response.data.message, 'modal', 'serious', '', []);
                     }
                  })
               .catch(error => {
                  this.responseMessage('Failed', error, 'modal', 'serious', '', []);
               })
       },
       deleteEmail(id, account, email) {
           var url = "/api/delete-recovery-email";
           var config = { headers:
                            { Authorization: "Bearer " + this.jwt }
                         };
           this.delData.account = account;
           this.delData.id = id;
           axios.post(url, this.delData, config)
               .then (
                  response => {
                      if (response.data.status) {
                          this.responseMessage('Deleted', email, 'review', 'info', '', []);
                          this.getRecEmailList();
                      } else {
                          this.responseMessage('Failed', response.data.message, 'modal', 'serious', '', []);
                      }
                  })
               .catch(error => {
                      if (error.response.data.status && error.response.data.status == "expired_token") {
                          this.$store.dispatch('expired');
                      }
                      this.responseMessage('Failed', error, 'modal', 'serious', '', []);
               })

       },
       selectCell(params) {
           var row = params.row;
           this.selected_row = row;
           this.selected_cell = params.column;
           if (this.selected_cell.field == "delbtn") {
               this.deleteEmail(this.selected_row.id, this.current_user, this.selected_row.contact_info);
           }
       },
       responseMessage(title, body, type, alert, footer, menu) {
              var args = { title: title, body: body,
                           type: type, alert: alert,
                           footer: footer, menu: menu };
              this.$store.commit('setStatus', args);
              this.$emit('message');
              return;
       },
       getRecEmailList() {
           var url = "/api/get-recovery-emails/";
           var config = { headers:
                            { Authorization: "Bearer " + this.jwt }
                        };
           console.log("GetRecEmail list for " + this.current_user);
           axios.get(url + this.current_user, config )
              .then (
                 response => {
                    this.rows = this.append_buttons(response.data.data.email_list);
                    this.err = "";
                    if (! this.rows.length) { this.err = "No Recovery Addresses Found"; }
                 })
              .catch(error => {
                    if (error.response.data.status && error.response.data.status == "expired_token") {
                        this.$store.dispatch('expired');
                    }
                    console.log("Didn't find temp emails for " + this.current_user);
                    this.rows = [ ];
               })
       }
    },
    computed: {
          currentRouteName() { return this.$route.name; },
          operator ()        { return this.$store.state.operator; },
          current_user ()    { return this.$store.state.current_user; },
          mode()             { return this.$store.state.mode; },
          jwt ()             { return this.$store.state.jwt; },
          vgt_theme()        {
              if (this.mode == "light") {
                 return "swcptab";
              } else {
                 return "swcpdark";
              }
          }
   },
   data () {
      return {
        current_domain: '',
        expdates: [],
        first_date: '',
        selected_row: [],
        selected_cell: '',
        addData: {
            logname: '',
            email: ''
        },
        delData: {
            account: '',
            id: ''
        },
        columns: [
             {
               field: "id",
               label: "ID",
               sortable: false
             },
             {
               field: "uid",
               label: "UID",
               sortable: false
             },
             {
               field: "contact_info",
               label: "Recovery Address",
               sortable: true
             },
             {
               field: "delbtn",
               label: "Delete",
               sortable: false,
               width: "10%",
               html: true
             }

        ],
        rows: [ ]
      }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.email-select {
   padding: 10px;
   border: 1px solid dodgerblue;
   border-radius: 5px;
}
.midial {
  width: 50%;
}
.maxial {
  width: 100%;
}
.padme {
   padding-left: 5%;
}
</style>
