<template>
  <div class="container">
    <div class="row">
       <div class="offset-sm-1 col-sm-10">
           <h2 class="dbhead">Change Billing Method</h2>
       </div> <!-- col-sm-10 -->
    </div> <!-- row -->

    <div v-if="this.acc_role!='Secondary'">
       <div class="row">
          <div class="offset-sm-1 col-sm-10">
            <p>SWCP provides the following options for receiving your bills.</p>
        
            <b>Standard Billing</b>
             <ul class="bullets">
                <li>If you are on credit card billing, you receive a statement via
                    email each month.</li>
                <li>If you are on semi-annual or annual billing, you receive a paper
                    invoice in the mail when your account is out of money, and an email
                    statement monthly if you still have money on account.</li>
                <li>If you're billed monthly  you get a paper invoice in the mail</li>
            </ul>
            <b>Other Options</b><br/>
            <b>Statements</b> show that status of your account whether you owe money
            or not. <br><b>Invoices</b> only show money owed.
          </div> <!-- col-sm-10 -->
       </div> <!-- row -->
   
       <VForm class="form-group" @submit="setStDel">
         <div class="row my-2 my-lg-0">
           <div class="offset-sm-1 col-sm-10">
            <br>
              <ul><b>
                 <VField name="method" type=radio v-model=userData.method value="n" />
                 Standard invoice delivery, as described above<br>
                 <VField name="method" type=radio v-model=userData.method value="e" />
                 Deliver statements and invoices only by email<br>
                 <VField name="method" type=radio v-model=userData.method value="p" />
                 Deliver statements and invoices only by paper US Mail<br>
                 <VField name="method" type=radio v-model=userData.method value="ep" />
                 Deliver statements and invoices by <em>both</em> email and US Mail<br><br>
   
                 <input name="nozero" type=checkbox true-value="1" false-value="0" v-model=userData.nozero  />
                 Only send something when money is owed<br>
   
                 </b>
              </ul>
             </div> <!-- col-sm-10 -->
         </div> <!-- row -->
         <div class="row">
              <div class="col-sm-12">
                 <ErrorMessage class="form-error" name="email" /><br>
                 <div class="ducks">
                 <label>Email Address, if different than {{current_user}}@swcp.com</label>
                 <VField name="email" class="col-4 form-control" type=text v-model=userData.email rules="email" /><br>
                 </div>
              </div> <!-- col-sm-12 -->
         </div> <!-- row -->
         <div class="row">
              <div class="offset-sm-2 col-sm=10">
                 <button class="btn btn-outline-primary my-2 my-sm-0"
                     type="submit">Set Delivery Method</button>
              </div> <!-- col-sm-10  -->
         </div> <!-- row -->
       </VForm>
    </div><!-- v-if -->
    <div v-else>
       <h3>Statement delivery options are only available for the primary account</h3>
    </div><!-- v-else -->
  </div><!-- container top -->
</template>

<script>
   const fail_title = "Sorry, something has failed";
   const fail_body = "There was a problem changing the delivery method<br>" +
                     "You can reach us at (505) 232-7992 or help@swcp.com for more information.<br>"; 
   const success_title = "Thanks!";
   const success_body = "Your Statement Delivery Method has been changed. " +
                        "If you need to follow up you can reach us at (505) 232-7992<br>";

   import axios from "axios";
   import { Field as VField, Form as VForm, ErrorMessage, defineRule } from "vee-validate";
   export default {
      name: 'ChangeStmtDelivery',
      emits: [ 'message' ],
      components: { VForm, VField, ErrorMessage, defineRule },
      data () {
        return {
           acc_role: 'Secondary',
           userData: {
               method: 'n',
               nozero: 0,
               email: '',
               footer: ''
           }
        }
      },
      mounted () {
         this.setupPage();
      },

      methods: {
         setupPage() {
             var config = { headers:
                                 { Authorization: "Bearer " + this.jwt }
                          };
             var account = this.current_user;
             var url = "api/account-role/" + account;
             axios.get(url, config )
             .then (
                      response => {
                         this.acc_role = response.data.data.account_role;
                         this.getStDel();
                      })
             .catch(error => {
                         console.log("Failed to get account_role " + error);
                         this.status = error;
             })
     
         },
         buildNotice(title, body) {
            var notice = "<h3>" + title + "</h3>" + body;
            return notice;
         },
         responseMessage(title, body, type, alert, footer, menu) {
           var args = { title: title, body: body,
                        type: type, alert: alert,
                        footer: footer, menu: menu };
           this.$store.commit('setStatus', args);
           this.$emit('message');
           return;
         },
         getStDel() {
            var url = "/api/get-statement-delivery/"; 
            var config = { headers:
                            { Authorization: "Bearer " + this.jwt }
                         };
            this.userData.logname = this.current_user;
            axios.get(url + this.current_user, config )
            .then (
                   response => {
                      this.userData.method = response.data.data.method;
                      this.userData.nozero = response.data.data.nozero;
                      this.userData.email = response.data.data.email;
                   })
            .catch(error => {
                      if (error.response) {
                         if (error.response.data.status && error.response.data.status == "expired_token") {
                             this.$store.dispatch('expired');
                         }
                      }
                      this.responseMessage(fail_title, fail_body + error, 'modal', 'serious', '', []);
                   })
         },
         setStDel() {
            var url = "/api/change-statement-delivery"; 
            var config = { headers:
                            { Authorization: "Bearer " + this.jwt }
                         };
            this.userData.logname = this.current_user;
            axios.post(url, this.userData, config )
            .then (
                   response => {
                      if (response.data.status) {
                         this.responseMessage(success_title, success_body, 'review', 'info', '', []);
                      } else {
                         this.responseMessage(fail_title, response.data.message, 'modal', 'serious', '', []);
                      }
                   })
            .catch(error => {
                      if (error.response) {
                         if (error.response.data.status && error.response.data.status == "expired_token") {
                             this.$store.dispatch('expired');
                         }
                      }
                      this.responseMessage(fail_title, fail_body + error, 'modal', 'serious', '', []);
                   })
         }
      },
      watch: {
          current_user: function (newUser, oldUser) {
            this.setupPage();
          }
      },
      computed: {
          operator ()        { return this.$store.state.operator; },
          current_user ()    { return this.$store.state.current_user; },
          jwt ()             { return this.$store.state.jwt; },
          customer_email ()  { return this.$store.state.reply_to; }
      }
   }
</script>

<style>
.rsam-info.card-body {
   background-color: #ffffee;
   margin-bottom: 15px;
}
.sam-info.card-body {
   background-color: #eef8fe;
}
</style>
