<template>
   <div class="container">
     <div class="col-12">
         <h2 class="dbhead">Temporary Email FAQ</h2>
     </div>
     <div class="row">
        <div class="col-12">
             <h3><em>Answers to common questions about temporary email addresses.</em></h3>
             <FAQ faqname="tempemailfaq" title="" :items="items" />
        </div>
     </div>
   </div>
</template>

<script>

import FAQ from "@/components/FAQ";
export default {
      name: "TempEmailFAQ",
      components: { FAQ },
      data () {
        return {
          account: '',
          items: [
             { question: "What are temporary email addresses?",
               answer: `They are another tool in the fight against spam 
                        (Unsolicited bulk email). Temporary email addresses 
                        are a way to allow you to define an email address that 
                        lasts from a day to several weeks. This allows you to 
                        interact with someone on the internet without having your 
                        permanent email address harvested. It is intended to provide 
                        protection for your permanent email address, not as an anonymous email service.` },
             { question: "How's it work?",
               answer: `We've reserved a domain (shadowz.com) for creation of temporary 
                        email addresses. You choose a name and we create an email address 
                        of 'name'@shadowz.com and forward it to an email address you specify. 
                        At the same time you choose an expiration date for this address.  ` },
             { question: "What is the 'Fade' time?",
               answer: `We don't want customers to receive email intended to another customer 
                        so once an email name has been used we reserve its use for 6 months. 
                        This way if you use an email address to order something no one else is going 
                        to see any messages about that order. ` },
             { question: "Why can't I define a new alias?",
               answer: `We allow 25 active aliases per account. 
                        If you need additional aliases please contact help@swcp.com with information on your 
                        situation. Thanks! `
             }
          ]
        }
      },
      computed: {
          currentRouteName() { return this.$route.name; },
          operator ()        { return this.$store.state.operator; },
          current_user ()    { return this.$store.state.current_user; },
          jwt ()             { return this.$store.state.jwt; }
      }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped >
.rsam-info.card-body {
   background-color: #ffffee;
   margin-bottom: 15px;
}
.drh-info.card-body {
   background-color: white;
   background-image: linear-gradient(white, white, #eeeeee);
}
.card-columns { 
    column-count: 2;
}
@media (max-width: 767px) 
.card-columns { 
    column-count: 1;
}

</style>
