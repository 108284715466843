<template>
    <div class="col-sm-12">
        <div class="row">
        </div>
        <div class="row justify-content-md-center">
             <div class="offset-sm-3 col-sm-4">
                  <ErrorMessage :error="problems.address" />
             </div>
       </div>
       <div class="form-group row">
                 <label class="col-sm-2">Street</label>
                 <input class="form-control col-sm-8" type="text" 
                        name="address" placeholder="Address" aria-label="Address" 
                        :value="address"
                        @input="$emit('update:address', $event.target.value)" />
       </div>
        <div class="row justify-content-md-left">
             <div class="offset-sm-2 col-sm-4">
                  <ErrorMessage :error="problems.city" />
             </div>
             <div class="col-sm-1">
                  <ErrorMessage :error="problems.state" />
             </div>
             <div class="col-sm-2">
                  <ErrorMessage :error="problems.zipcode" />
             </div>
             <div class="col-sm-1">
                  <ErrorMessage :error="problems.country" />
             </div>
       </div>
       <div class="form-group row">
                 <label class="col-sm-2">City, State Zip</label>
                 <input class="form-control col-sm-4" type="text"
                        @input="$emit('update:city', $event.target.value)"
                        :value="city"
                        name="city" placeholder="City" aria-label="City" />
                 <input class="form-control col-sm-1" type="text"
                        @input="$emit('update:state', $event.target.value)"
                        :value="state"
                        name="state" placeholder="State" aria-label="State" />
                 <input class="form-control col-sm-2" type="text" 
                        @input="$emit('update:zipcode', $event.target.value)"
                        :value="zipcode"
                        name="zipcode" placeholder="Zipcode" aria-label="Zipcode" />
                 <input class="form-control col-sm-1" type="text"
                        @input="$emit('update:country', $event.target.value)"
                        :value="country"
                        name="country" placeholder="Country" aria-label="Country" />
       </div>
    </div>
</template>

<script>
   import ErrorMessage from '@/components/ErrorMessage.vue';
   import { useVuelidate } from '@vuelidate/core';
   import { required, numeric, helpers } from '@vuelidate/validators';

   export default {
      name: 'OnlyAddress',
      emits: [ 'update:address', 'update:city', 'update:state', 
               'update:zipcode', 'update:country', 'clearProbs'
              ],
      props: {
        name_tag: String,
        hard: String,
        address: String,
        city: String,
        state: String,
        zipcode: String,
        country: String,
        problems: Object
      },
      components: { ErrorMessage },
      setup () { 
        return { v$: useVuelidate() } 
      },                
      validations: {    
        address: { required: helpers.withMessage('Required', required) },
        city: { required: helpers.withMessage('Required', required) },
        state: { required: helpers.withMessage("Req'd", required) },
        zipcode: { required: helpers.withMessage('Required', required) }
      },           
      methods: {
         clearProbs () {
              this.$emit('clearProbs');
         }
      }
   }

</script>

<style>
::placeholder { 
  color: #a2bbd3 !important;
}
</style>
