<template>
   <div>
    <div class="row">
        <h2 class='dbhead'>Circuits for <span class="swcp-em">{{ current_user}}</span> </h2>
    </div>
    
    <div class="row">
    <table class="table table-bordered">
       <thead class="thead-dark">
         <tr>
          <th>Location</th>
          <th>Service Address</th>
          <th>Provider</th>
          <th>Speed</th>
          <th>Activation</th>
         </tr>
       </thead>
       <tbody>
         <tr v-for="row in rows" :key="row.uid">
          <td>{{row.loc_name}}</td>
          <td>{{row.addr1}} {{row.addr2}}</td>
          <td>{{row.provider}}</td>
          <td>{{row.speed}}</td>
          <td>{{pretty_date(row.active_date)}}</td>
         </tr>
       </tbody>
    </table>
    </div>
  </div>
</template>

<script>

import axios from "axios";

export default {
      name: 'Circuits',
      emits: [ 'message' ],
      data () {
        return {
          rows: [],
          circuits: []
        }
      },
      watch: {
          current_user: function (newUser, oldUser) {
            this.getCircuits();
          }
      },

      mounted() {
          this.getCircuits();
      },
      methods: {
         pretty_date (str) {
            if (str.length >= 8) {
               var year = str.substring(0,4);
               var month = str.substring(4,6);
               var day = str.substring(6,8);
               return  year + "-" + month + "-" + day;
            } else {
               return str;
            }
         },

         getCircuits() {
            var config = { headers:
                            { Authorization: "Bearer " + this.jwt }
                         };

            var url = "/api/circuits/" + this.current_user;
            axios.get(url, config )
            .then (
                   response => {
                      this.circuits = response.data.data.circuits;
                      this.rows = this.circuits;
                   })
            .catch(error => {
                   if (error.response) {
                      if (error.response.status == 500) {
                         this.responseMessage('Server failure: 500', error, 'modal', 'danger', '', []);
                      }
                      if (error.response.data) {
                         if (error.response.data.status && error.response.data.status == "expired_token") {
                             this.$store.dispatch('expired');
                         } 
                      } else {
                         this.responseMessage('Get Circuits failed', error, 'modal', 'serious', '', []);
                      }
                    }
                    this.rows = [ ];
                   })
         },
         responseMessage(title, body, type, alert, footer, menu) {
                var args = { title: title, body: body,
                             type: type, alert: alert,
                             footer: footer, menu: menu };
                this.$store.commit('setStatus', args);
                this.$emit('message');
                return;
         }
      },
      computed: {
          operator ()        { return this.$store.state.operator; },
          current_user ()    { return this.$store.state.current_user; },
          jwt ()             { return this.$store.state.jwt; }
      }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
