<template>
 <transition name="catmodal-fade">
  <div class="catmodal-backdrop">
    <div :class="modsize">
     <header class="catmodal-header">
        <div class="col-8">
         <h3 class="text-center">{{ this.title }}</h3>
        </div>
        <div class="col-2">
          <button
             type="button"
             class="btn-close"
             @click="close">
             X
          </button>
        </div>
     </header>

     <section class="catmodal-body">
      <div :class="alert_class" v-html="body"></div>
     </section>

     <footer class="catmodal-footer">
         {{ this.footer }}&nbsp;&nbsp;
          <button type="button" class="btn-green" @click="close">
              Close
          </button>
     </footer>
    </div>
  </div>
 </transition>
</template>
<script>
  export default {
    name: 'Modal',
    emits: [ 'close' ],
    props: {
      title: String,
      footer: String,
      body: String,
      alert: String
    },
    data () {
      return {
         modsize: 'catmodal catmodal-long',
         alert_class: ""
      }
    },
    mounted () {
      this.alert_class = "alert alert-" + this.alert;
    },
    methods: {
      close() {
        this.$emit('close');
      },
    },
  };
</script>

<style>
.catmodal-fade-enter, 
.catmodal-fade-leave-active {
    opacity: 0;
  }

.catmodal-fade-enter-active, 
.catmodal-fade-leave-active {
    transition: opacity .5s ease
  }

.catmodal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
}

.catmodal-short {
    height: 50%;
    align-items: center;
}
.catmodal-long {
    height: 75%;
}
.catmodal {
    margin-top: 5%;
    margin-left: 20%;
    width: 70%;
    background: #FFFFFF;
    box-shadow: 2px 2px 20px 1px;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    z-index: 1044;
}

.catmodal-header,
.catmodal-footer {
    padding: 15px;
    display: flex;
}

.catmodal-header {
    border-bottom: 1px solid #eeeeee;
    color: #2288ff;
    justify-content: space-between;
}

.catmodal-footer {
    border-top: 1px solid #eeeeee;
    justify-content: flex-end;
}

.catmodal-body {
    position: relative;
    padding: 20px 10px;
    color: black;
}

.btn-close {
    border: none;
    font-size: 20px;
    padding: 10px;
    cursor: pointer;
    text-align: right;
    font-weight: bold;
    color: #2288ff;
    background: transparent;
}

.btn-green {
    color: white;
    background: #2288ff;
    border: 1px solid #3399ff;
    border-radius: 5px;
}

</style>
