<template>
  <div>
     <h2 class="dbhead">Send Authenticated Message</h2>

   <div class="row">
     <div class="col-sm-12">
         <p class="col text-center">
         <a class="btn btn-primary" data-toggle="collapse" href="#helpBox" role="button" aria-expanded="false" aria-controls="helpBox">
            <i class="fa fa-info-circle"></i>  Why do we need this?
          </a>
         </p>
         <div class="collapse" id="helpBox">
            <div :class="sam_class">
              <p>
                 <b>TL;DR</b><br>
                 This lets us know this request comes from someone who knows your password
              </p>
              <hr />
              <p>
                 If you need to inform SWCP about a change in your account status 
                 or request a change in your service, we need to verify that you are authorized to 
                 make such a change to your account. If you call us on the phone, we generally try
                 to determine that you're the right person by verifying your caller ID number, or we 
                 may call you back at the phone number we have on file for you. Sometimes these measures
                 are insufficient or inconvenient (for example, if you are traveling and need to make a
                 change to your email forwarding while you are gone, and we can't call you at your
                 regular number to verify your identity).
              </p><p>
                 So, we have this form as an alternate method of verification. This form is stored 
                 in the members-only portion of our web site, so we know that you had to enter your
                 account password to reach it. Therefore we can authenticate your request and take
                 action accordingly. Just type your message, click "Send Message", and you're done!
              </p>
              <p>
                 If this seems like a lot of trouble to go through, remember that anybody can forge an 
                 email message trivially. Therefore we cannot accept an off-site email message as a 
                 legitimate request to alter your account without additional verification. We take these
                 extra steps to guard against anyone making unwanted changes to your account.
              </p>
            </div>
        </div>
     </div>
   </div>
   <div class="row">
     <div class="col-sm-12">
         <br>
         <form class="form-group my-2 my-lg-0" @submit.prevent="sendMsg">
           <div class="row justify-content-md-center">
                       <ErrorMessage :error="problems.subject" />
            </div>
            <input class="form-control mr-sm-2" type="text" v-model="userData.subject"
                      name="subject"
                      placeholder="Subject" aria-label="Subject" /><br>
           <div class="row justify-content-md-center">
                       <ErrorMessage :error="problems.message" />
            </div>
            <textarea class="form-control" rows="7" name="message" v-model="userData.message"></textarea><br>
           <div class="row justify-content-md-center">
                       <ErrorMessage :error="problems.reply_to" />
            </div>
           <div class="row form-group">
            <label class="col-sm-2">Reply To</label> 
            <input class="col-sm-10" type="text" name="reply_to"
                      v-model="userData.reply_to"
                      aria-label="Reply To" /><br>
           </div>
            <div class="tap-right">
              <button class="btn btn-outline-primary my-2 my-sm-0"
                  type="submit">Send Message</button>
            </div>
         </form>
     </div>
   </div>
 </div>
</template>

<script>
   const fail_title = "Sorry, something has failed";
   const fail_body = "The message was not sent<br>" +
                     "If you need to follow up you can reach us at (505) 232-7992 or help@swcp.com."; 
   const success_title = "Thanks!";
   const success_body = "Your message has been sent to the SWCP help desk. " +
                        "If you need to follow up you can reach us at (505) 232-7992";

   import axios from "axios";
   import { useVuelidate } from '@vuelidate/core'
   import { required, email, helpers } from '@vuelidate/validators';
   import ErrorMessage from '@/components/ErrorMessage.vue';

   export default {
      name: 'SendAuthMsg',
      components: { ErrorMessage },
      emits: [ 'message' ],
      validations: {
        userData: {
           subject: { required: helpers.withMessage('Required', required) },
           reply_to: { email: helpers.withMessage('Invalid Email Address', email) },
           message: { required: helpers.withMessage('Required', required) }
        }
      },
      data () {
        return {
           v$: useVuelidate(),
           userData: {
               send_to: 'help@swcp.com',
               send_from: '',
               subject: '',
               message: '',
               reply_to: this.customer_email,
               footer: ''
           },
           problems: {
               subject: '',
               reply_to: '',
               message: ''
           },
           fields: [ 'subject', 'reply_to', 'message' ],
           is_visible: false,
           response_status: ''
        }
      },
      methods: {
         clearProblems() {
            this.fields.forEach ((item) => { 
               this.problems[item] = ''; 
            });                   
         },             
         responseMessage(title, body, type, alert, footer, menu) {
           var args = { title: title, body: body,
                        type: type, alert: alert,
                        footer: footer, menu: menu };
           this.$store.commit('setStatus', args);
           console.log("BA: responseMessage");
           console.log(args);
           this.$emit('message');
           return;
         },

         setDisappear() {
            this.is_visible = false;
         },
         clearForm() {
            this.userData.subject = '';
            this.userData.message = '';
         },
         async sendMsg() {
            var url = "/api/sendmessage"; 
            var rep = this.customer_email;
            var config = { headers:
                            { Authorization: "Bearer " + this.jwt }
                         };
            var message, property, msg;
            if (this.userData.reply_to != "") { 
                rep = this.userData.reply_to;
            }
            const result = await this.v$.$validate();
            if (! result) {
                  msg = "Validation problems found:";
                  this.clearProblems();
                  this.v$.$errors.forEach (err => {
                      property = err.$property;
                      message = err.$message;
                      this.problems[property] = message;
                      if (message != "") {
                          msg += " " + property + "=" + message + "\n";
                      }
                  });
                  this.cckey++;
                  console.log(msg);
            } else {
               this.userData.send_from = rep;
               this.userData.footer = this.footer_tag; 
               axios.post(url, this.userData, config )
               .then (
                      response => {
                         this.response_status = response.data.status;
                         this.responseMessage(success_title, success_body, 'thankyou', 'info', '', []);
                      })
               .catch(error => {
                         if (error.response) {
                            if (error.response.data.status && error.response.data.status == "expired_token") {
                                this.$store.dispatch('expired');
                            }
                         }
                         this.response_status = error;
                         this.responseMessage(fail_title,fail_body, 'modal', 'serious', '', []);
                      })
             }
         }
      },
      mounted () {
           this.userData.reply_to = this.customer_email;
      },
      watch: {
          current_user: function (newUser, oldUser) {
             this.userData.reply_to = this.customer_email;
          }
      },
      computed: {
          footer_tag () {
            if (this.operator === this.current_user) {
                       return "Message sent by " + this.operator;
            } else {
                       return "Message sent by " + this.operator + " for " + this.current_user;
            }
          },
           operator ()       { return this.$store.state.operator; },
           current_user ()   { return this.$store.state.current_user; },
           jwt ()            { return this.$store.state.jwt; },
           customer_email () { return this.$store.state.reply_to; },
           mode() { return this.$store.state.mode; },
           sam_class () {
               if (this.mode == "light") { return "card card-body sam-info"; }
               return "card card-body sam-info-dark";
           }        
      }
   }
</script>

<style>
.rsam-info.card-body {
   background-color: #ffffee;
   margin-bottom: 15px;
}
.sam-info-dark.card-body {
   background-color: #0f4e53;
}
.sam-info.card-body {
   background-color: #f5e6c5;
}
</style>
