<template>
 <div>
    <p v-html="invoice_text"></p>
 </div>
</template>

<script>

import axios from "axios"
export default {
  name: 'ViewInvoice',
  data () {
       return {
           errors: [],
           invoice_text: '',
           userData: {
               invoice: '',
               logname: ''
           },
       }
  },
  mounted() {
      var config = { headers:
                          { Authorization: "Bearer " + this.jwt }
                   };
      var url = "/api/get-invoice";
      var invoice = this.$route.params.invoice;
      this.userData.invoice = invoice;
      this.userData.logname = this.current_user;
      console.log (invoice);
      console.log (this.current_user);
      axios.post(url, this.userData, config )
      .then (
              response => {
                    this.invoice_text = response.data.data.invoice;
              })
      .catch(error => {
             if (error.response.data.status && error.response.data.status == "expired_token") {
                 this.$store.dispatch('expired');
             }
             this.invoice_text = "Can't find " + this.userData.invoice +
                                  " " + error.response.data.message + " " + error;
             })
  },
  methods: {
  },
  computed: {
    operator ()       { return this.$store.state.operator; },
    current_user ()   { return this.$store.state.current_user; },
    jwt ()            { return this.$store.state.jwt; }
  }

}
</script>
<style>
::placeholder {
  color: #a2bbd3 !important;
}
</style>
