<template>
   <div>
    <div class="row">
        <h2 class='dbhead'>Change email passwords for secondary accounts 
                           for <span class="swcp-em">{{ current_user}}</span></h2>
    </div>
    <div class="row just-breathe">
        <label class="mr-sm-2" for="panel_password">Panel Password</label>
        <input class="mr-sm-2" :type="type" id=panel_password v-model="userData.panel_password"/>
        &nbsp;&nbsp;<button class="btn btn-outline-info" type="button" v-on:click="togglePassword"> <i class='fa fa-eye'></i> {{ showHide }} </button>
    </div>
    <div class="row">
        <p class="col-12 directions">Leave passwords blank for any accounts you do not want to change</p>
    </div>
    <div class="row">
      <div class="col-12 center-block">
        <h3 v-if='error_message != ""' class='alert alert-info'>{{ error_message }}</h3>
      </div>
    </div>
    
    <div class="row">
       <table class="table table-bordered">
          <tr>
              <th :bgcolor="waiting" class="text-center">Waiting</th>
              <th :bgcolor="pending" class="text-center">Password change already pending</th>
              <th :bgcolor="success" class="text-center">Successfully Changed</th>
              <th :bgcolor="unchanged" class="text-center">Unchanged</th>
              <th :bgcolor="tooshort" class="text-center">Password is too short</th>
              <th :bgcolor="breached" class="text-center">Password appeared in a breach</th>
              <th :bgcolor="perms" class="text-center">Permission denied</th>
          </tr>
      </table>
    </div>
    <div class="row">
    <table class="table table-bordered">
       <thead class="thead-light">
         <tr>
          <th>Logname</th>
          <th>Account ID</th>
          <th>Name</th>
          <th>New Password</th>
          <th>Status</th>
         </tr>
       </thead>
       <tbody>
         <tr v-for="row in accounts" :key="row.uid" :bgcolor="row.color">
          <td>{{row.logname}}</td>
          <td>{{row.uid}}</td>
          <td>{{row.first_name}} {{row.last_name}}</td>
          <td> <input :type="type" v-model="row.password"/> </td>
          <td> {{row.status}}</td>
         </tr>
       </tbody>
    </table>
    </div>
    <div class="row">
       <div class="col text-center">
               <button v-on:click="change_passwords()" class="btn btn-outline-primary"
                         type="submit">Change Passwords</button>
       </div>
    </div>
  </div>
</template>

<script>

import axios from "axios";

export default {
      name: 'SecPasswords',
      emits: [ 'message' ],
      data () {
        return {
          waiting: 'white',
          pending: 'lightblue',
          success: 'lightgreen',
          unchanged: '#ffffdd',
          breached: 'tomato',
          tooshort: 'bisque', 
          perms: 'red',
          type: "password",
          error_message: '',
          showHide: "Show",
          secondaries: [],
          accounts: [],
          userData: {
               logname: '',
               panel_password: '',
               new: '',
               confirm: '',
               id: 0
           }         
        }
      },
      watch: {
          current_user: function (newUser, oldUser) {
            this.getSecondaries();
          }
      },

      mounted() {
          this.getSecondaries();
      },
      methods: {
         responseMessage(title, body, type, alert, footer, menu) {
           var args = { title: title, body: body,
                        type: type, alert: alert,
                        footer: footer, menu: menu };
           this.$store.commit('setStatus', args);
           this.$emit('message');
           return;
         },
         togglePassword () {
            if (this.type == "password") {
                this.type = "text";
                this.showHide = "Hide";
            } else {
                this.type = "password";
                this.showHide = "Show";
            }
         },
         find_acct_type(item) {
            var acct_type = "";
            if (item.at_code == 9) { acct_type = "Email"; }
            else if (item.at_code == 2) { acct_type = "Network Auth"; }
            else { acct_type = "Shell"; }
            return acct_type;
         },
         getSecondaries() {
            var config = { headers:
                            { Authorization: "Bearer " + this.jwt }
                         };

            var url = "/api/secondaries/" + this.current_user;
            axios.get(url, config )
            .then (
                   response => {
                      this.secondaries = response.data.data.secondaries;
                      this.build_form(this.secondaries);
                   })
            .catch(error => {
                   if (error.response) {
                      if (error.response.data.status && error.response.data.status == "expired_token") {
                          this.$store.dispatch('expired');
                      } else {
                          this.responseMessage('Permission denied',
                               "Secondary accounts can't change passwords for other secondary accounts", 'modal', 'warning', '', []);
                      }
                   }
                   this.rows = [ ];
                   })
         },
         change_passwords() {
            var config = { headers:
                            { Authorization: "Bearer " + this.jwt }
                         };

            this.userData.panel_logname = this.current_user;
            this.error_message = "";
            var url = "/api/change-unix-password";
            var title, body, idx;
            for (var i = 0; i < this.accounts.length; i++) { 
                 if (this.accounts[i].password == "") {
                     this.accounts[i].color = this.unchanged;
                     this.accounts[i].status = "unchanged";
                 } else {
                     if (this.accounts[i].password.length < 8) {
                         this.accounts[i].color = this.tooshort;
                         this.error_message = this.accounts[i].logname + ": Password must be at least 8 characters long";
                         this.accounts[i].status = "Error";
                     } else {
                         this.userData.logname = this.accounts[i].logname;
                         this.userData.new = this.accounts[i].password;
                         this.userData.confirm = this.accounts[i].password;
                         this.userData.id = i;
                         this.accounts[i].color = this.waiting;
                         axios.post(url, this.userData, config )
                         .then (
                              response => {
                                    idx = response.data.data.id;
                                    if (response.data.status) {
                                       console.log("Success: " + idx);
                                       this.accounts[idx].color = this.success;
                                       this.accounts[idx].password = "";
                                       this.accounts[idx].status = "change pending";
                                    } else {
                                       console.log("Failed: " + idx);
                                       console.log(response.data);
                                       this.accounts[idx].color = this.perms;
                                       this.error_message = this.accounts[idx].logname + ": " + response.data.message;
                                       this.accounts[idx].status = "Error";
                                       if (response.data.message.endsWith('data breaches.')) {
                                           this.accounts[idx].status = "Error";
                                           this.accounts[idx].color = this.breached;
                                       }
                                       if (response.data.message.startsWith('Password change already pending')) {
                                           this.accounts[idx].color = this.pending;
                                           this.accounts[idx].status = "already pending";
                                       }
                                    }
                                })
                         .catch(error => {
                                console.log("Returned Error");
                                console.log(error);
                                if (error.response) {
                                   if (error.response.data.status && error.response.data.status == "expired_token") {
                                       this.$store.dispatch('expired');
                                   }
                                   this.error_message = error.response.data.message;
                                   console.log(error);
                                }
                          })
                     }
                }
             
            }
         },
         build_form(secs) {
           var item;
           for (var i = 0; i < secs.length; i++) { 
               item = secs[i];
               item.password = ""; 
               item.color = this.waiting;
               item.status = "";
               this.accounts.push( item );
           }
         }
      },
      computed: {
          operator ()        { return this.$store.state.operator; },
          current_user ()    { return this.$store.state.current_user; },
          jwt ()             { return this.$store.state.jwt; }
      }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.directions {
      border-top: 4px solid #eeeeee;
      padding: 10px;
}
.just-breathe {
      padding-bottom: 25px;
}
label {
      font-weight: bold;
}
</style>
