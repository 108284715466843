<template>
   <div>
    <div class="row">
        <h2 class='dbhead'>Secondary Accounts for <span class="swcp-em">{{ current_user}}</span> </h2>
        <p>Dashboard Admins are secondary accounts that are allowed to view 
           account information as if they were the account owner. You can
           make changes to which accounts are Admins from this page.</p>
    </div>
    
    <div class="row">
    <table :class="tab_class">
       <thead :class="thead_mode">
         <tr>
          <th>Logname</th>
          <th>Account ID</th>
          <th>Name</th>
          <th>Type</th>
          <th>Dashboard Admin</th>
         </tr>
       </thead>
       <tbody>
         <tr v-for="row in rows" :key="row.uid">
          <td>{{row.logname}}</td>
          <td>{{row.uid}}</td>
          <td>{{row.first_name}} {{row.last_name}}</td>
          <td>{{find_acct_type(row)}}</td>
          <td>
              <input type="checkbox" :id=row.logname :value="row.uid" v-model="admins"/>
          </td>
         </tr>
       </tbody>
    </table>
    </div>
    <div class="row">
       <div class="col text-center">
               <button v-on:click="update_admins()" class="btn btn-outline-primary"
                         type="submit">Update Admins</button>
       </div>
    </div>

  </div>
</template>

<script>

import axios from "axios";

export default {
      name: 'Secondaries',
      emits: [ 'message' ],
      data () {
        return {
          rows: [],
          secondaries: [],
          admins: [],
          userData: {
              logname: '',
              admins: '' 
          }
        }
      },
      watch: {
          current_user: function (newUser, oldUser) {
            this.getSecondaries();
          }
      },

      mounted() {
          this.getSecondaries();
      },
      methods: {
         responseMessage(title, body, type, alert, footer, menu) {
           var args = { title: title, body: body,
                        type: type, alert: alert,
                        footer: footer, menu: menu };
           this.$store.commit('setStatus', args);
           this.$emit('message');
           return;
         },
         find_acct_type(item) {
            var acct_type = "";
            if (item.at_code == 9) { acct_type = "Email"; }
            else if (item.at_code == 2) { acct_type = "Network Auth"; }
            else { acct_type = "Shell"; }
            return acct_type;
         },
         getSecondaries() {
            var config = { headers:
                            { Authorization: "Bearer " + this.jwt }
                         };

            var url = "/api/secondaries/" + this.current_user;
            axios.get(url, config )
            .then (
                   response => {
                      this.secondaries = response.data.data.secondaries;
                      this.build_admin_list(this.secondaries);
                      this.rows = this.secondaries;
                   })
            .catch(error => {
                   if (error.response) {
                      if (error.response.data.status && error.response.data.status == "expired_token") {
                          this.$store.dispatch('expired');
                      } else {
                          this.responseMessage('Permission denied',
                               "Secondary accounts don't have access to this information", 'modal', 'warning', '', []);
                      }
                   }
                   this.rows = [ ];
                   })
         },
         update_admins() {
            var config = { headers:
                            { Authorization: "Bearer " + this.jwt }
                         };

            this.userData.logname = this.current_user;
            var url = "/api/update-admins";
            var title, body;
            this.userData.admins = this.admins.join(',');
            axios.post(url, this.userData, config )
            .then (
                   response => {
                       if (response.data.status) {
                          this.responseMessage("Success", '', 'review', 'info', '', []); 
                       } else {
                          this.responseMessage('Update Failed', response.message, 'modal', 'warning', '', []);
                       }
                       this.getSecondaries();
                   })
            .catch(error => {
                   if (error.response) {
                      if (error.response.data.status && error.response.data.status == "expired_token") {
                          this.$store.dispatch('expired');
                      }
                      this.responseMessage("Update admins failed", error, 'modal', 'serious', '', []); 
                   }
                   })

         },
         build_admin_list(accounts) {
           this.admins = [];
           for (var i = 0; i < accounts.length; i++) { 
               if (accounts[i].prime_uid) {
                   this.admins.push( accounts[i].uid );
               }
           }
         }
      },
      computed: {
          operator ()        { return this.$store.state.operator; },
          current_user ()    { return this.$store.state.current_user; },
          jwt ()             { return this.$store.state.jwt; },
          mode ()            { return this.$store.state.mode; },
          tab_class () {
            return "table table-borderless table-" + this.mode;
          },
          thead_mode ()      { return "thead-" + this.mode; }  
      }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
