<template>
  <div class="hello">
    <h2 class="dbhead">{{title}}</h2>
    <div class="boxed">
      <div class="row">
        <img class="paddme col-2" :src="picture()" alt="Icon">
        <div class="col-10 tapped">
            <span v-html="body"></span> 
        </div>
      </div>
        <div class="signed">
              <em>{{ date }}</em>
        </div>
    </div>
    <div v-if="menu.length" class="text-center">
       <button v-for="(item, index) in menu" v-on:click="$router.push(item.url)" class="mx-2 btn btn-primary"
                v-bind:key="index"
                v-bind:value="index"> {{ item.name }}</button>
     </div>
  </div>
</template>

<script>
import axios from "axios"
import dayjs from "dayjs"

export default {
  name: 'ThankYou',
  data () {
        return {
           pics: [ 'owls-small.png', 'kitten-small.png', 'white-mandela-small.png',
                   'geometric-small.png', 'raccoon-small.png', 'tiger-small.png',
                   'tribal-cat-small.png', 'owl-family-small.png', 'dragon-small.png' ],
           pindex: 0,
           date: '',
           title: '',
           body: '',
           footer: '',
           alert: '',
           menu: [ ]
        }
  },
  mounted() {
     this.date = dayjs().format('MMM D, YYYY h:mm:ss A');
     this.buildMessage();
  },
  methods: {
         picture () { 
           this.pindex = Math.floor(Math.random() * 9);
           return require("@/assets/img/" + this.pics[ this.pindex ]); 
         },
         buildMessage() {
           this.body = this.$store.state.status_body;
           this.title = this.$store.state.status_title;
           this.alert = this.$store.state.status_alert;
           this.footer = this.$store.state.status_footer;
           this.menu = this.$store.state.mini_menu;
           return;
         }
  },
  computed: {
          menu_length () { return this.menu.length; },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.tapped {
   padding-top: 7%;
}
.paddme {
   padding: 15px;
}
.signed {
   padding-left: 50%;
}
.boxed {
   padding: 40px;
   margin-bottom: 25px;
   border: 1px solid #2c394f;
   background-color: #ddeeff;
   color: #2c394f;
   font-weight: bold;
}
</style>
