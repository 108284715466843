<template>
  <div>
   <h2 class='dbhead'> Websites for <span class="swcp-em">{{current_user}}</span></h2>
    <vue-good-table v-if="rows.length > 0"
      :columns="columns"
      :rows="rows"
      :theme="vgt_theme"
      @on-cell-click="onCellClick"
      :sort-options="{
          enabled: true,
          initialSortBy: {field: 'virtname', type: 'asc'}
      }"
      :pagination-options="{
        enabled: true,
        mode: 'records',
        perPage: 10,
        position: 'top',
        perPageDropdown: [5, 10, 25, 50],
        dropdownAllowAll: false,
        setCurrentPage: 1,
        nextLabel: 'next',
        prevLabel: 'prev',
        rowsPerPageLabel: 'Rows per page',
        ofLabel: 'of',
        pageLabel: 'page', // for 'pages' mode
        allLabel: 'All',
      }"
      :search-options="{
         enabled: false,
         placeholder: 'Search All Fields'
       }"
       styleClass="vgt-table bordered condensed"
     >
   </vue-good-table>
   <h3 v-else>No Websites Found</h3>
  </div>
</template>

<script>

import VueGoodTable from '@/components/vue3-good-table/Table';
import axios from "axios";

export default {
     name: 'Websites',
     components: {
        VueGoodTable
     },
     emits: [ 'message' ],
     data () {
         return {
           account: '',
           vsData: {
                     logname: '',
                     virt_server: '',
                     vid: 0,
                     status: -1,
                     last_log_id: 0
                   },

           columns: [  
             {
               field: "virtname",
               label: "Website",
               filterOptions: {
                  enabled: true, // enable filter for this column
                  placeholder: 'Search', // placeholder for filter input
               },
               sortable: true
             },
             {
               field: "php_version",
               label: "PHP",
               type: "string",
               sortable: false
             },
             {
               field: "ssl",
               label: "SSL",
               type: "number",
               sortable: true,
               formatFn: this.ssl
             },
             { 
               field: "edit",
               label: "Edit",
               sortable: false,
               html:true
             },
             { 
               field: "button_log",
               label: "Toggle",
               sortable: false,
               html:true
             },
             { 
               field: "view_log",
               label: "View",
               sortable: false,
               html:true
             },
             {
               field: "download_log",
               label: "Download",
               sortable: false,
               html:true
             }
           ],
           rows: [],
           logs: [],
           selected_row: {},
           log_length: 0
         }
     },
     mounted() {
         this.getWebsiteList();
     },
     watch: {
             current_user: function (newUser, oldUser) {
               this.account = this.current_user;
               this.getWebsiteList();
             }
     },
     methods: {
      responseMessage(title, body, type, alert, footer, menu) {
              var args = { title: title, body: body,
                           type: type, alert: alert,
                           footer: footer, menu: menu };
              this.$store.commit('setStatus', args);
              this.$emit('message');
              return;
       },
       edit_btn (row) {
          var label = "Edit Site";
          return "<Button class='btn btn-sm btn-outline-primary'>" + label + "</Button>";
       },
       el_btn (row) {
          var logstat = row['error_logs']; 
          var label = "Enable Logs";
          if (logstat == 1) { label = "Disable Logs"; }
          return "<Button class='btn btn-sm btn-outline-primary' value='" + logstat + "'>" + label + "</Button>";
       },
       view_btn (row) {
          var logstat = row['error_logs']; 
          var retval = "";
          if (logstat == 1) { 
             retval = "<Button class='btn btn-sm btn-outline-primary' value='1'>View Log</Button>";
          }
          return retval;
       },
       dl_btn (row) {
          var logstat = row['error_logs']; 
          var retval = "";
          if (logstat == 1) { 
             retval = "<Button class='btn btn-sm btn-outline-primary' value='1'>Download Log</Button>";
          }
          return retval;
       },
       newItem (item) {
          item['edit'] = this.edit_btn(item);
          item['button_log'] = this.el_btn(item);
          item['view_log'] = this.view_btn(item);
          item['download_log'] = this.dl_btn(item);
          return item;
       },
       add_buttons (items) {
          return items.map(this.newItem);
       },
       selectVirt(params) {
            var row = params.row;
            this.selected_row = row;
            this.$router.push({ name: 'edit-website', 
                 params: { vid: row.vid, }});
       },

       onCellClick (params) {
         var status = "Enable";
         if (params.column.field == "edit") {
            this.selectVirt(params);
         }
         if (params.column.field == "view_log") {
             if (params.row.error_logs == 1) { 
                 this.$router.push({ name: 'showlogs', 
                                     params: { virtname: params.row.virtname, vid: params.row.vid }});
             }
         }
         if (params.column.field == "download_log") {
             if (params.row.error_logs == 1) { 
                 this.vsData.logname = this.current_user;
                 this.vsData.vid = params.row.vid;
                 this.vsData.virt_server = params.row.virtname;
                 this.vsData.status = params.row.error_logs==1 ? 0 : 1;
                 this.getLogs();
             }
         }
         if (params.column.field == "button_log") {
             var config = { headers:
                                 { Authorization: "Bearer " + this.jwt }
                          };
             this.vsData.logname = this.current_user;
             this.vsData.virt_server = params.row.virtname;
             this.vsData.vid = params.row.vid;
             this.vsData.status = params.row.error_logs==1 ? 0 : 1;
             // console.log(this.vsData.virt_server + " setting log status " + this.vsData.status);
             axios.post("/api/watcherrorlogs", this.vsData, config )
                 .then (
                         response => {
                               this.getWebsiteList();
                         })
                 .catch(error => {
                         if (error.response) {
                            if (error.response.data.status && error.response.data.status == "expired_token") {
                                this.$store.dispatch('expired');
                            }
                            this.responseMessage("Error", error.response.data.message, 'modal', 'serious', '', []);
                         } 
                  })
   
            }
       },
       getWebsiteList () {
         var config = { headers:
                             { Authorization: "Bearer " + this.jwt }
                      };
         this.account = this.current_user;
         axios.get("/api/compressed-websites/" + this.account, config )
         .then (
                 response => {
                       this.rows = this.add_buttons(response.data.data);
                 })
         .catch(error => {
                 if (error.response) {
                    if (error.response.data.message) {
                        this.responseMessage('Error getting website list', error.response.data.message, 'modal', 'serious', '', []);
                    }
                    if (error.response.data.status && error.response.data.status == "expired_token") {
                        this.$store.dispatch('expired');
                    }
                 } 
                 })
   
       },
       ssl: function (value) {
                 if (value == 0) { return "No"; }
                 if (value == 1) { return "SSL-only"; }
                 else { return "Yes"; }
       },
       disabled: function (value) {
                 if (value == 1) { return "Enabled"; }
                 else { return "Disabled"; }
       },
       getLogs () {
         var config = { headers:
                             { Authorization: "Bearer " + this.jwt },
                        responseType: 'blob'
                      };
         if (this.vsData.virt_server != "") {
             axios.post("/api/download-errorlogs", this.vsData, config )
             .then (
                     response => {
                          const blob = new Blob([response.data], { type: 'text/plain' })
                          const link = document.createElement('a')
                          link.href = URL.createObjectURL(blob)
                          link.download = this.vsData.virt_server + ".txt"
                          link.click()
                          URL.revokeObjectURL(link.href)
                     })
             .catch(error => {
                     var notes = "";
                     if (error.response) {
                        if (error.response.data.message) {
                            notes = error.response.data.message;
                        }
                        if (error.response.data.status && error.response.data.status == "expired_token") {
                            this.$store.dispatch('expired');
                        }
                     }
                     this.responseMessage('No Logs available' + notes, 'It may take a few minutes before logs are available', 'modal', 'serious', '', []);
                     })
           }

       }
     },
     computed: {
          currentRouteName() { return this.$route.name; },
          operator ()        { return this.$store.state.operator; },
          current_user ()    { return this.$store.state.current_user; },
          mode()             { return this.$store.state.mode; },
          jwt ()             { return this.$store.state.jwt; },
          vgt_theme()        {
              if (this.mode == "light") {
                 return "swcptab";
              } else {
                 return "swcpdark";
              }
          }
     }
   
   }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
