<template>
 <transition name="msgmodal-fade">
  <div class="msgmodal-backdrop">
    <div class="msgmodal">
       <header class="msgmodal-header">
         <div class="row msgmodal-body">
         <div class="col-10">
           <h3 :class="text_class">{{ this.title }}</h3>
         </div>
         <div class="col-2">
           <button
              type="button"
              class="btn-close text-right"
              @click="close">
              X
           </button>
         </div>
       </div>
     </header>

     <section class="msgmodal-body">
      <div :class="alert_class" v-html="body"></div>
     </section>

     <footer class="msgmodal-footer">
         {{ this.footer }}&nbsp;&nbsp;
          <button type="button" :class="btn_class" @click="close">
              Close
          </button>
     </footer>
    </div>
  </div>
 </transition>
</template>
<script>
  export default {
    name: 'NoticeModal',
    emits: [ 'modal-notice-close' ],
    props: {
      title: String,
      footer: String,
      body: String,
      size: {
            type: String,
            default: "long"
      },
      alert: String
    },
    methods: {
      close() {
        this.$emit('modal-notice-close');
      },
      standardize (str) {
          if (str === "serious") {
             return "danger";
          } else {
             if (str === "left") {
                 return "info";
             } else {
                 return str;
             }
          }
      }
    },
    computed: {
       alert_class () {
          if (this.alert == "left") {
              return "text-left alert alert-info";
          } else {
              return "text-center alert alert-" + this.alert;
          }
       },
       btn_class () {
          return "btn btn-" + this.standardize(this.alert);
       },
       text_class () {
          return "text-center text-" + this.standardize(this.alert);
       }
    }
  };
</script>

<style>
.msgmodal-fade-enter, 
.msgmodal-fade-leave-active {
    opacity: 0;
  }

.msgmodal-fade-enter-active, 
.msgmodal-fade-leave-active {
    transition: opacity .5s ease
  }

.msgmodal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
}

.msgmodal {
    margin-top: 5%;
    margin-left: 20%;
    width: 70%;
    background: #FFFFFF;
    box-shadow: 2px 2px 20px 1px;
    overflow-x: auto;
    border-radius: 10px;
    z-index: 1044;
}

.msgmodal-header,
.msgmodal-footer {
    text-align: center;
    padding: 10px 15px;
}

.msgmodal-header {
    color: #2288ff;
}

.msgmodal-body {
    height: 50%;
    position: relative;
    padding: 0px 10px;
    text-align: left;
    color: black;
}

.btn-close {
    border: none;
    padding-top: 0px;
    padding-bottom: 0px;
    font-size: 20px;
    cursor: pointer;
    text-align: right;
    font-weight: bold;
    color: #888888;
    background: transparent;
}

.btn-green {
    color: white;
    background: #2288ff;
    border: 1px solid #3399ff;
    border-radius: 5px;
}

</style>
