<template>
  <div>
   <div class="row">
   </div>
   <div class="row">
       <form class="col-11" @submit.prevent>
        <h2>Password Status of things</h2>
        <div class="row">
             <input type='text' placeholder="Enter password to check" v-model="userData.item" class="form-control" />
        </div>
        <div class="row my-3 justify-content-center">
        <button v-on:click="check_password()" class="col-2-offset col-2 btn btn-outline-primary"
                type="submit">Check Password</button>
        </div>
       </form>
   </div>
   <div v-if="show_me" class="row">
    <table class="table table-light">
     <thead>
     </thead>
     <tbody>
      <tr><th><i class="fa fa-lg fa-key"></i> Password</th><td>{{ password }} </td></tr>
      <tr><th class='w-50'><i class="fa fa-lg fa-circle-question"></i> 
              Has my password appeared in any data breaches?</th><td :class="level"> {{ yesno(hacked) }}</td>
             <td :class="level"><i :class="emoji"></i></td></tr>
      <tr><th><i class="fa fa-lg fa-user-secret"></i> Number of times this password was seen</th><td :class="level" colspan=2>{{ count }}</td></tr>
     </tbody>
    </table>
    </div>
   <div class="row">
     <div class="col-12">
        <h3>FAQ</h3>
        <FAQ faqname="pwnedfaq" title="" :items="items" />
     </div>
   </div>

 </div>

</template>

<script>

import axios from "axios";
import FAQ from "@/components/FAQ";

export default {
      name: 'Pwned',
      components: { FAQ },
      data () {
        return {
          show_me: 0,
          hacked: 0,
          level: 'safe',
          emoji: 'fa fa-lg fa-lock',
          count: 0,
          tries: 0,
          place: 0,
          hash: '',
          password: '',
          userData: {
              item: ''
          },
          items: [
             { question: "What is this about?",
               answer: `<p>You're probably aware that there have been many data breaches
                        at companies that run popular web services, like Adobe, Facebook,
                        Yahoo, LinkedIn, and the list goes on. NIST (the National Institute of Standards and Technology)
                        has made new recommendations in 2022 regarding passwords
                        and how to keep them safe. </p><p>One of the things they recommend is that if a password has
                        appeared in a data breach you shouldn't use it anymore. There are a number of places
                        where you can get information on breached passwords. One of the popular sites that
                        has aggregated a lot of this data is called Have I been Pwned? We have a copy of their
                        most recent mega list and this tools checks to see if your password is on that list.</p>` },
             { question: "Is it safe to check my password here?",
               answer: `This is a local service. Your password will not be logged or sent to any third party website.` },
             { question: "Can I find out if my email address has appeared in a breach?",
               answer: `<a target="_boo" href="https://haveibeenpwned.com/">Have I been Pwned</a> 
                        will provide you with more information on which breaches (if any) your email has appeared in. 
                        Check out their FAQ for more information.` },
             { question: "Yikes! I found my email on the breach list, now what?",
               answer: `Take a deep breath. Think about all the services you've used over the years. It would be
                        more surprising if your email address hadn't been seen by the bad guys. This is why it's
                        so important that you don't reuse passwords. If you're worried about your email address
                        being on lists you can ask us to change your logname, but more than likely it's not a 
                        problem as long as you've used unique passwords that are strong and you occasionally
                        change them. It's also important to protect the password that you use to collect your
                        email, as that is often the path to password recovery.` },
             { question: "More Information",
               answer: `<ul class='classic-list'>
                          <li><a target="_boo" href="https://pages.nist.gov/800-63-3/sp800-63b.html">NIST recommendations</a></li>
                          <li><a target="_boo" href="https://haveibeenpwned.com/FAQs">The Have I Been Pwned FAQ</a></li>
                        </ul> `}
           ]
         }
      },
      methods: {
         yesno(h) {
            if (h) { 
               this.level = "unsafe w-25";
               this.emoji = "fa fa-lg fa-face-frown";
               return "Yes" 
            } else { 
               this.level = "safe w-25";
               this.emoji = "fa fa-lg fa-face-smile";
               return "No" 
            }
         },
         check_password() {
            var url;
            url = "api/pwned"; 
            var config = { headers:
                            { Authorization: "Bearer " + this.jwt }
                         };
            axios.post(url, this.userData, config )
                .then (
                   response => {
                      this.show_me = 1;
                      this.userData.item = '';
                      this.hacked = response.data.data.hacked;
                      this.count = response.data.data.count;
                      this.password = response.data.data.password;
                      this.tries = response.data.data.tries;
                      this.place = response.data.data.place;
                      this.hash = response.data.data.hash;
                   })
                .catch ( error => {  
                    if (error.response.data.status && error.response.data.status == "expired_token") {
                        this.$store.dispatch('expired');
                    }
                    this.answer = "Error: " + error;
                   })
         }
      },
      computed: {
           user () { return this.$store.state.user; },
           staff () { return this.$store.state.staff; },
           logged_in () { return this.$store.state.logged_in; },
           jwt () { return this.$store.state.jwt; }
      }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style> 
.safe {
    font-weight: bold;
    background-color: green;
    color: white;
}
.unsafe {
    font-weight: bold;
    background-color: red;
    color: white;
}
.classic-list {
    list-style-type: circle;
    padding: 10px;
}
.classic-list li {
    display: list-item;
}
.classic-list li a {
    font-weight: bold; 
}
</style>
