<template>
  <div>
   <div class="row top-row">
     <h2 class="col-12">Add Web Hosting</h2>

     <form  v-if="this.domains.length > 0" class="col-sm-12" v-on:submit.prevent="requestWebHosting" ref="form">
        <div class="offset-sm-3 row justify-content-md-center">
            <ErrorMessage :error="problems.domain" /><br>
        </div>
        <div class="form-group row">
            <label for='domain' class="col-sm-3 offset-2">Which Domain?</label>
            <select id='domain' name="domain" class="selcss col-sm-5" v-model='userData.domain' as="select" >
               <option value="0" selected disabled>Select a Domain</option>
               <option v-for="(dom) in domains"
                       v-bind:key="dom.domain"
                       v-bind:value="dom.domain"> {{ dom.domain }} </option>
            </select>
        </div>
        <div class="offset-sm-3 row justify-content-md-center">
            <ErrorMessage :error="problems.hosting_type" /><br>
        </div>
        <div class="form-group row">
            <label for='domain' class="col-sm-3 offset-2">Hosting Type?</label>
            <div class="form-check form-check-inline col-sm-6">
                    <label class="form-check-label">
                      <input class="form-check-input" type="radio"
                             name="hosting_type"  
                             v-model="userData.hosting_type"
                             value="Starter" aria-label="Starter" /> Starter &nbsp;&nbsp;</label>
                    <label class="form-check-label">
                      <input class="form-check-input" type="radio"
                             v-model="userData.hosting_type"
                             name="hosting_type" value="Basic" aria-label="Basic" />
                             Basic  &nbsp;&nbsp; </label>
                    <label class="form-check-label">
                      <input class="form-check-input" type="radio"
                             v-model="userData.hosting_type"
                             name="hosting_type" value="Pro" aria-label="Pro" />
                             Pro  &nbsp;&nbsp; </label>
                 </div>
        </div>
        <div class="offset-sm-3 row justify-content-md-center">
        </div>
        <div class="form-group row">
            <label for='domain' class="col-sm-3 offset-2">Profit or Non-Profit?</label>
            <div class="form-check form-check-inline col-sm-6">
                    <label class="form-check-label">
                      <input class="form-check-input" type="radio"
                             name="profit_nonprofit"  
                             v-model="userData.profit_nonprofit"
                             value="Profit" aria-label="Profit" /> Profit &nbsp;&nbsp;</label>
                    <label class="form-check-label">
                      <input class="form-check-input" type="radio"
                             v-model="userData.profit_nonprofit"
                             name="profit_nonprofit" value="Non-profit" aria-label="Non-profit" />
                             Non-profit  &nbsp;&nbsp; </label>
                 </div>
        </div>
        <div v-if="userData.hosting_type != 'Starter'" class="form-group row">
            <label for='wp_yesorno' class="col-sm-3 offset-2">Install WordPress?</label>
            <div class="form-check form-check-inline col-sm-6">
                    <label class="form-check-label">
                      <input class="form-check-input" type="radio"
                             name="wp_yesorno"  
                             v-model="userData.wp_yesorno"
                             value="Yes" aria-label="Yes" /> Yes &nbsp;&nbsp;</label>
                    <label class="form-check-label">
                      <input class="form-check-input" type="radio"
                             v-model="userData.wp_yesorno"
                             name="wp_yesorno" value="No" aria-label="No" />
                             No &nbsp;&nbsp; </label>
                 </div>
        </div>
        <div class="offset-sm-3 form-group row">
            <h5>If you have additional requirements, let us know what you need.</h5>
        </div>
        <div class="form-group row">
            <label for='notes' class="col-sm-3 offset-2">Special requests?</label>
            <div class="form-check form-check-inline col-sm-6">
                 <textarea class="col-9" rows="10" style="height:100%;" name="notes" v-model="userData.notes"></textarea>
             </div>
        </div>
        <div class="form-group row">
             <button class="offset-sm-3 col-md-4 btn btn-outline-primary"
                     type="submit"><i class="fa fa-lg fa-fw fa-share-from-square"></i>&nbsp;&nbsp; Setup</button>

        </div>

     </form>
     <div v-else>
       <div v-if="visibleBox">
           <p class="alert alert-primary offset-sm-2 col-sm-8">You don't seem to have any unused domains registered with us.
           Would you like to register or transfer a domain and set up webhosting?</p>
           <div class="col-3 offset-3">
                <button @click="$router.push('domain-registration')" class="btn btn-info">Register a Domain</button>
           </div>
       </div>
     </div>

   </div>
  </div>

</template>

<script>

import axios from "axios";
import { useVuelidate } from '@vuelidate/core'
import { required, numeric, email, helpers } from '@vuelidate/validators';
import { not_negative, build_mail_message } from '@/helpers/utils.js';
import { AWH_template, AWH_items, AWH_subject_template, AWH_subject_items } 
        from '@/helpers/mail_templates/AWH.js';
import ErrorMessage from '@/components/ErrorMessage.vue';

export default {
      name: 'AddWebHosting',
      components: { ErrorMessage },
      emits: [ 'message' ],
      setup () {
         return { v$: useVuelidate() }
      },
      data () {
        return {
           visibleBox: 0,
           result: [ ],
           pending: [ ],
           domains: [ ],
           domsel: 'Select a domain',
           sendData: {  
               send_from: '',
               send_to: 'noc@swcp.com',
               footer: '',
               message: ''
           },    
           userData: {
               domain: '',
               hosting_type: '',
               profit_nonprofit: 'Profit',
               wp_yesorno: 'No',
               notes: ''
           },
           problems: {
               domain: '',
               hosting_type: ''
           },
           fields: [ 'domain', 'hosting_type', 'profit_nonprofit', 'wp_yesorno', 'notes' ],
         }
      },
      validations () {
        return {
           userData: {
               domain: { required: helpers.withMessage('Select a domain', required) },
               hosting_type: { required: helpers.withMessage('Select a hosting type', required) }
           }
        }
      },
      mounted() {
         this.setupApps();
         this.clearProbs();
      },
      watch: {
          current_user: function (newUser, oldUser) {
            console.log("watch called for " + newUser);
            this.clearForm();
            this.setupApps();
          }
      },
      methods: {
         clearProbs() {
            this.fields.forEach ((item) => {
               this.problems[item] = '';
            });                  
         },             
         responseMessage(title, body, type, alert, footer, menu) {
                var args = { title: title, body: body,
                             type: type, alert: alert,
                             footer: footer, menu: menu };
                this.$store.commit('setStatus', args);
                this.$emit('message');
                return;
         },
         setupApps() {
            this.getVirtList();
            this.userData.email = this.customer_email;
         },
         clearForm() {
            this.fields.forEach ((item) => {
               this.userData[item] = '';
            });                  
            this.userData.profit_nonprofit = "Profit";
            this.userData.wp_yesorno = "No";
         },
        send_message( msg, subject ) {
            var url = "/api/sendmessage";
            var success = "Your web hosting request has been submitted. <br>If you have any questions you can reach us at 505-232-7992 or help@swcp.com.";
            var config = { headers:
                            { Authorization: "Bearer " + this.jwt }
                         };
            this.sendData.send_from = this.customer_email;
            this.sendData.message = msg;
            this.sendData.subject = subject;
            this.sendData.footer = this.footer_tag;
            axios.post(url, this.sendData, config )
            .then (
                   response => {
                      this.response_status = response.data.status;
                      if (this.response_status) {
                          this.responseMessage('Domain Registration Submitted', success, 'thankyou', 'info', '', [ ] );
                      } else {
                          this.responseMessage('Error sending registration', response.data.message, 'modal', 'serious', '', []);
                      }
                   })
            .catch(error => {
                      if (error.response) {
                         if (error.response.data.status && error.response.data.status == "expired_token") {
                             this.$store.dispatch('expired');
                         }
                      }
                      this.response_status = error;
                      this.responseMessage('Problems with Request', error, 'modal', 'serious', '', []);
                   })

         },

         getVirtList () {
            var config = { headers:
                                { Authorization: "Bearer " + this.jwt }
                         };
            this.account = this.current_user;
            axios.get("/api/empty-domains/" + this.account , config )
            .then (
                    response => {
                          this.domains = response.data.data;
                          this.visibleBox = 1;
                    })
            .catch(error => {
                    var notes = "";
                    if (error.response) {
                       if (error.response.data.message) {
                           notes = error.response.data.message;
                       }
                       if (error.response.data.status && error.response.data.status == "expired_token") {
                           this.$store.dispatch('expired');
                       }
                    }
                    this.domains = [ ];
                    })
         },
         async requestWebHosting () {
            var config = { headers:
                                { Authorization: "Bearer " + this.jwt }
                         };
            const result = await this.v$.$validate();
            var subject, message, property, email;
            if (! result) {
                this.clearProbs(); // Set all problems to ''
                this.v$.$errors.forEach (err => {
                      property = err.$property;
                      message = err.$message;
                      this.problems[property] = message;
                });
            } else {
                this.account = this.current_user;
                this.clearProbs(); // Set all problems to ''
                if (this.userData.hosting_type == "Starter") { this.userData.wp_yesorno = "No"; }
               
                AWH_items.domain = this.userData.domain;
                AWH_items.profit = this.userData.profit_nonprofit;
                AWH_items.plan = this.userData.hosting_type;
                AWH_items.wp_install = this.userData.wp_yesorno;
                AWH_items.logname = this.current_user;
                AWH_items.notes = this.userData.notes;
                AWH_subject_items.logname = this.current_user;
                AWH_subject_items.domain = this.userData.domain;

                message = build_mail_message (AWH_template, AWH_items);
                subject = build_mail_message (AWH_subject_template, AWH_subject_items);
                this.send_message (message, subject);
             }
          }
      },
      computed: {
          footer_tag () {
              if (this.operator === this.current_user) {
                 return "Message sent by " + this.operator;
              } else {
                 return "Message sent by " + this.operator + " for " + this.current_user;
              }       
          },              
          operator ()       { return this.$store.state.operator; },
          current_user ()   { return this.$store.state.current_user; },
          jwt ()            { return this.$store.state.jwt; },
          customer_email () { return this.$store.state.reply_to; }
      }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped> 
.selcss {
   padding: 10px;
   border: 1px solid dodgerblue;
   border-radius: 5px;
}
.table td, .table th {
    padding: .5rem .75rem;
}
.table th {
    color: #333333;
}
.top-row h1, h2, h3, h4, h5, h6 {
    margin-top: .5em;
}

</style>
