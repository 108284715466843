<template>
 <div>
     <h2 class="dbhead">Make Payments: <span class="swcp-em">{{current_user}}</span> </h2>

     <div class="container" v-if="this.acc_role!='Secondary'">
        <div class="row" v-if="errors.length">
              <b>Please correct the following error(s):</b>
              <ul>
                <li v-for="error in errors" v-bind:key="error">{{ error }}</li>
              </ul>
        </div>
        <div class="row">
            <PaymentStatus v-bind:info="info"> </PaymentStatus>
        </div>
        <div class="row">
            <CardsOnFile v-on:change-card="changeCard" :key="gatekeeper"> </CardsOnFile>
        </div>
        <PayForm v-on:made-payment="getPaymentInfo" 
                 v-on:updated-card="newCardList"
                 v-on:added-card="newCardList"
                 v-on:message="setMessage"
                 v-bind:which_card = parseInt(which_card) 
                 v-bind:method = method
                 v-bind:info = "info">
        </PayForm>
     </div>
     <div class="container" v-else>
          <h3>Secondary accounts don't have access to billing information</h3>
      </div>
 </div>
</template>

<script>

   import axios from "axios";
   import PaymentStatus from '@/components/PaymentStatus.vue'
   import CardsOnFile from '@/components/CardsOnFile.vue'
   import PayForm from '@/components/PayForm.vue'
   export default {
      name: 'MakePayment',
      components: { PaymentStatus, CardsOnFile, PayForm },
      emits: [ 'message' ],
      data () {
        return {
           info: [ ],
           errors: [],
           gatekeeper: 1,
           is_visible: false,
           which_card: 0,
           method: {
              ccn4: 'xxxx'
           },
           acc_role: 'Secondary',
           response_title: '',
           response_body: '',
           status: '',
           idinfo: ''
        }
      },
      mounted() {
          this.setupPage();
      },

      watch: {
         current_user: function (newUser, oldUser) {
            this.$router.push({ name: "home"});
          }
      },

      methods: {
         setMessage () {
            this.$emit('message');
         },
         changeCard(which, method) {
            this.which_card = which;
            this.method = method;
         },
         newCardList() {
            this.gatekeeper++;
         },
         setupPage() {
            var config = { headers:
                            { Authorization: "Bearer " + this.jwt }
                         };
            var url = "api/account-role/" + this.current_user;
            axios.get(url, config )
            .then (
                 response => {
                    this.acc_role = response.data.data.account_role;
                    if (this.acc_role != "Secondary") { this.getPaymentInfo(); }
                    this.status = "";
                 })
            .catch(error => {
                    if (error.response.data.status && error.response.data.status == "expired_token") {
                        this.$store.dispatch('expired');
                    }
                    this.info = [ { 'Status': 'Failed',
                                    'account': this.current_user,
                                    'error': error } ];
                    console.log("Failed to get account_role " + error);
                    this.status = error;
            })

         },

         getPaymentInfo() {
            var config = { headers:
                            { Authorization: "Bearer " + this.jwt }
                         };
            axios.get("api/get-payment-info/" + this.current_user, config )
            .then (
                 response => {
                    this.info = response.data.data;
                    this.info.last_invoice = this.pretty_date(this.info.last_invoice);
                    this.status = "";
                 })
            .catch(error => {
                    this.info = [ { 'Status': 'Failed',
                                    'account': this.current_user,
                                    'error': error } ];
                    console.log(error);
                    this.status = error;
            })

         },
         pretty_date (str) {
            if (str.length >= 8) {
               var year = str.substring(0,4);
               var month = str.substring(4,6);
               var day = str.substring(6,8);
               return  year + "-" + month + "-" + day;
            } else {
               return str;
            }
         }
      },
      computed: {
          operator () { return this.$store.state.operator; },
          current_user () { return this.$store.state.current_user; },
          jwt () { return this.$store.state.jwt; }
      }
    }

</script>

<style>
.rsam-info.card-body {
   background-color: #ffffee;
   margin-bottom: 15px;
}
.sam-info.card-body {
   background-color: #def6c5;
}
.swcp-comp {
   margin-left: 10%;
   width: 80%;
}
.swcp-comp td, .swcp-comp th {
   padding: .25em;
   width: 25%;
}
</style>
