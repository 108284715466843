<template>
  <section>
   <div class="col-12">
    <h2 class='dbhead'>Add a Account Contact for <span class="swcp-em">{{current_user}}</span></h2>
   </div>
  </section>
  <section class="section_box">
   <form>
   <div class="col-12">
       <div class="row">
         <div class="col-4">
           <table :class="tab_class">
             <tr><td><h4>Contact Name</h4>
                     <input class="form-control" type='text' v-model="addData.contact" /></td></tr>
             <tr><td>Has restrictions?</td><td>
                        <input name="restricted" 
                               type=checkbox true-value="1" 
                               false-value="0" 
                               v-model=addData.restricted  /></td></tr>
             <tr><td>Technical Contact?</td><td>
                        <input name="technical" 
                               type=checkbox true-value="1" false-value="0" 
                               v-model=addData.technical  /></td></tr>
             <tr><td>Billing Contact?</td><td>
                        <input name="billing" 
                               type=checkbox true-value="1" false-value="0" 
                               v-model=addData.billing  /></td></tr>
           </table>
         </div>
         <div class="offset-1 col-6">
           <section class="section_box">
           <h4>Contact Info</h4>
             <textarea required name='info' v-model='addData.info' class="form-control" rows=8></textarea>
           </section>
         </div>
       </div>
       <section class="section_box">
          <div class="col-12">
             <h4>Restrictions</h4>
             <textarea required name='restriction' v-model='addData.restriction' class="form-control" rows=8></textarea>
          </div>
       </section>
          <div class="col-12 text-center">
             <button class="btn btn-info" v-on:click="addTechContact">Add New Contact</button>&nbsp;&nbsp;
             <button class="btn btn-info" v-on:click="contactList">Contact List</button>
         </div>
 
   </div>
   </form>
 </section>

</template>

<script>
import axios from "axios";
export default {
    name: 'AddTC',
    emits: [ 'message' ],
    watch: {
          current_user: function (newUser, oldUser) {
            console.log("Watch called for " + newUser);
            this.$router.push({ name: 'contacts' });
          }
    },
    methods: {
       newlines_to_br(str) {
          if (str == null) { return ""; }
          return str.replaceAll('\n', '<br>');
       },
       responseMessage(title, body, type, alert, footer, menu) {
              var args = { title: title, body: body,
                           type: type, alert: alert,
                           footer: footer, menu: menu };
              this.$store.commit('setStatus', args);
              console.log("addTC: responseMessage");
              console.log(args);
              this.$emit('message');
              return;
       },
       addTechContact() {
           var url = "/api/add-contact";
           var config = { headers:
                            { Authorization: "Bearer " + this.jwt }
                         };
           this.addData.logname = this.current_user;
           console.log("Add to Contact");
           console.log(this.addData);
           axios.post(url, this.addData, config )
               .then (
                  response => {
                      if (response.data.status == 0) {
                         this.responseMessage('Add Contact Failed',
                              response.data.message, 'modal', 'warning', '', []);
                      } else {
                         this.$router.push({ name: 'contacts' });
                      }
                  })
               .catch(error => {
                  if (error.response.data.status && error.response.data.status == "expired_token") {
                      this.$store.dispatch('expired');
                  } else {
                      this.responseMessage('Add Contact Failed',
                           error, 'modal', 'serious', '', []);
                  }
               })
       },
       contactList () {
            this.$router.push({ name: 'contacts' });
       }
    },
    computed: {
          currentRouteName() { return this.$route.name; },
          operator ()        { return this.$store.state.operator; },
          current_user ()    { return this.$store.state.current_user; },
          jwt ()             { return this.$store.state.jwt; },
          mode ()            { return this.$store.state.mode; },
          tab_class () {
            return "table table-responsive table-borderless table-" + this.mode;
          }

   },
   data () {
      return {
        current_domain: '',
        expdates: [],
        first_date: '',
        selected_row: { tcid: 0 },
        selected_cell: '',
        addData: {
            logname: '',
            contact: '',
            info: '',
            restricted: '0',
            technical: '0',
            billing: '0',
            restriction: ''
        }
      }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

section {
   margin-top: 20px;
   margin-bottom: 20px;
}
.section_box {
   padding: 20px;
   border: 1px #dcdcdc solid;
   border-radius: 20px;
   box-shadow: 5px 5px 7px rgb(0 100 200 / 0.25);
}
.email-select {
   padding: 10px;
   border: 1px solid dodgerblue;
   border-radius: 5px;
}
.maxial {
  width: 100%;
}
.padme {
   padding-left: 5%;
}
</style>
