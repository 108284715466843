<template>
  <div class="container">
    <h2 class="dbhead">Change Billing Address</h2>
    <p>If bills need to be sent to a different address other than the account address, 
       you will need a billing address. Most people won't need billing addresses.
       Examples of when this is useful are:
         <ul class="list-group">
            <li :class="limode">A satellite office of a larger corporation where the bills are 
                handled by the corporate office</li>
            <li :class="limode">A student whose parents pay for their internet access</li>
            <li :class="limode">A child whose takes care of their parent's bills</li>
         </ul>
    </p>
    <p>If you don't have a billing address you'll want the <router-link v-bind:to="{ name: 'account-addr' }">
       Account Address Tool</router-link> to update where you receive your bills.</p>
       <p>If you need to change the billing address for a <b>credit card you have on file</b>,
       please use the <router-link v-bind:to="{ name: 'edit-cc' }">Edit Credit Card Tool</router-link>.</p>
       <p> If you want to update the information associated with your <b>domain registration </b>  
           <router-link v-bind:to="{ name: 'send-auth-msg' }">send us a message</router-link>. Thanks! </p>
    
       <div class="col-12">
       <form  v-if="showme" v-on:submit.prevent="validateBillingAddr()">
           <div class="form-group row">
               <h4 v-if="actual_logname != ''" class="demark">Billing Account: {{actual_logname}}</h4>
           </div>
           <div class="form-group row">
               <h5 class="demark">Billing Address </h5><br>
               <Address
                   name_tag="Name"
                   v-model:first_name="userData.first_name"
                   v-model:last_name="userData.last_name"
                   v-model:company="userData.company"
                   v-model:address="userData.address"
                   v-model:city="userData.city"
                   v-model:state="userData.state"
                   v-model:zipcode="userData.zipcode"
                   v-model:country="userData.country"
                   v-model:problems="problems"
                   :key="keymaster"
               >
               </Address>
               <div class="col-sm-12">
                  <div class="form-group row">
                     <label class="col-sm-3">Telephone Number</label>
                     <input class="form-control col-sm-4" type="text" v-model="userData.phone" id="phone" />
                  </div>
               </div>
          </div>
          <div class="form-group row">
              <div class="offset-sm-3 col-sm-6">
                   <button :class="btn_mode"
                           type="submit">Update</button> &nbsp;&nbsp;
                   <button :class="btn_mode" v-on:click="removeBillingAddr"
                           type="button">Remove</button>
              </div>
              <div class="col-sm-3"></div>
         </div>
      </form>
      </div>
  </div>
</template>

<script>
// TODO: Finish converting to Vuelidate and test.
// TODO: Must update Address and UpdateCreditCard as well
   import axios from "axios";
   import { useVuelidate } from '@vuelidate/core';
   import { required, email, helpers } from '@vuelidate/validators';
   import { flattenAddress } from '@/helpers/utils.js';
   import ErrorMessage from '@/components/ErrorMessage.vue';
   import Address from '@/components/Address.vue';
   export default {
      name: 'BillingAddress',
      emits: [ 'message' ],
      components: { Address, ErrorMessage },
      data () {
        return {
           v$: useVuelidate(),
           pending: false,
           showme: 1,
           actual_logname: '',
           keymaster: 1,
           userData: {
               account: '',
               first_name: '',
               last_name: '',
               company: '',
               address: '',
               city: '',
               state: '',
               zipcode: '',
               country: 'US',
               phone: ''
           },
           problems: {
               first_name: '',
               last_name: '',
               company: '',
               address: '',
               city: '',
               state: '',
               zipcode: '',
               country: ''
           },
           sendData: {
               send_from: 'swcpweb@swcp.com',
               send_to: 'accounting@swcp.com',
               subject: '',
               footer: '',
               message: ''
           },
           errors: [],
        }
      },
      mounted() {
          this.getBillingAddr();
      },
      watch: {
          current_user: function (newUser, oldUser) {
            this.getBillingAddr();
          }
      },
      validations () {
          return {
             userData: {
               first_name: { required: helpers.withMessage('Required', required)},
               last_name: { required: helpers.withMessage('Required', required) },
               address: { required: helpers.withMessage('Required', required)},
               city: { required: helpers.withMessage('Required', required) },
               state: { required: helpers.withMessage("Req'd", required) },
               zipcode: { required: helpers.withMessage('Required', required) }
             }
          }
      },

      methods: {
         clearProbs () {
           this.problems['first_name'] = '';
           this.problems['last_name'] = '';
           this.problems['company'] = '';
           this.problems['address'] = '';
           this.problems['city'] = '';
           this.problems['state'] = '';
           this.problems['zipcode'] = '';
           this.problems['country'] = '';  
         },    
         responseMessage(title, body, type, alert, footer, menu) {
           var args = { title: title, body: body, 
                        type: type, alert: alert,
                        footer: footer, menu: menu };
           this.$store.commit('setStatus', args);
           console.log("BA: responseMessage");
           console.log(args);
           this.$emit('message');
           return;
         },
         fillForm( data ) {
           this.userData.first_name = data.first_name;
           this.userData.last_name = data.last_name;
           this.userData.company = data.company;
           this.userData.address = data.address;
           this.userData.city = data.city;
           this.userData.state = data.state;
           this.userData.zipcode = data.zipcode;
           this.userData.country = data.country;
           this.userData.phone = data.phone;
           this.keymaster++;
         },
         clearForm() {
           this.userData.first_name = '';
           this.userData.last_name = '';
           this.userData.company = '';
           this.userData.address = '';
           this.userData.city = '';
           this.userData.state = '';
           this.userData.zipcode = '';
           this.userData.country = 'US';
           this.userData.phone = '';
           this.keymaster++;
         },
         buildMessage ( data ) {
           var msg = "Billing Address has been changed. \n";
           msg += "Please check to see that the new one is postal service correct.\n";
           msg += "---------------------------------------------------\n";
           msg += "Old Address:\n";
           msg += flattenAddress( data.old_address );
           msg += "---------------------------------------------------\n";
           msg += "New Address:\n";
           msg += flattenAddress( data.new_address );
           return msg;
         },
         sendMessage( msg ) {
            var url = "/api/sendmessage";
            var config = { headers:
                            { Authorization: "Bearer " + this.jwt }
                         };
            this.sendData.message = msg;
            this.sendData.subject = "Billing Address updated for " + this.current_user;
            this.sendData.footer = this.footer_tag;
            axios.post(url, this.sendData, config )
            .then (
                   response => {
                      this.response_status = response.data.status
                   })
            .catch(error => {
                      if (error.response) {
                         if (error.response.data.status && error.response.data.status == "expired_token") {
                             this.$store.dispatch('expired');
                         }
                      }
                      if (error.response.data.status && error.response.data.status == "expired_token") {
                          this.$store.dispatch('expired');
                      }
                      this.response_status = error;
                   })
         },
         getBillingAddr() {
            var url = "api/get-bill-address";
            var error_msg = "Unknown error, please contact noc@swcp.com";
            var config = { headers:
                            { Authorization: "Bearer " + this.jwt }
                         };
            this.userData.account = this.current_user;
            axios.post(url, this.userData, config )
            .then (
                   response => {
                      if (response.data.status) {
                          this.fillForm(response.data.data.address);
                          this.actual_logname = response.data.data.logname;
                      } else {
                          if (typeof response.data.message !== 'undefined') { error_msg = response.data.message; }
                          this.responseMessage("Problem in Billing Address change " , 
                                               error_msg, 'modal', 'serious', '', []);
                          this.showme = 0;
                      }
                   })
            .catch(error => {
                      if (error.response.data.status && error.response.data.status == "expired_token") {
                         this.$store.dispatch('expired');
                      }
                      this.responseMessage("Failed", "Failed to get billing address " + error, 
                                           "modal", "serious", "", []);
                      this.clearForm();
                   })
         },
         removeBillingAddr() {
            this.clearForm();
            this.updateBillingAddr();
         },

         async validateBillingAddr() {
            var url = "api/update-bill-address";
            var hdr = "";
            var msg = "";
            var stat = 0;
            var property, message;
            var config = { headers:
                            { Authorization: "Bearer " + this.jwt }
                         };
            this.userData.account = this.current_user;
            const result = await this.v$.$validate();
            console.log(result);
            if (this.pending) { console.log("processNewCard: submit while pending"); return; }
            this.clearProbs();
            if (! result) {
                this.v$.$errors.forEach (err => {
                      property = err.$property;
                      message = err.$message;
                      this.problems[property] = message;
                });
                this.responseMessage("", "", "reset", "warning", "", []);
            } else {
                this.updateBillingAddr();
            }
          },
         updateBillingAddr() {
            var url = "api/update-bill-address";
            var hdr = "";
            var msg = "";
            var stat = 0;
            var property, message;
            var config = { headers:
                            { Authorization: "Bearer " + this.jwt }
                         };
            this.userData.account = this.current_user;
            if (this.pending) { console.log("processNewCard: submit while pending"); return; }
            this.pending = true;
            axios.post(url, this.userData, config )
                .then (
                       response => {
                          if (response.data.status) { 
                              this.responseMessage("Success", "Updated", "review", "info", "", []);
                              this.sendMessage(this.buildMessage(response.data.data));
                              this.pending = false;
                          } else {
                              this.responseMessage("Failed", "Unable to update - let NOC know", "modal", "serious", "", []);
                              this.pending = false;
                          }
                       })
                .catch(error => {
                          this.responseMessage('Update failed', error.message, 'modal', 'warning', '', []);
                          this.pending = false;
                          console.log(this.userData);
                          console.log(this.problems);
                       })
          },

      },
      computed: {
           footer_tag () {
             if (this.operator === this.current_user) {
                        return "Message sent by " + this.operator;
             } else {
                        return "Message sent by " + this.operator + " for " + this.current_user;
             }     
           },
           user () { return this.$store.state.user; },
           current_user () { return this.$store.state.current_user; },
           operator ()     { return this.$store.state.operator; },
           staff () { return this.$store.state.staff; },
           jwt () { return this.$store.state.jwt; },
           mode () { return this.$store.state.mode; },
           btn_mode () { return "btn btn-outline-primary btn-" + this.mode; },
           limode() { return "list-group-item-" + this.mode; }
      }
   }

</script>

<style scoped>
.list-group-item-light {
    position: relative;
    display: block;
    padding: .2rem .75rem;
    background-color: #fff;
    color: #326ca4;
}
.list-group-item-dark {
    position: relative;
    display: block;
    padding: .2rem .75rem;
    background-color: #222;
    color: pink;
}
.demark {
    border-bottom: 2px #458fea solid;
    padding-bottom: 4px;
    margin-bottom: 30px;
}
</style>
