<template>
  <div>
   <h2 class='dbhead'> Invoices </h2>
   <h4>Click on Invoice number to view an invoice</h4>
    <div class="container" v-if="this.acc_role!='Secondary'">
       <vue-good-table
         :columns="columns"
         :rows="rows"
         :theme="vgt_theme"
         :sort-options="{
             enabled: true,
             initialSortBy: {field: 'invoice', type: 'desc'}
         }"
         :pagination-options="{
           enabled: true,
           mode: 'records',
           perPage: 12,
           position: 'top',
           perPageDropdown: [6, 12, 18],
           dropdownAllowAll: false,
           setCurrentPage: 1,
           nextLabel: 'next',
           prevLabel: 'prev',
           rowsPerPageLabel: 'Rows per page',
           ofLabel: 'of',
           pageLabel: 'page', // for 'pages' mode
           allLabel: 'All',
         }"
         :search-options="{
            enabled: true,
            placeholder: 'Search All Fields'
          }"
         @on-row-click="showInvoice"
        >
      </vue-good-table>
    </div>
    <div class="container" v-else>
        <h3>Invoices only visible to the primary account holder</h3>
     </div>
  </div>
</template>

<script>

import axios from "axios";
import VueGoodTable from '@/components/vue3-good-table/Table';
import ViewInvoice  from '@/views/ViewInvoice.vue';

export default {
  components: {
     VueGoodTable, ViewInvoice
  },
  data () {
      return {
        acc_role: "Secondary",
        columns: [
          {
            field: "invoice",
            label: "Invoice Number",
            sortable: true
          },
          {
            field: "invdate",
            label: "Date",
            type: "string",
            sortable: true
          },
          {
            field: "duedate",
            label: "Due Date",
            type: "string",
            sortable: false
          },
          {
            field: "amount",
            label: "Amount",
            type: "number",
            tdClass: 'text-center',
            thClass: 'text-center',
            sortable: true,
            formatFn: this.money
          },
         ],
        rows: [],
        sort: "asc",
        sortField: "invoice"
      }
  },
  watch: {
            current_user: function (newUser, oldUser) {
                 this.$router.push({ name: "home"});
         }
  },
  mounted() {
         this.setupPage();
  },
  methods: {
    setupPage() {
        var config = { headers:
                            { Authorization: "Bearer " + this.jwt }
                     };
        var account = this.current_user;
        var url = "api/account-role/" + account;
        axios.get(url, config )
        .then (
                 response => {
                    this.acc_role = response.data.data.account_role;
                    this.get_invoices();
                 })
        .catch(error => {
                    if (error.response.data.status && error.response.data.status == "expired_token") {
                        this.$store.dispatch('expired');
                    }
                    this.status = error;
        })

    },
    get_invoices () {
      var config = { headers:
                          { Authorization: "Bearer " + this.jwt }
                   };
      var account = this.current_user;
      var url = "/api/invoices/" + account;
      console.log("URL=" + url);
      axios.get(url, config )
      .then (
              response => {
                    this.rows = response.data.data;
              })
      .catch(error => {
              this.rows = [ { 'invoice': 'unknown', 
                              'Status': 'Failed', 'account': account, 'error': error } ];
              })
    },
    money: function(value) {
       return '$' + value;
    },
    showInvoice(params) {
      var row = params.row;
      console.log("Selected an Invoice: ");
      console.log(row);
      this.$router.push({ name: 'view-invoice', params: { invoice: row.invoice }});
    }
  },
  computed: {
    currentRouteName() { return this.$route.name; },
    operator ()        { return this.$store.state.operator; },
    current_user ()    { return this.$store.state.current_user; },
    mode()             { return this.$store.state.mode; },
    jwt ()             { return this.$store.state.jwt; },
    vgt_theme()        {
         if (this.mode == "light") {
             return "swcptab";
         } else {
             return "swcpdark";
         }
    }
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
