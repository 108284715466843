<template>
   <div class="container">
     <div class="row">
         <h2 class="dbhead">FAQ</h2>
     </div>
     <div class="row">
        <div class="col-12">
             <p><em>Answers to common questions about domain permissions and the email alias manager.</em></p>
             <FAQ faqname="domhelpfaq" title="" :items="items" />
         </div>
     </div>
   </div>
</template>

<script>

import FAQ from "@/components/FAQ";
export default {
      name: "DomRegHelp",
      components: { FAQ },
      data () {
        return {
          account: '',
          items: [
             { question: "Why aren't all of my domains listed?",
               answer: `When you login to the SWCP Member's Panel it's important
                        to login as the primary account holder in order to manage your
                        domains and email aliases.
                        If you use one of the secondary accounts, you will not see any of the
                        domains that you have access to modify. This means that if you want 
                        to delegate domain management to one of your secondary accounts, you have 
                        to log in with the primary account at least once in order to set up admin 
                        permissions access for that secondary account.  
                        Alternatively you can simply call or email the SWCP Help Desk and we can log in 
                        on your behalf to make any needed changes.` },
             { question: "Why are some of my domains listed with expiration date UNKNOWN, with no renew/edit links?",
               answer: `The Domain Manager can only operate on domains which are registered with 
                       SWCP/OpenSRS. You may have other domains which SWCP hosts for you but which "
                       are registered elsewhere (e.g. GoDaddy, Network Solutions, etc.) We cannot 
                       renew these domains. If you would like to transfer these domains to 
                       SWCP/OpenSRS please email help@swcp.com.` },
             { question: "I changed the contact email on my domain, but WHOIS still shows another address.",
               answer: `The contact email address in the domain renewal manager is only used by SWCP 
                       to contact you for domain renewal related purposes. It is not published in 
                       WHOIS and is not used by the registrar. If you want to update the WHOIS 
                       information for your domain, you can email the changes to help@swcp.com.` },
             { question: "Can I remove an admin from all my domains?",
               answer: `If you want to remove someone from all your domains, select Remove Permissions from the sidebar. 
                        This will allow you to remove someone quickly and easily from all your domains, 
                        useful when you lose an employee or change web developers.`
             }

          ]
        }
      },
      computed: {
          currentRouteName() { return this.$route.name; },
          operator ()        { return this.$store.state.operator; },
          current_user ()    { return this.$store.state.current_user; },
          jwt ()             { return this.$store.state.jwt; }
      }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped >
.rsam-info.card-body {
   background-color: #ffffee;
   margin-bottom: 15px;
}
.drh-info.card-body {
   background-color: white;
   background-image: linear-gradient(white, white, #eeeeee);
}
.card-columns { 
    column-count: 2;
}
@media (max-width: 767px) 
.card-columns { 
    column-count: 1;
}

</style>
